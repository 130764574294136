import gql from 'graphql-tag';
import { taskFieldsFragment } from '../../fragments/task/taskFields';

export default gql`
  query FetchUserByID($id: ID) {
    userByID(id: $id) {
      _id
      email
      firstname
      lastname
      picture
      status
      type
      saved_events {
        event_id
        Event {
          _id
          name
          picture
          config {
            start
            end
            frequency
            volunteers_needed
          }
          location {
            city
            state
            country
            postcode
          }
          charity {
            _id
            name
            picture
            charity_users {
              user_id
            }
          }
          confirmed_users {
            user_id
          }
          EventReviews {
            _id
          }
        }
      }
      about
      skills
      interests
      created
      location {
        location
        street
        city
        state
        country
        postcode
        latitude
        longitude
      }
      settings {
        notifications {
          promotional
          task_update
          task_reminder
          message
          platform_update
        }
      }
      group_messages_orders {
        group_id
        pined
      }
      EventReviews {
        _id
        hours
        rating
        message
        report {
          active
        }
        Event {
          _id
          name
          charity {
            _id
            name
          }
          config {
            start
            end
            volunteers_needed
          }
        }
      }
      CharitiesFollowing {
        _id
        name
        picture
        Causes {
          _id
          name
        }
        charity_followers {
          user_id
        }
        Forums {
          _id
          name
          Messages {
            _id
            body
            seen
            created
          }
        }
      }
      UpcomingEvents {
        _id
        name
        picture
        config {
          start
          end
          frequency
          volunteers_needed
        }
        location {
          city
          state
        }
        charity {
          _id
          name
          picture
          charity_users {
            user_id
          }
        }
        EventReviews {
          _id
        }
        confirmed_users {
          user_id
        }
      }
      CharitiesAdmin {
        _id
        name
        picture
        website
        Events {
          _id
          config {
            end
          }
          EventReviews {
            _id
          }
          confirmed_users {
            user_id
          }
        }
      }
      CharitiesOfAdmin {
        _id
        name
      }
      IsOnboarded
      UserMacros {
        _id
        name
        color
      }
      UserSkills {
        _id
        name
        skill_color
        skill_label_color
        Macro {
          _id
          name
          color
        }
      }
      TaskReviews {
        _id
        message
        rating
        minutes
        name
        created
        Charity {
          _id
          name
          picture
        }
      }
      Causes {
        _id
        name
      }
      CharitiesAdmin {
        _id
        name
        picture
        description
      }
      TaskSubmissions {
        _id
        _task_id
        status
        cancel
        User {
          firstname
          lastname
        }
        Task {
          ...TaskFields
        }
        TaskTimesheet {
          _id
          _task_submission_id
          status
          name
          start
          end
        }
      }
    }
  }
  ${taskFieldsFragment}
`;
