import moment from 'moment';
import update from 'immutability-helper';
import _ from 'lodash';

const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

update.extend('$auto', (value, object) => {
  return object ? update(object, value) : update({}, value);
});

const utils = {
  escapeRegexCharacters(str) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  },
  formatTimestampToDate(timestamp, format = 'DD/MM/YYYY hh:mma') {
    const dateInNumber = parseInt(timestamp, 10);
    return moment(dateInNumber).format(format);
  },
  formatTimeFromX(startTimestamp, endTimestamp) {
    return moment(startTimestamp).from(moment(endTimestamp), true);
  },
  formatTimeToNow(startTimestamp) {
    return moment(startTimestamp).toNow(true);
  },
  formatDate(date) {
    return `${String(date.getDate()).padStart(2, '0')} ${monthNames[date.getMonth()]} ${date.getFullYear()}`;
  },
  groupByDate(arraySource, dateField, format = 'ddd Do MMM') {
    return _.groupBy(arraySource, item => moment(item[dateField]).format(format));
  },
  groupByDates(source, dateField, dateFormat = 'D MMMM YYYY') {
    return _.chain(source)
      .groupBy(item => moment(item[dateField]).startOf('day').valueOf())
      .map((group, day) => {
        // Sort the grouped messages within the same day
        const sortedGroup = _.orderBy(group, 'created', 'asc');
        return {
          day: moment(parseInt(day, 10)).format(dateFormat),
          group: sortedGroup,
          timestamp: moment(parseInt(day, 10)),
        };
      })
      .orderBy('timestamp', 'desc')
      .value();
  },
  camelize(str) {
    if (str) {
      return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
        if (+match === 0) return '';
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
      });
    }
    return str;
  },
  capitalize(str) {
    if (str === undefined) {
      return str;
    }
    const stringToReturn = str.toLowerCase();
    return stringToReturn.charAt(0).toUpperCase() + stringToReturn.slice(1);
  },
  removeAllBlankSpaces(str) {
    return str.replace(/ /g, '');
  },
  getCurrentYear() {
    return new Date().getFullYear();
  },
  getXdaysFromNow(daysToAdd) {
    const date = new Date();
    date.setDate(date.getDate() + daysToAdd);
    return date;
  },
  convert24HourTo12Hour(time) {
    return moment(time, 'HH:mm').format('hh:mma');
  },
  addDaysToTimestamp(timestamp, days) {
    return moment(timestamp).add(days, 'days').valueOf();
  },
  addHoursToTimestamp(timestamp, hours) {
    return moment(timestamp).add(hours, 'hours').valueOf();
  },
  addMinutesToTimestamp(timestamp, minutes) {
    return moment(timestamp).add(minutes, 'minutes').valueOf();
  },
  addTimeToTimeString(timeString, timeToAdd, metric) {
    const hourTimestamp = moment(timeString, 'HH:mm').valueOf();
    const newEndHourTimestamp = moment(hourTimestamp).add(timeToAdd, metric).valueOf();
    return moment(newEndHourTimestamp).format('HH:mm');
  },
  extractHoursFromTimestamp(timestamp, zeroDown = false) {
    if (zeroDown) return moment(timestamp).format('HH:00');
    return moment(timestamp).format('HH:mm');
  },
  getLabelFromArray(array, currentValue) {
    return array.find(obj => {
      return obj.value === currentValue;
    }).label;
  },
  getYearFromTimestamp(timestamp) {
    // Convert timestamp to integer if necessary
    if (typeof timestamp === 'string') {
      return moment(parseInt(timestamp, 10)).year();
    }
    return moment(timestamp).year();
  },
  normaliseLink(link) {
    // Function to check if the link has http in front or not.
    // If it doesnt have it should then add it
    // If it has it it should return itself
    if (link.includes('http')) return link;

    return `http://${link}`;
  },
  isWeekend(date) {
    const formattedDate = moment(date).format('e');
    return formattedDate === 6 || formattedDate === 0;
  },
  formatLastName(lastname) {
    if (!lastname) return '';
    return `${lastname.substr(0, 1).toUpperCase()}.`;
  },
  isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);
  },
  getScreenSize() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  },
  transformsIntoMarkdown(source, field) {
    return source[field].toString('html');
  },
  orderArrayByField(arraySource, field, order = 'asc', second_field) {
    return _.orderBy(
      arraySource,
      o => {
        if (second_field) return o[field][second_field];
        return o[field];
      },
      [order],
    );
  },
  parseQueryParams(query) {
    if (!query) return null;
    return new URLSearchParams(query);
  },
  removeTypenames(value) {
    if (value === null || value === undefined) {
      return value;
    }
    if (Array.isArray(value)) {
      return value.map(v => utils.removeTypenames(v));
    }
    if (typeof value === 'object') {
      const newObj = {};
      Object.entries(value).forEach(([key, v]) => {
        if (key !== '__typename') {
          newObj[key] = utils.removeTypenames(v);
        }
      });
      return newObj;
    }
    return value;
  },
  getCharityAddress(charityDataObject) {
    const { location } = charityDataObject;
    return `${location.street} ${location.city}, ${location.state} ${location.postcode}`;
  },
};

export const getSkillMacros = skills =>
  skills.reduce((acc, curr) => {
    if (acc.some(({ _id }) => _id === curr.Macro._id)) return acc;
    return [...acc, curr.Macro];
  }, []);
export const normaliseCausesArray = causes => {
  let causesArray = [];
  if (_.size(causes) > 0) {
    causesArray = causes.map(cause => {
      return {
        _id: cause._id,
        label: cause.name,
      };
    });
  }
  return causesArray;
};

export const normaliseSkillsArray = (skills, subSkills = []) => {
  return skills.map(skill => ({
    _id: skill._id,
    label: skill.name,
    selected: subSkills.find(item => item === skill._id),
    bgColor: _.get(skill, 'skill_color'),
    labelColor: _.get(skill, 'skill_label_color'),
  }));
};

export const getLocationText = location => {
  if (location) {
    const country = _.get(location, 'country') || '';
    const countryText = country ? `, ${country}` : '';
    return `${_.get(location, 'city') || ''} ${_.get(location, 'state') || ''}${countryText}`;
  }
  return '';
};

export const generateCertificationId = userId => {
  const date = new Date();
  return `WO_${userId}_${date.getFullYear()}${String(date.getMonth() + 1).padStart(2, '0')}${String(date.getDate()).padStart(2, '0')}${String(date.getHours()).padStart(
    2,
    '0',
  )}${String(date.getMinutes()).padStart(2, '0')}`;
};

export { utils, update };
