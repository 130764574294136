import React from 'react';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { NavigationDrawer } from 'components/common/Drawer/view';
import { MessageButton, LoadingOverlay } from 'components/common';
import fetchTaskById from 'queries/task/fetchTaskById';
import { getTaskDueDateLabel } from 'lib/task';
import { TimeSheetsBySubmission } from './TimeSheetsBySubmission/view';
import './styles.scss';

export const ModalReviewTimesheets = ({ isOpen, onClose, taskId, taskCreator, isCharityManager, charityId, userId }) => {
  const { loading, data: taskData } = useQuery(fetchTaskById, {
    variables: {
      _id: taskId,
    },
  });
  return (
    <NavigationDrawer open={isOpen} onClose={onClose}>
      <div>
        {loading ? (
          <div className="flexCenter relative fullHeight">
            <LoadingOverlay style={{ opacity: 0.8 }} />
          </div>
        ) : (
          <>
            <div className="title mbottom8">{_.get(taskData, 'taskByID.name')} - Timesheets</div>
            <div className="date mbottom40">Due date: {getTaskDueDateLabel(_.get(taskData, 'taskByID'))}</div>
            {_.get(taskData, 'taskByID.TaskSubmissions')?.map(taskSubmission => {
              return (
                (isCharityManager || taskSubmission.User._id === userId) && (
                  <div className="mbottom80">
                    <div className="flexBetween">
                      <div className="volunteerName">
                        <img className="volunteerProfilePicture" src={taskSubmission.User.picture} alt="user_image" />
                        {taskSubmission.User.firstname} {taskSubmission.User.lastname}
                      </div>
                      {isCharityManager && <MessageButton users={[taskSubmission.User]} isDark />}
                    </div>
                    <div>
                      <TimeSheetsBySubmission taskId={taskId} taskCreator={taskCreator} taskSubmission={taskSubmission} isCharityManager={isCharityManager} charityId={charityId} />
                    </div>
                  </div>
                )
              );
            })}
          </>
        )}
      </div>
    </NavigationDrawer>
  );
};
