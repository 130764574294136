import React, { useEffect, useState, useCallback } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { Button, Input, LoadingOverlay, GoogleInput } from '../../common';
import { localStorage } from '../../../lib/storage';
import { userUtils } from '../../../lib/user';

// Queries
import fetchCharityById from '../../../queries/charity/fetchCharityById';

// Mutations
import updateCharityMutation from '../../../mutations/charity/updateCharity';

const CreateCharityContacts = ({ history, location }) => {
  const [charity, setCharity] = useState({
    location: {
      street: '',
      city: '',
      state: '',
      postcode: null,
      country: '',
      latitude: null,
      longitude: null,
      location: '',
    },
    phone: '',
    email: '',
    website: '',
  });
  const [locationText, setLocationText] = useState('');
  const [loading, setLoading] = useState(false);
  const [emailValid, setEmailValid] = useState('');
  const [phoneValid, setPhoneValid] = useState('');

  // Queries
  const fetchCharitiesByIdQuery = useQuery(fetchCharityById, {
    variables: {
      _id: localStorage.get('charity-temp'),
    },
    skip: !localStorage.get('charity-temp'),
  });
  const charityData = _.get(fetchCharitiesByIdQuery, 'data.charityById');

  // Mutations
  const [updateCharity] = useMutation(updateCharityMutation);

  useEffect(() => {
    // Get the saved charity information in case the user come back later to finish it up
    if (_.size(charityData) > 0) {
      setCharity(prevState => ({
        ...prevState,
        location: {
          street: charityData.location && charityData.location.street,
          location: charityData.location && charityData.location.location,
          city: charityData.location && charityData.location.city,
          postcode: charityData.location && charityData.location.postcode,
          state: charityData.location && charityData.location.state,
          country: charityData.location && charityData.location.country,
          latitude: charityData.location && charityData.location.latitude,
          longitude: charityData.location && charityData.location.longitude,
        },
        phone: charityData.phone,
        email: charityData.email,
        website: charityData.website,
      }));
      setLocationText(charityData.location && charityData.location.street);
    }
  }, [charityData]);

  const onLocationSelected = value => {
    setCharity(prevState => ({
      ...prevState,
      location: {
        ...prevState.location,
        location: value.location,
        street: value.street,
        city: value.city,
        postcode: Number(value.postcode),
        state: value.state,
        country: value.country,
        latitude: value.latitude,
        longitude: value.longitude,
      },
    }));
  };

  const onUpdateCharityInput = (value, fieldName) => {
    setCharity(prevState => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const onNextClicked = async () => {
    setLoading(true);

    await updateCharity({
      variables: {
        id: localStorage.get('charity-temp'),
        charity,
      },
      refetchQueries: [
        {
          query: fetchCharityById,
          variables: { _id: localStorage.get('charity-temp') },
        },
      ],
    });
    setLoading(false);
    history.push({ pathname: '/charity/register/review', state: { ...location.state } });
  };

  const checkURL = () => {
    let string = charity.website;
    if (string) {
      string = `www.${string.replace('http://', '').replace('https://', '').replace('www.', '')}`;
    }
    onUpdateCharityInput(string, 'website');
  };

  const onReWriteCode = input => {
    // Remove all non-numeric characters from the input
    let phoneNumber = input?.replace(/\D/g, '');

    // Check if the phone number starts with "1" or "+1"
    if (phoneNumber?.startsWith('1')) {
      phoneNumber = phoneNumber?.slice(1); // Remove the first character
    } else if (phoneNumber?.startsWith('+1')) {
      phoneNumber = phoneNumber?.slice(2); // Remove the first two characters
    }

    // Insert dashes between the groups of numbers
    phoneNumber = phoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');

    // Return the formatted phone number
    return phoneNumber;
  };

  return (
    <div className="childContainer contactsContainer">
      {loading && <LoadingOverlay style={{ opacity: 0.8 }} />}
      <div className="contentContainer row">
        <div className="col-xs-12">
          <div className="col-xs-12 col-sm-6">
            <GoogleInput onNewPlace={onLocationSelected} value={locationText} onValueChange={setLocationText} />
          </div>
          <div className="col-xs-12 col-sm-6">
            <Input
              name="phone"
              placeholder="Phone e.g. 915-548-5457"
              type="tel"
              errorText={phoneValid}
              value={onReWriteCode(charity.phone)}
              onChange={value => onUpdateCharityInput(value, 'phone')}
              onBlur={() => {
                if (!userUtils.isValidPhone(charity.phone)) {
                  setPhoneValid('Please enter a valid phone e.g. 915-548-5457.');
                } else {
                  setPhoneValid('');
                }
              }}
            />
          </div>
        </div>
        <div className="col-xs-12">
          <div className="col-xs-12 col-sm-6">
            <Input
              name="email"
              placeholder="Email"
              type="email"
              value={charity.email}
              onChange={value => onUpdateCharityInput(value, 'email')}
              errorText={emailValid}
              onBlur={() => {
                if (userUtils.isValidEmail(charity.email) === false) {
                  setEmailValid('Please enter a valid email.');
                } else {
                  setEmailValid('');
                }
              }}
            />
          </div>
          <div className="col-xs-12 col-sm-6">
            <Input name="website" placeholder="Charity Website" type="url" value={charity.website} onChange={value => onUpdateCharityInput(value, 'website')} onBlur={checkURL} />
          </div>
        </div>
      </div>
      <div className="buttonsContainer">
        <Button label="Back" labelColor="#2723EB" bgColor="#FFF" onClick={() => history.goBack()} className="btnDefault btnWhite" />
        <Button
          label="Next"
          onClick={onNextClicked}
          className="btnDefault btnBlueSolid"
          disabled={!(charity.location.latitude && charity.phone && charity.email && charity.website)}
        />
      </div>
    </div>
  );
};

export default withRouter(CreateCharityContacts);
