import _ from 'lodash';
import React, { useState, useMemo, useRef } from 'react';
import { useQuery } from '@apollo/client';
import DraggableList from 'react-draggable-list';
import ForumItem from './forumItem';
import { localStorage } from '../../lib/storage';
import NewForumModal from './newForumModal';
import AddForumIcon from '../../assets/svg/squared-add.svg';

// Queries
import fetchForumsByCharityId from '../../queries/forum/fetchForumsByCharityId';

// Mutation

function ForumCharityManagerSidebar({ macrosData, macroSelected, charityId, unreadMessagesData }) {
  const [displayAllChannels, setDisplayAllChannels] = useState(false);
  const [showNewForumModal, setShowNewForumModal] = useState(false);

  const containerRef = useRef();

  const forumsResult = useQuery(fetchForumsByCharityId, {
    variables: {
      charity_id: charityId,
    },
    fetchPolicy: 'network-only',
    pollInterval: 5000,
  });
  const forumData = _.get(forumsResult, 'data.forumsByCharityId', []);

  const channelsToDisplay = useMemo(() => {
    const channelOrders = JSON.parse(localStorage.get('_wc_channelOrders'));
    let channels = [...forumData, ...macrosData];
    if (channelOrders) {
      channels.sort((cn1, cn2) => {
        if (channelOrders.indexOf(cn1._id) === -1 && channelOrders.indexOf(cn2._id) !== -1) return 1;
        if (channelOrders.indexOf(cn1._id) !== -1 && channelOrders.indexOf(cn2._id) === -1) return -1;
        return channelOrders.indexOf(cn1._id) - channelOrders.indexOf(cn2._id);
      });
    }
    if (!displayAllChannels) {
      channels = [..._.slice(channels, 0, 10)];
    }

    return _.map(channels, channel => ({
      _id: _.get(channel, '_id'),
      isSelected: _.get(channel, '_id') === macroSelected,
      name: channel.name,
      unreadMessages: _.get(
        _.find(unreadMessagesData, item => item.charityId === charityId && item.macroId === _.get(channel, '_id')),
        'unreadMessagesAmount',
        0,
      ),
    }));
  }, [forumData, macrosData, displayAllChannels]);

  const handleListChanged = newList => {
    const newOrder = _.map(newList, item => item._id);
    localStorage.save('_wc_channelOrders', JSON.stringify(newOrder));
  };

  return (
    <>
      <div className="forumSidebarContentContainer" ref={containerRef}>
        <DraggableList
          itemKey="_id"
          template={ForumItem}
          list={channelsToDisplay}
          onMoveEnd={newList => handleListChanged(newList)}
          container={() => containerRef.current}
          commonProps={charityId}
        />
      </div>
      <a className="showMoreChannels" onClick={() => setDisplayAllChannels(!displayAllChannels)}>
        {displayAllChannels ? '- Show less' : '+ Show more'}
      </a>
      <div className="newForum" onClick={() => setShowNewForumModal(true)}>
        <img src={AddForumIcon} alt="Create New Forum" />
        <span>New Forum</span>
      </div>
      {showNewForumModal && <NewForumModal onClose={() => setShowNewForumModal(false)} charityId={charityId} />}
    </>
  );
}

export default ForumCharityManagerSidebar;
