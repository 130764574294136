import React, { useEffect, useMemo } from 'react';
import _ from 'lodash';
import { Document, Page, View, Text, Image, StyleSheet, Font } from '@react-pdf/renderer';
import { utils } from '../../../lib/utils';

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    backgroundColor: '#F7F5F0',
    fontFamily: 'Poppins',
  },
  pageDecoration: {
    position: 'absolute',
    width: '94vw',
    height: '96vh',
    alignSelf: 'center',
    marginVertical: '18',
    border: 1,
    borderColor: '#3042D4',
  },
  certificationLogo: {
    width: '18%',
    transform: 'translateX(-50%)',
    left: '50%',
    paddingBottom: 20,
  },
  certificationText: {
    fontSize: 30,
    fontFamily: 'Petrona',
    fontWeight: 800,
    letterSpacing: '1',
    textAlign: 'center',
    color: '#3042D4',
    paddingBottom: 20,
  },
  name: {
    fontSize: 24,
    fontFamily: 'Poppins',
    fontWeight: 600,
    paddingTop: 20,
    textAlign: 'center',
  },
  table: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    textAlign: 'left',
    marginHorizontal: 20,
  },
  tableHeader: {
    fontSize: 10,
    fontWeight: 600,
    fontFamily: 'Poppins',
    paddingTop: 12,
    paddingBottom: 6,
    borderBottom: 1,
    borderColor: '#96958D',
    marginBottom: 12,
  },
  tableBody: {
    fontSize: 8,
    fontWeight: 400,
    fontFamily: 'Poppins',
    marginBottom: 8,
  },
  footer: {
    position: 'absolute',
    fontSize: 8,
    fontWeight: 400,
    fontFamily: 'Poppins',
    bottom: 30,
    left: 35,
    right: 35,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  logoTextSVG: {
    width: 65,
    paddingBottom: 8,
  },
});

Font.register({ family: 'Petrona', fontWeight: 800, src: `${window.location.origin}/fonts/Petrona-ExtraBold.ttf` });
Font.register({ family: 'Poppins', fontWeight: 600, src: `${window.location.origin}/fonts/Poppins SemiBold 600.ttf` });
Font.register({ family: 'Poppins', fontWeight: 400, src: `${window.location.origin}/fonts/Poppins Regular 400.ttf` });

const CertificationTemplate = ({ user, workHistories }) => {
  const duration = useMemo(() => {
    const createdDate = new Date(user.created);
    const nowDate = new Date();
    return `${utils.formatDate(createdDate)} - ${utils.formatDate(nowDate)}`;
  }, [user]);

  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <View style={styles.pageDecoration} fixed />
        <Image src={`${window.location.origin}/icons/certification.png`} style={styles.certificationLogo} />
        <Text style={styles.certificationText}>Certificate of Completion</Text>
        <Text style={{ fontSize: 12, fontWeight: 400, fontFamily: 'Poppins', textAlign: 'center' }}>This digital certificate verifies the successful completion</Text>
        <Text style={{ fontSize: 12, fontWeight: 400, fontFamily: 'Poppins', textAlign: 'center' }}>of the subsequent tasks by the registered volunteer</Text>
        <Text style={styles.name}>{`${user.firstname} ${user.lastname}`}</Text>
        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '20%', alignSelf: 'center', paddingVertical: 16 }}>
          <View style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#FFF', padding: 4 }}>
            <Image src={`${window.location.origin}/icons/star.png`} style={{ width: 10, height: 10 }} />
            <Text style={{ fontSize: 10, paddingLeft: 4 }}>{parseFloat(user.rate).toPrecision(3)}</Text>
          </View>
          <View style={{ display: 'flex', flexDirection: 'row', backgroundColor: '#FFF', padding: 4 }}>
            <Image src={`${window.location.origin}/icons/calendar.png`} style={{ width: 10, height: 10 }} />
            <Text style={{ fontSize: 10, paddingLeft: 4 }}>{user.hours}</Text>
          </View>
        </View>
        <Text style={{ fontSize: 10, fontWeight: 400, fontFamily: 'Poppins', textAlign: 'center', paddingBottom: 24 }}>{duration}</Text>
        <View style={{ ...styles.table, ...styles.tableHeader }} fixed>
          <Text style={{ width: '20%' }}>Charity</Text>
          <Text style={{ width: '32%' }}>Task</Text>
          <Text style={{ width: '23%' }}>Category</Text>
          <Text style={{ width: '15%' }}>Date</Text>
          <Text style={{ width: '10%', textAlign: 'right' }}>Hours</Text>
        </View>
        {workHistories.map((workHistory, index) =>
          workHistory.works.map((work, jndex) => (
            <View key={`${index}-${jndex}`} style={{ ...styles.table, ...styles.tableBody }}>
              <Text style={{ width: '20%' }}>{jndex === 0 ? workHistory.charity : ''}</Text>
              <Text style={{ width: '32%' }}>{work.task}</Text>
              <Text style={{ width: '23%' }}>{work.category}</Text>
              <Text style={{ width: '15%' }}>{work.date}</Text>
              <Text style={{ width: '10%', textAlign: 'right' }}>{work.hours}</Text>
            </View>
          )),
        )}
        <View style={styles.footer} fixed>
          <Image src={`${window.location.origin}/icons/logo-text.png`} style={styles.logoTextSVG} />
          <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Text>Certificate Id: {user.certId}</Text>
            <Text render={({ pageNumber, totalPages }) => `Page ${pageNumber} of ${totalPages}`}></Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default CertificationTemplate;
