import _ from 'lodash';
import React, { useEffect, useState, useCallback } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { userUtils } from 'lib/user';
import { charityUtils } from 'lib/charity';
import { GoogleInput, Input, Button } from '../common';
import ModalVerifyCharity from './modalVerifyCharity';

import fetchCharityById from '../../queries/charity/fetchCharityById';
import fetchCharitiesByUserId from '../../queries/charity/fetchCharitiesByUserId';
import updateCharityMutation from '../../mutations/charity/updateCharity';

function CharityDetailsPanel() {
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [ein, setEin] = useState('');
  const [einErrorText, setEinErrorText] = useState('');
  const [location, setLocation] = useState(null);
  const [locationText, setLocationText] = useState('');
  const [email, setEmail] = useState('');
  const [emailErrorText, setEmailErrorText] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [website, setWebsite] = useState('');
  const [openVerifyCharity, setOpenVerifyCharity] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchCharitiesByUserIdQuery = useQuery(fetchCharitiesByUserId, {
    variables: {
      userId: localStorage.getItem('userID'),
    },
    skip: !localStorage.getItem('userID'),
    fetchPolicy: 'cache-and-network',
  });
  const charitiesData = _.get(fetchCharitiesByUserIdQuery, 'data.charityByUserId');
  const [updateCharity] = useMutation(updateCharityMutation);

  useEffect(() => {
    if (_.size(charitiesData) > 0) {
      const charityData = charitiesData[0];
      setId(charityData._id);
      setName(charityData.name);
      setEin(charityData.registration);
      setEmail(charityData.email);
      setPhoneNumber(charityData.phone);
      setWebsite(charityData.website);
      setLocation({
        street: _.get(charityData, 'location.street'),
        location: _.get(charityData, 'location.location'),
        city: _.get(charityData, 'location.city'),
        postcode: _.get(charityData, 'location.postcode'),
        state: _.get(charityData, 'location.state'),
        country: _.get(charityData, 'location.country'),
        latitude: _.get(charityData, 'location.latitude'),
        longitude: _.get(charityData, 'location.longitude'),
      });
      setLocationText(userUtils.getLocationtext(charityData.location));
    }
  }, [charitiesData]);

  const onLocationSelected = value => {
    const locationInfo = {
      street: value.street,
      city: value.city,
      postcode: Number(value.postcode),
      state: value.state,
      country: value.country,
      latitude: value.latitude,
      longitude: value.longitude,
      location: value.location,
    };
    setLocation(locationInfo);
  };

  const onSave = async () => {
    setLoading(true);
    await updateCharity({
      variables: {
        id,
        charity: {
          name,
          registration: ein,
          email,
          phone: phoneNumber,
          website,
          location,
        },
      },
      refetchQueries: [
        {
          query: fetchCharityById,
          variables: { _id: id },
        },
      ],
    });
    setLoading(false);
    setOpenVerifyCharity(true);
  };

  const onReWriteCode = useCallback(code => {
    if (code.length > 2) {
      const formatedCode = `${code.slice(0, 2)}-${code.slice(2).replace('-', '')}`;
      return formatedCode;
    }
    return code;
  }, []);

  return (
    <div className="settingsPanel details">
      <h2>Charity Details</h2>
      <div className="detailsContainer">
        <h3>Charity Details</h3>
        <div className="col-xs-12">
          <p>Name</p>
          <Input name="name" type="text" errorText="" value={name} onChange={value => setName(value)} />
        </div>
        <div className="col-xs-12">
          <p>EIN</p>
          <Input
            name="ein"
            type="text"
            errorText={einErrorText}
            value={onReWriteCode(ein)}
            onChange={value => setEin(value.replace('-', ''))}
            onBlur={() => {
              if (!charityUtils.validateEIN(ein)) setEinErrorText('Invalid, please enter your EIN number without dashes or spaces.');
              else setEinErrorText('');
            }}
          />
        </div>
        <div className="col-xs-12">
          <p>Suburb</p>
          <GoogleInput onNewPlace={onLocationSelected} value={locationText} onValueChange={value => setLocationText(value)} />
        </div>
      </div>
      <div className="contactContainer">
        <h3>Contact</h3>
        <div className="col-xs-12">
          <p>Email</p>
          <Input
            name="email"
            type="email"
            errorText={emailErrorText}
            value={email}
            onChange={value => setEmail(value)}
            onBlur={() => {
              if (!userUtils.isValidEmail(email)) setEmailErrorText('Please enter a valid email.');
              else setEmailErrorText('');
            }}
          />
        </div>
        <div className="col-xs-12">
          <p>Phone Number</p>
          <Input name="phoneNumber" type="tel" pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$" value={phoneNumber} onChange={value => setPhoneNumber(value)} />
        </div>
        <div className="col-xs-12">
          <p>Website</p>
          <Input
            name="website"
            type="url"
            value={website}
            onChange={value => setWebsite(value)}
            onBlur={() => {
              if (website.indexOf('https') === -1 && website.indexOf('http') === -1) {
                setWebsite(`https://${website}`);
              }
            }}
          />
        </div>
      </div>
      <Button label="Save" onClick={onSave} disabled={!(name && locationText && email && userUtils.isValidEmail(email))} />
      <ModalVerifyCharity open={openVerifyCharity} onClose={() => setOpenVerifyCharity(false)} />
    </div>
  );
}

export default CharityDetailsPanel;
