import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Chips, Input, LoadingOverlay } from 'components/common';
import { ModalBase } from 'components/common/ModalBase/view';
import fetchCauses from 'queries/causes/fetchCauses';
import { FlatButton } from 'components/common/FlatButton/view';
import { charityUtils } from 'lib/charity';

export const EditCharityModal = ({ onClose, charity, onUpdateCharity }) => {
  const [open, setOpen] = useState(true);
  const { loading, data } = useQuery(fetchCauses);
  const [name, setName] = useState(charity.name);
  const [registration, setRegistration] = useState(charity.registration);
  const [website, setWebsite] = useState(charity.website);
  const [description, setDescription] = useState(charity.description);
  const [selectedCauses, setSelected] = useState(charity.causes);
  const [saving, setSaving] = useState(false);
  const [registrationError, setRegistrationError] = useState('');

  if (!data) {
    return <LoadingOverlay />;
  }

  const onClickCause = chip => {
    if (selectedCauses.includes(chip._id)) {
      setSelected(() => selectedCauses.filter(id => id !== chip._id));
    } else if (selectedCauses.length < 3) {
      setSelected([...selectedCauses, chip._id]);
    }
  };

  const normaliseCausesArray = causesArray => {
    if (causesArray.length === 0) return [];
    return causesArray.map(cause => {
      return {
        _id: cause._id,
        label: cause.name,
        selected: selectedCauses.filter(item => item === cause._id).length > 0,
      };
    });
  };

  const saveChange = async () => {
    setSaving(true);
    await onUpdateCharity({
      name,
      website,
      registration,
      description,
      causes: selectedCauses,
    });
    setOpen(false);
  };

  return (
    <ModalBase open={open} onClose={onClose} className="editCharityModal">
      <h1 className="textCenter textBlack mtop0 mbottom40">{name}</h1>
      <div className="mbottom24">To edit your Charity Name, EIN details or website link, please visit Settings.</div>
      <div className="textBlack bold mbottom16">Charity Name</div>
      <Input name="ein" placeholder="Charity Name" type="text" value={name} onChange={setName} />
      <div className="textBlack bold mbottom16">EIN</div>
      <Input
        name="ein"
        placeholder="EIN"
        type="text"
        value={registration}
        errorText={registrationError}
        onChange={setRegistration}
        onBlur={() => {
          if (charityUtils.validateEIN(registration) === false) {
            setRegistrationError('Invalid, please enter your EIN number without dashes or spaces.');
          } else {
            setRegistrationError('');
          }
        }}
      />
      <div className="textBlack bold mbottom16">Charity Website</div>
      <Input name="ein" placeholder="Charity Website" type="text" value={website} onChange={setWebsite} />
      <div className="textBlack bold mbottom16">About the charity</div>
      <Input
        name="description"
        placeholder="What is your mission & purpose? Write a short description to encourage volunteers to work with you."
        type="text"
        value={description}
        onChange={setDescription}
        maxLength="500"
        multiLine
      />
      <div className="textBlack bold mbottom16">Causes</div>
      <div className="textBlack light">Select maximum 3 causes that are relevant to your charity</div>
      <Chips onClick={onClickCause} containerStyle="mtop20 mbottom40" values={normaliseCausesArray(data.causes)} isRounded />
      <FlatButton label="Save Changes" filled className="mLeftAuto mRightAuto" disabled={saving} onClick={saveChange} />
    </ModalBase>
  );
};
