import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import ArrowDownIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-down';
import ArrowUpIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-up';
import { userUtils } from '../../lib/user';
import ForumIcon from '../../assets/svg/forum.svg';

// External components
import GroupChatsSidebar from './groupChatsSidebarComponent';
import ForumCharityManagerSidebar from './forumCharityManagerSidebar';
import ForumVolunteerSidebar from './forumVolunteerSidebar';

function Sidebar({ macrosData, userByIdData, groupMessagesByUserData, groupIdFromUrl, macroSelected, setMacroSelected, charitySelected, setCharitySelected, unreadMessagesData }) {
  const [isForumCollapsed, setIsForumCollapsed] = useState(true);
  const [skillGroupsOpened, setSkillGroupsOpened] = useState(true);

  const isCharityManager = userUtils.isCharityManager(userByIdData);
  const orderedGroupsData = _.get(userByIdData, 'group_messages_orders', []);

  const charityId = useMemo(() => (isCharityManager ? _.get(userByIdData, 'CharitiesAdmin.0._id') : ''), [userByIdData]);
  const followingCharityIds = useMemo(() => (!isCharityManager ? _.map(_.get(userByIdData, 'CharitiesFollowing', []), item => item._id) : []), [userByIdData]);
  const unreadMessagesDataForForum = useMemo(() => _.filter(unreadMessagesData, item => !item.groupId), [unreadMessagesData]);
  const unreadMessagesDataForGroup = useMemo(() => _.filter(unreadMessagesData, item => item.groupId), [unreadMessagesData]);

  return (
    <div className="sidebar">
      <div className="sidebarSectionContainer">
        <div className="sidebarHeadingSectionContainer">
          {isCharityManager && (isForumCollapsed ? <ArrowDownIcon onClick={() => setIsForumCollapsed(false)} /> : <ArrowUpIcon onClick={() => setIsForumCollapsed(true)} />)}
          <h2>
            <img src={ForumIcon} alt="Forum Icon" />
            Forums
          </h2>
        </div>
        {skillGroupsOpened && (
          <div className="contentSidebarContainer">
            {isCharityManager ? (
              isForumCollapsed && (
                <ForumCharityManagerSidebar macrosData={macrosData} macroSelected={macroSelected} charityId={charityId} unreadMessagesData={unreadMessagesDataForForum} />
              )
            ) : (
              <ForumVolunteerSidebar
                userByIdData={userByIdData}
                macroSelected={macroSelected}
                setMacroSelected={setMacroSelected}
                charitySelected={charitySelected}
                setCharitySelected={setCharitySelected}
                unreadMessagesData={unreadMessagesDataForForum}
              />
            )}
          </div>
        )}
        <hr />
        <GroupChatsSidebar groupsData={groupMessagesByUserData} groupIdFromUrl={groupIdFromUrl} orderData={orderedGroupsData} unreadMessagesData={unreadMessagesDataForGroup} />
      </div>
    </div>
  );
}

export default Sidebar;
