import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Button, Menu, Popover } from 'components/common';
import { FlatButton } from 'components/common/FlatButton/view';
import { IconButton } from 'components/common/IconButton/view';
import { localStorage } from 'lib/storage';
import followCharityMutation from 'mutations/charity/followCharity';
import unfollowCharityMutation from 'mutations/charity/unfollowCharity';
import searchCharities from 'queries/charity/searchCharities';
import fetchUserById from 'queries/user/fetchUserById';
import CheckIcon from 'assets/svg/check.svg';
import DotsIcon from 'assets/svg/dots.svg';
import ConnectWhiteIcon from 'assets/svg/connect-white.svg';
import { LeaveModal } from './LeaveModal/view';

export const CharityNetworkButton = ({ charity }) => {
  const [openAnchorEl, setOpenAnchorEl] = useState();
  const [showJoinModal, setShowJoin] = useState(false);
  const [showLeaveModal, setShowLeave] = useState(false);
  const [followCharity] = useMutation(followCharityMutation);
  const [unfollowCharity] = useMutation(unfollowCharityMutation);
  const userId = localStorage.get('userID');
  const inNetwork = charity.charity_followers.some(item => item.user_id === userId);

  const handleConnect = () => {
    if (!userId) {
      setShowJoin(true);
      return;
    }

    followCharity({
      variables: {
        id: charity._id,
        user_id: userId,
      },
      refetchQueries: [
        {
          query: searchCharities,
          variables: {
            searchText: '',
            searchCauses: [],
            status: 1,
          },
        },
        {
          query: fetchUserById,
          variables: { id: userId },
        },
      ],
    });
  };

  const handleLeaveNetwork = () => {
    unfollowCharity({
      variables: {
        id: charity._id,
        user_id: userId,
      },
      refetchQueries: [
        {
          query: searchCharities,
          variables: {
            searchText: '',
            searchCauses: [],
            status: 1,
          },
        },
        {
          query: fetchUserById,
          variables: { id: userId },
        },
      ],
    });
    setOpenAnchorEl(undefined);
  };

  const handleOpenPopover = event => {
    event.preventDefault();
    setOpenAnchorEl(event.currentTarget);
  };

  return (
    <div>
      {inNetwork ? (
        <div className="flexStart textPrimary">
          <FlatButton icon={CheckIcon} label="In Network" bordered disabled />
          <IconButton icon={DotsIcon} onClick={handleOpenPopover} />
          <Popover open={!!openAnchorEl} anchorEl={openAnchorEl} handleRequestClose={() => setOpenAnchorEl(undefined)} className="userPopover">
            <Menu
              values={[
                {
                  label: 'Leave Network',
                  action: () => {
                    setOpenAnchorEl(undefined);
                    setShowLeave(true);
                  },
                },
              ]}
              className="mainMenuUserContent"
            />
          </Popover>
        </div>
      ) : (
        <FlatButton icon={ConnectWhiteIcon} label="Connect" onClick={handleConnect} filled className="mleft25" />
      )}
      {showJoinModal ? (
        <div className="joinModal" onClick={() => setShowJoin(false)}>
          <div className="content">
            <h1>Join Worthy</h1>
            <p>You&apos;ll need to have an account and be logged in to connect to a charity.</p>
            <Link to="/sign-up">
              <Button label="Sign Up" />
            </Link>
            <div className="btnLoginContainer">
              <span>Or </span>
              <Link to="/login">
                <button className="btnLogin" type="button">
                  Login
                </button>
              </Link>
            </div>
          </div>
        </div>
      ) : null}
      {showLeaveModal && <LeaveModal onLeave={handleLeaveNetwork} onClose={() => setShowLeave(false)} />}
    </div>
  );
};
