import React from 'react';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';

export default function (props) {
  return (
    <Menu className={props.className ? props.className : ''} width={props.width && props.width}>
      {props.values &&
        props.values.map(item => {
          return (
            <MenuItem
              innerDivStyle={props.innerDivStyle ? props.innerDivStyle : {}}
              key={item.label}
              primaryText={item.label}
              className={item.className ? item.className : ''}
              onClick={item.action}
            />
          );
        })}
    </Menu>
  );
}
