import moment from 'moment';
import _ from 'lodash';

export const TASK_STATUS = {
  DRAFT: 0,
  ACTIVE: 1,
  IN_REVIEW: 2,
  COMPLETED: 3,
};

export const TASK_TYPE = {
  FACE_TO_FACE: 1,
  REMOTE: 2,
};

export const TASK_FREQUENCY = {
  ONE_OFF: 1,
  ONGOING: 2,
};

export const TASK_ONGOING_TYPE_MAP = {
  1: 'Short Term (Less than 3 months)',
  2: 'Long Term (More than 3 months)',
};

export const TASK_ONGOING_HOURS_MAP = {
  1: 'Less than 5 hours per week',
  2: '5-10 hours per week',
  3: '10-20 hours per week',
};

export const hasTaskStarted = task => {
  if (_.get(task, 'frequency') === TASK_FREQUENCY.ONGOING) return true;
  // eslint-disable-next-line new-cap
  const startDate = new moment(_.get(task, 'oneoff_frequency_config.start'));
  if (moment().isAfter(startDate)) return true;
  return false;
};

export const isTaskAdvertised = task => {
  if (_.get(task, 'frequency') === TASK_FREQUENCY.ONGOING) {
    if (_.get(task, 'TaskSubmissions')?.some(submission => submission.status > 3)) return false;
    return true;
  }
  // eslint-disable-next-line new-cap
  const startDate = new moment(_.get(task, 'oneoff_frequency_config.start'));
  if (moment().isAfter(startDate)) return false;
  return true;
};

export const hasTaskValidSubmission = task => {
  const hasValidSubmission = _.get(task, 'TaskSubmissions').some(submission => submission.status !== 2);
  return hasValidSubmission;
};

export const hasTaskAssignedSubmission = task => {
  const hasValidSubmission = _.get(task, 'TaskSubmissions').some(submission => submission.status === 3);
  return hasValidSubmission;
};

export const hasTaskProgressSubmission = task => {
  const hasValidSubmission = _.get(task, 'TaskSubmissions').some(submission => submission.status > 3);
  return hasValidSubmission;
};

export const getReviewedSubmission = task => {
  const hasValidSubmission = _.get(task, 'TaskSubmissions').filter(submission => submission.status === 6);
  return hasValidSubmission;
};

export const getTaskStatus = task => {
  switch (_.get(task, 'status')) {
    case TASK_STATUS.DRAFT:
      return 'Upcoming';
    case TASK_STATUS.ACTIVE:
      return (hasTaskStarted(task) && hasTaskAssignedSubmission(task)) || hasTaskProgressSubmission(task) ? 'In progress' : 'Upcoming';
    case TASK_STATUS.IN_REVIEW:
      return 'Awaiting review';
    case TASK_STATUS.COMPLETED:
      return 'Completed';
    default:
      return '';
  }
};

export const getTaskDueDateLabel = task => {
  if (_.get(task, 'frequency') === TASK_FREQUENCY.ONGOING) return 'Ongoing';
  if (_.get(task, 'oneoff_frequency_config.is_date_flexible')) return 'Flexible';
  // eslint-disable-next-line new-cap
  return moment(_.get(task, 'oneoff_frequency_config.start')).format('MMMM Do YYYY');
};

export const getSumittedHours = task => {
  let submittedMilliseconds = 0;
  _.get(task, 'TaskSubmissions').map(submission =>
    _.get(submission, 'TaskTimesheet').map(timesheet => {
      if (timesheet.status === 1) submittedMilliseconds = submittedMilliseconds + timesheet.end - timesheet.start;
      return submittedMilliseconds;
    }),
  );
  const submittedHours = moment.utc(submittedMilliseconds).format('HH:mm:ss');
  return submittedHours;
};

export const getApprovedHours = task => {
  let approvedMilliseconds = 0;
  _.get(task, 'TaskSubmissions').map(submission =>
    _.get(submission, 'TaskTimesheet').map(timesheet => {
      if (timesheet.status === 2) approvedMilliseconds = approvedMilliseconds + timesheet.end - timesheet.start;
      return approvedMilliseconds;
    }),
  );
  const approvedHours = moment.utc(approvedMilliseconds).format('HH:mm:ss');
  return approvedHours;
};
