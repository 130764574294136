import gql from 'graphql-tag';

export default gql`
  query SearchMessages($search: MessageSearchInput!) {
    searchMessages(search: $search) {
      messages {
        _id
        type
        body
        created
        _to_user_id
        _from_user_id
        direction
        seen
        UserTo {
          _id
          firstname
          lastname
        }
        UserFrom {
          _id
          firstname
          lastname
          picture
        }
        context {
          _task_id
          _charity_id
          _skill_id
          _macro_id
          _group_id
          Task {
            _id
            name
          }
          Charity {
            _id
            name
            charity_users {
              user_id
            }
          }
          Skill {
            _id
            name
            macro
          }
          Macro {
            _id
            name
          }
          GroupMessages {
            _id
            name
            Users {
              _id
              firstname
              lastname
            }
          }
        }
      }
      totalCount
      status
    }
  }
`;
