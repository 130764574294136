import { useQuery } from '@apollo/client';
import React, { useState } from 'react';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { localStorage } from 'lib/storage';
import fetchUserById from '../../queries/user/fetchUserById';
import { userUtils } from '../../lib/user';
import AccountPanel from './account';
import Notification from './notification';
import AccessPanel from './access';
import CharityDetailsPanel from './details';

import './styles/settingsStyles.scss';

const subSidebarMenuitems = {
  Personal: [
    {
      id: 'account',
      title: 'My Account',
    },
    {
      id: 'notification',
      title: 'Notifications',
    },
  ],
  Charity: [
    {
      id: 'details',
      title: 'Charity Details',
    },
    {
      id: 'access',
      title: 'Access & Privilege',
    },
    {
      id: 'upgrade',
      title: 'Upgrade Plan',
    },
  ],
};

const contentPanels = {
  account: <AccountPanel />,
  notification: <Notification />,
  access: <AccessPanel />,
  details: <CharityDetailsPanel />,
};

function Settings(props) {
  const menu = _.get(props, 'location.state.menu');
  const [selectedItem, setSelectedItem] = useState(menu || subSidebarMenuitems.Personal[0]);

  const _current_user_id = localStorage.get('userID');
  const userByIdResults = useQuery(fetchUserById, {
    variables: {
      id: _current_user_id,
    },
  });
  const userByIdData = _.get(userByIdResults, 'data.userByID');
  const isCharityManager = userUtils.isCharityManager(userByIdData);

  const renderCharityManagerSidebar = () => {
    const keys = Object.keys(subSidebarMenuitems);
    return (
      <>
        {keys.map((key, index) => {
          const menuitems = subSidebarMenuitems[key];
          return (
            <div key={`${key}-${index}`} className="sidebarSectionContainer">
              <a className="menu header">{key}</a>
              {menuitems.map(menuitem => {
                const isSelected = selectedItem.id === menuitem.id;
                return (
                  <a
                    key={menuitem.id}
                    className={`menu item ${isSelected ? 'selected' : ''}`}
                    onClick={e => {
                      e.preventDefault();
                      setSelectedItem(menuitem);
                    }}
                  >
                    {menuitem.title}
                  </a>
                );
              })}
            </div>
          );
        })}
      </>
    );
  };

  const renderVolunteerSidebar = () => {
    const menuitems = subSidebarMenuitems.Personal;
    return (
      <div className="sidebarSectionContainer">
        {menuitems.map(menuitem => {
          const isSelected = selectedItem.id === menuitem.id;
          return (
            <a
              key={menuitem.id}
              className={`menu item ${isSelected ? 'selected' : ''}`}
              onClick={e => {
                e.preventDefault();
                setSelectedItem(menuitem);
              }}
            >
              {menuitem.title}
            </a>
          );
        })}
      </div>
    );
  };

  return (
    <div className="settings">
      <div className="sidebar">{isCharityManager ? renderCharityManagerSidebar() : renderVolunteerSidebar()}</div>
      <div className="settingsContentContainer">{contentPanels[selectedItem.id]}</div>
    </div>
  );
}

export default withRouter(Settings);
