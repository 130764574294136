import React from 'react';
import '../styles/legalStyles.css';

function TermsAndConditions() {
  return (
    <div className="legalContainerNew">
      <div className="header">
        <h1>Terms of Service</h1>
      </div>
      <div className="mainContainer">
        <div className="content">
          <h1 id="terms">Terms of Service</h1>
          <p>
            We, Worthy Network Inc., a Delaware registered C Corporation trading as Worthy (and its successors and assignees) (“Worthy”, “we”, “our” or “us”). We own and operate
            this online platform (Platform), which is available at: <a href="https://www.worthycause.com">https://www.worthycause.com</a> and may be available through other
            addresses or channels including any mobile application.
          </p>
          <p>
            The Platform connects charities (Charity), volunteers (Volunteer), and companies or other entities (Businesses) who are interested in connecting with each other and
            searching for opportunities to collaborate.
          </p>
          <p>
            <span className="bold">Authorised Users</span>: Charities and Businesses may authorise their employees, agents or contractors to access and use the Platform on their
            behalf (Authorised User). Charities and Businesses acknowledge and agree to be responsible for their Authorised Users’ use (including negligent use) of the Platform.
          </p>
          <h1 id="acceptance">Acceptance</h1>
          <p>
            By accessing and/or using our Platform, you; each Volunteer, Charity, and Business using our Platform (referred to as you or a User ) (1) warrant to us that you have
            reviewed the Terms of Use, these Terms of Service and our Privacy Policy (each, available on the Platform and/or our website) (collectively, Terms ), with your parent
            or legal guardian (if you are under 18 years of age), and you understand them; (2) warrant to us that you have the legal capacity to enter into a legally binding
            agreement with us or (if you are under 18 years of age) you have your parent’s or legal guardian’s permission to access and use the Platform and they have agreed to the
            Terms on your behalf; (3) agree to use the Platform in accordance with the Terms; and (4) if you are a Business or Charity, you warrant to us that any Authorised Users
            have:
          </p>
          <p>(a) your prior consent and authority to use and access the Platform on your behalf; and</p>
          <p>(b) read and agree to comply with these Terms.</p>
          <p>
            Please read these Terms carefully as they govern and apply to any and all use of the Platform. We may, at any time and at our discretion, vary these Terms by publishing
            the varied terms on our Platform. By continuing to use the Platform, you accept the Terms as they apply from time to time. We recommend you check our Platform regularly
            to ensure you are aware of our current Terms. If you do not agree to the Terms, please immediately cease using and delete our Platform.
          </p>
          <h2>Platform Summary</h2>
          <p>
            (a) The Platform provides an introductory service for Charities, Businesses and Volunteers to connect and collaborate, including by providing a directory of registered
            charities, charitable events and volunteering opportunities.
          </p>
          <p>
            (b) <span className="bold">Charities</span>: A Charity may use the Platform to:
          </p>
          <ul>
            <li>
              create a charity page, outlining information about the Charity, including but not limited to the Charity’s purposes, events, locations, ambassadors and sponsors (if
              relevant) (Charity Page);
            </li>
            <li>find and connect with other Charities;</li>
            <li>find and connect with Businesses;</li>
            <li>
              create and manage tasks and roles for Volunteers, including the duration, skills and certifications required, location and any other relevant information to the
              opportunity (Volunteering Opportunities);
            </li>
            <li>leave reviews, ratings and feedback on Volunteers;</li>
            <li>invite volunteers to join the Charity’s network.</li>
          </ul>
          <p>
            (c) <span className="bold">Volunteers</span>: Volunteers may use the Platform to:
          </p>
          <ul>
            <li>create a Volunteer profile and track their philanthropy;</li>
            <li>if they are employees of a Business, associate their Volunteer profile and/or Volunteering Opportunities with that Business;</li>
            <li>browse Charity Pages for information about a Charity and to discover information about any Volunteering Opportunities;</li>
            <li>connect with Charities on the Platform and receive notifications about that Charity’s activities;</li>
            <li>search and find Volunteering Opportunities based on skills, location and other filters as may be added to the Platform from time to time;</li>
            <li>record Volunteering Opportunities completed, including the number of hours contributed to a Charity;</li>
            <li>print a transcript with their contributions to date.</li>
          </ul>
          <p>
            (d) <span className="bold">Businesses</span>: Businesses may use the Platform to:
          </p>
          <ul>
            <li>nominate its employees as Volunteers and verify any Volunteers that have associated their profile with the Business;</li>
            <li>connect with Charities;</li>
            <li>find out about Volunteering Opportunities, Charities and charitable events;</li>
            <li>do other things offered on the Platform as it is updated from time to time.</li>
          </ul>
          <p>
            (e) <span className="bold">Communications</span>: Volunteers and Businesses may contact a Charity using the contact details found on a Charity’s Charity Page or through
            the Platform’s messaging service. Users can use the Platform’s messaging function to message other Users, including bilaterally within the same organisation and
            creating group messages with other Users. You are responsible for ensuring your use, and where applicable your Authorised Users’ use, of the messaging functions in
            accordance with these Terms and any other applicable laws.
          </p>
          <h2>Acknowledgment</h2>
          <p>You understand and agree that:</p>
          <ol type="a">
            <li>
              the Platform is an online introductory platform only, and that our responsibilities are limited to facilitating the User functionality availability of the Platform.
            </li>
            <li>
              We are not an employment agency or labour hire business, Charities and Businesses are not our partners or agents and Volunteers are not our employees or contractors.
              We are not responsible for the acts, omissions, or statements of or by any User, whether a Volunteer, Charity or Business.
            </li>
            <li>
              We are not a party to any agreement entered into between a Charity and a Volunteer or Business. We have no control over (and are not responsible for) the conduct of
              Charities, Volunteers, Businesses or any other Users of the Platform.
            </li>
            <li>
              Charities are responsible for ensuring (without limitation) that Volunteers have the required certification, checking identification and determining the suitability
              of a Volunteer for a Volunteering Opportunity. Volunteers are responsible for determining that a Volunteering Opportunity fits their skill set and experiences.
            </li>
            <li>
              We accept no liability for any aspect of the Volunteer, Business, and Charity interaction, including but not limited to the description of Volunteering Opportunities
              offered, any communications on or outside the Platform, the performance of any services by Volunteers or a Charity’s occupational health and safety obligations
              towards Volunteers when performing volunteering services at the Charity’s premises or at a location determined by the Charity.
            </li>
          </ol>
          <h2>Registration and Profiles</h2>
          <p>
            (a) You may browse and view the Platform as an unregistered user of the Platform. You must register on the Platform and create an account (Account) to access some
            features on the Platform. Charities must register for an Account in order to create a Charity Page. Volunteers must register for an Account to create a profile.
            Businesses must register for an Account to __________. All Users must register for an Account to create or post any user content or reviews. We will request charity
            registration information before registering an Account for a Charity (but we are not responsible for ensuring their charitable status. We do not endorse any particular
            Charity or Charities, We simply host a Platform where you can search and find Charities of interest to you, based on your own selection criteria and judgement.)
          </p>
          <p>
            (b) You must, and where applicable, you must ensure your Authorised Users, provide basic information when registering for an Account including name, name of the
            Business or Charity (as applicable), email address, and location and we will provide you with a username and password, or such other details as we may reasonably
            request from time to time. Users are responsible for keeping their information accurate and up to date.
          </p>
          <p>
            (c) <span className="bold">Charities</span>: When registering an Account for a Charity, we will ask for details of the Charity’s registration and authority to fundraise
            or similar information if the Charity is registered overseas. While we provide this as a convenience, we are not responsible for verifying the non-profit status of any
            Charity, and you are responsible for validating and maintaining such status, and describing your Charity and status accurately and kept up to date.
          </p>
          <p>
            (d) <span className="bold">Volunteers</span>: For Volunteers, once a Volunteer has created an Account, their account information will be used to create a profile which
            may then be curated (Profile). You are responsible for ensuring your Profile is accurate and kept up to date
          </p>
          <p>
            (e) <span className="bold">Businesses</span>: For Businesses, we will ask for evidence including the registered company details, officer details and such other
            information as may be reasonably required to register. If you wish to connect Volunteers as employees of your Business, we may require further information to verify
            this employment relationship. While we provide this as a convenience, we are not responsible for verifying this relationship and you are responsible for ensuring any
            such relationships are described accurately and kept up to date.
          </p>
          <p>
            (f) You must, and where applicable, you must ensure your Authorised Users, provide accurate, current and complete information during the registration process and you
            must update such information to keep it accurate, current and complete. 501(c)(3) Charities must immediately notify us if their registration with the Internal Revenue
            Service or not-for-profit status changes, lapses, is revoked or is not renewed, or is otherwise no longer in good standing or threatened to be revoked, or if you are a
            Charity registered overseas, you must tell us if your Charity’s registration or not-for-profit status lapses, is revoked or is not renewed in the Charity’s country of
            establishment or is otherwise no longer in good standing or threatened to be revoked.
          </p>
          <p>
            (g) You, and where applicable, you must ensure any Authorised Users, are responsible for keeping your Account and in the case of Volunteers; Profile details, username
            and password confidential and you will be liable for all activity on your Account, whether or not you authorised such activities or actions.
          </p>
          <p>(h) You will immediately notify us of any unauthorised use of your Account or your or any Authorised User’s breach of these Terms.</p>
          <p>
            (i) At our sole discretion, we may refuse to allow any person, Business or Charity to register or create an Account and/or Profile, and may revoke, suspend, disable or
            terminate Accounts or Profiles.
          </p>
          <p>(j) You agree not to use the Platform to impersonate any person or entity, or otherwise misrepresent your identity or your affiliation with a person or entity.</p>
          <p>
            Certain material you may post on our Platform is or may be available to the public, including without limitation any public profile data, feedback, reviews, etc.
            (collectively, &quot;Public Postings&quot;). These Public Postings will be treated as non-confidential and non-proprietary. You are responsible for any Public Postings
            and the consequences of sharing or publishing such content with others or the general public. This includes, for example, any personal information, such as your
            address, the address of others, or your current location. WE ARE NOT RESPONSIBLE FOR THE CONSEQUENCES OF PUBLICLY SHARING OR POSTING ANY PERSONAL OR OTHER INFORMATION
            ON THE PLATFORM.
          </p>
          <h2>Reviews</h2>
          <p>
            (a) The Platform provides functionality for Charities to review a specific Volunteer or all Volunteers on a Volunteering Opportunity and provide feedback on the
            volunteering services performed by a Volunteer or several Volunteers for the Charity (Review).
          </p>
          <p>
            (b) Reviews of any Volunteer will be placed on that Volunteer’s Profile. Reviews can be viewed by any User and will remain viewable until the relevant Volunteer’s
            Account or Profile is removed or terminated. If you believe a Review infringes your legal rights, you must notify us immediately.
          </p>
          <p>(c) Charities must provide true, fair and accurate information in their Reviews.</p>
          <p>
            (d) If we consider that a Review is untrue, unfair, inaccurate, offensive or inappropriate, we may delete the Review, contact the Charity regarding the Review, or ban
            the Charity from posting the Review. We do not undertake to review each Review made by a Charity but we may elect to review Reviews in our discretion, however, we are
            not obligated to do so, and are not responsible for inaccurate or inappropriate Reviews, whether or not reviewed by us.
          </p>
          <p>
            (e) No review of or comment on, or failure to review or comment on a Review by us will (1) relieve a Charity from, or alter, affect, or reduce, the obligations and
            liabilities of a Charity under these Terms or otherwise at law; (2) constitute any representation by us that the Review is true, fair accurate or complies with law; or
            (3) prejudice the rights against our rights under these Terms or otherwise at law.
          </p>
          <p>(f) To the maximum extent permitted by law, we are not responsible for the content of any Reviews.</p>
          <p>(g) Volunteers and Businesses may print a transcript setting out the volunteering services they have completed including any Reviews given by Charities.</p>
          <h2>Fees and Payments</h2>
          <p>
            (a) Unless otherwise agreed between the parties, it is free to register an Account on the Platform. There is no charge for a Volunteer to create a Profile, or for other
            Users to review content on the Platform, including Charity Pages.
          </p>
          <p>
            (b) Where fees are applicable, these will be set out on the Platform or otherwise communicated to you. Any payments will be made through our third party payment
            processor, or by any other payment method advised by us and set out on the Platform.
          </p>
          <p>
            (c) We may change the fee and payment terms applying to the Platform at our sole discretion and any change is effective immediately upon us publishing the change on the
            Platform, or otherwise communicating such change to you, and will apply to any use of the Platform following the change.
          </p>
          <h2>License to use our Platform</h2>
          <p>
            We grant you, so long as you maintain an Account in good standing, during the term of this agreement, a non-exclusive, royalty-free, revocable, worldwide,
            non-transferable licence, including the right to sublicense to Authorised Users, to use our Platform in accordance with these Terms (Licence). All other uses are
            prohibited without our prior written consent.
          </p>
          <p>You must, and you must ensure your Authorised Users, use the Platform in accordance with the Terms of Use which are available on our website.</p>
          <h2>Prohibited conduct</h2>
          <p>
            In consideration for the Licence granted to you, you must not, and you must ensure any Authorised Users, do, or attempt to do, anything that is unlawful; anything
            prohibited by any laws or regulations which apply to our Platform or which apply to you or your use of our Platform; anything which we or others would consider
            inappropriate; or anything which might bring us or our Platform into disrepute, including (without limitation):
          </p>
          <p>
            (a) anything that would constitute a breach of an individual’s privacy (including soliciting personal information from any person or uploading private or personal
            information without an individual’s consent), or any other legal rights;
          </p>
          <p>(b) using our Platform to defame, harass, threaten, menace, offend or impersonate any person or other entity;</p>
          <p>(c) interfering with any User using our Platform;</p>
          <p>
            (d) tampering with or modifying our Platform, knowingly transmitting viruses or other disabling features, or damaging or interfering with our Platform, including
            (without limitation) using trojan horses, viruses or piracy or programming routines that may damage or interfere with our Platform;
          </p>
          <p>
            (e) anything that might violate any local, state, national or other law or regulation or any order of a court in the United States of America and in addition for
            Charities registered or operating overseas, any local, state, national or other law or regulation or any order of a court in a country where that Charity is registered
            or is operating;
          </p>
          <p>
            (f) organising or sponsoring any event via the Platform that might violate any local, state, national or other law or regulation or any order of a court or bring us or
            our Platform into disrepute;{' '}
          </p>
          <p>(g) as a Charity, using the Platform to find Volunteers to perform volunteer work where work should be performed by an employee of the Charity;</p>
          <p>
            (h) as a Charity, representing or stating that the Charity has 501(c)(3) status in the United States of America if the Charity does not in fact have 501(c)(3) status;
          </p>
          <p>
            (i) for a United States of America based Charity, registering for an Account on the Platform without being registered or having a current 501(c)(3)registration with the
            IRS or equivalent not- for-profit status with a state or territory regulator and for Charities registered overseas, registering for an Account without being registered
            or having current registration in that Charity’s country of establishment;
          </p>
          <p>
            (j) as a Volunteer or Business, making any offers to Charities to perform volunteering services when she or he does not possess appropriate qualifications, or
            misrepresenting her or his skill set, identity or any other information to a Charity;
          </p>
          <p>
            (k) as a Volunteer or Business, if you submit any questions to a Charity or comment publicly on a Charity’s event page, limiting your comments to discussing that
            Charity’s event and leaving no comment which is defamatory, derogatory or obscene;
          </p>
          <p>
            (l) as a Charity, if you write any Reviews or provide any feedback about a Volunteer on the Platform, ensuring your Reviews do not make any comment which is defamatory,
            derogatory, inappropriate or obscene;
          </p>
          <p>(m) using our Platform to send unsolicited email messages;</p>
          <p>(n) facilitating or assisting a third party to do any of the above acts; or</p>
          <p>
            (o) any content, acts or omissions which would otherwise violate any other applicable laws, terms or policies, such as, without limitation, our separate Terms of Use,
            as available on our website.
          </p>
          <p>You, or Authorised Users, may share and embed links from our Platform on third-party websites, however:</p>
          <p>
            (a) You must not, and must ensure any Authorised Users do not, represent yourself as being in any way connected with Worthy or any business conducted by Worthy or
            having any relationship of employment, trust, agency, partnership or joint venture without obtaining our prior written consent, which may be withheld at our absolute
            discretion;
          </p>
          <p>(b) We reserve the right to remove or request the removal of any such links at our sole discretion.</p>
          <p>
            If you are a Charity or Business, then you shall be responsible for clearly and conspicuously disclosing to Volunteers or other end users of your content (including
            without limitation in any posted terms and conditions and in any written agreements with them) (i) that you (and not we) are solely responsible for all content and any
            services you provide to them or any messages or content sent (or attempted to send) to, from, between or among, you, them and any other Users, (ii) that we are not
            responsible for any disputes between you and your end users. You shall not state or imply otherwise.
          </p>
          <h2>Exclusion of competitors</h2>
          <p>
            You are prohibited from accessing, studying, or using our Platform, including the data, information and materials available on it or obtained through accessing or using
            it (Content), in any way that does or could compete with our business. If you breach this term, we will hold you responsible for any liability, loss, damage, penalty,
            fines, fees, cost or expense, howsoever arising, whether direct or indirect and/or whether present, unascertained, future or contingent (Liability) we suffer, and hold
            you accountable for any profits that you may make from non-permitted use. Materials and User Content you post to the Platform may not contain promotions for, mentions
            of, or URLs or links to websites that compete with the Platform nor other competitive content or references.
          </p>
          <h2>Information</h2>
          <p>
            You acknowledge and agree that the Content is not comprehensive and is for general information purposes only. It does not take into account your specific needs,
            objectives or circumstances, and is not advice. While we use reasonable attempts to ensure the accuracy and completeness of the Content, we make no representation or
            warranty regarding it, to the extent permitted by law. The Content is subject to change without notice. However, we do not undertake to keep our Platform up-to-date and
            we are not liable if any Content is inaccurate or out- of-date.
          </p>
          <h2>Intellectual Property Rights</h2>
          <p>
            You, and you must ensure any Authorised Users, acknowledge and agree that unless otherwise indicated, we own or licence all rights, title and interest (including
            intellectual property rights) in our Platform and all of the Content. Anything in these Terms, your use of our Platform and your use of and access to any Content does
            not grant or transfer to you, or any Authorised Users, any rights, title or interest in relation to our Platform or the Content.
          </p>
          <p>You must not, and you must ensure any Authorised Users must not, without the prior written consent of ourselves or the owner of the Content (as applicable):</p>
          <p>(a) copy or use, in whole or in part, the Platform or any Content for any purpose;</p>
          <p>(b) reproduce, retransmit, distribute, disseminate, sell, publish, broadcast or circulate any Content to any third party; or</p>
          <p>(c) breach any intellectual property rights connected with our Platform, including (without limitation):</p>
          <p>
            (1) altering or modifying any of the Content; (2) causing any of the Content to be framed or embedded in another website; or (3) creating derivative works from the
            Content.
          </p>
          <h2>User Content</h2>
          <p>
            You may be permitted to post, upload, publish, submit or transmit relevant information and content, including Reviews (User Content) on our Platform. By making
            available any User Content on or through our Platform, you grant to us a worldwide, irrevocable, perpetual, non-exclusive, transferable, royalty-free licence to use the
            User Content, with the right to use, view, copy, adapt, modify, distribute, license, sell, transfer, communicate, publicly display, publicly perform, transmit, stream,
            broadcast, access, monetize, or otherwise exploit such User Content on, through, or by means of our Platform, without compensation or attribution to you.
          </p>
          <p>
            You agree that you are solely responsible for all User Content that you, or any of your Authorised Users, make available on or through our Platform. You represent and
            warrant that:
          </p>
          <p>
            (a) you are either the sole and exclusive owner of all User Content or you have all rights, licences, consents and releases that are necessary to grant to us the rights
            in such User Content as contemplated by these Terms; and
          </p>
          <p>
            (b) neither the User Content nor the posting, uploading, publication, submission or transmission of the User Content or our use of the User Content on, through or by
            means of our Platform will (i) contain abusive, homophobic, defamatory, derogatory, libellous, inciting, hateful, discriminatory or racist language, (ii) be false,
            fraudulent, or misleading, (iii) be obscene, pornographic, profane, offensive, or otherwise inappropriate or objectionable, (iv) harass, bully, threaten, or intimidate
            any person, (v) infringe, misappropriate or violate a third party’s intellectual property rights, or rights of publicity or privacy, (vi) contain or transmit any virus,
            spyware, Trojan Horse, or other malware or (vii) encourage, promote, or result in, the violation of any applicable law or regulation.
          </p>
          <p>
            We do not endorse or approve and are not responsible for any User Content. We may, at any time at our sole discretion, remove any User Content. Any failure by us to
            remove any User Content does not constitute any approval of the User Content or representation that the User Content is true, fair, accurate, complies with any
            applicable law or regulation or is otherwise inappropriate.
          </p>
          <p>
            <span className="bold">Privacy Policy</span>: We process and store your information (including User Content) in accordance with our Privacy Policy. By using the
            Platform, you consent to such processing and you warrant that all information you provide is accurate.
          </p>
          <p>
            <span className="bold">Third party sites</span> : Our Platform may contain links to websites operated by third parties. We do not control, endorse or approve and are
            not responsible for the content on those websites. You should make your own investigations with respect to the suitability of those websites.
          </p>
          <p>
            <span className="bold">Discontinuance</span> : We may, at any time and without notice to you, change, update, and/or discontinue our Platform, in whole or in part,
            including without limitation any features, fiunctionality or Content therein. We may also exclude any person from using our Platform, at any time at our sole
            discretion. We are not responsible for any Liability you may suffer arising from or in connection with any such discontinuance or exclusion.
          </p>
          <h2>Consumer Guarantees</h2>
          <p>
            (a) Certain consumer protection laws and regulations may confer you with rights, warranties, guarantees and remedies relating to our provision of our services which
            cannot be excluded, restricted or modified (Statutory Rights).
          </p>
          <p>(b) Nothing in these Terms voids your Statutory Rights as a consumer.</p>
          <h2>Warranties, Disclaimers and Indemnity</h2>
          <p>
            To the extent permitted by law, we make no representations or warranties about our Platform, the Content, Charities, Businesses, Volunteers, Profiles and Charity Pages
            including (without limitation) that:
          </p>
          <p>(a) they are complete, accurate, reliable, up-to-date and suitable for any particular purpose;</p>
          <p>(b) access will be uninterrupted, error-free and free from viruses;</p>
          <p>(c) our Platform will be secure;</p>
          <p>(d) Volunteers will find desirable Volunteering Opportunities;</p>
          <p>(e) Charities will find Volunteers to perform volunteering services; or</p>
          <p>(f) any volunteering services performed by a Volunteer are of a particular standard.</p>
          <p>You, and any Authorised Users, read, use, and act on our Platform and the Content at your own risk. </p>
          <p>Limitation of liability: Despite anything to the contrary, to the maximum extent permitted by law:</p>
          <p>
            (a) our maximum aggregate Liability arising from or in connection with these Terms (including the services we provide and/or the subject matter of these Terms) will be
            limited to, and must not exceed $100; and
          </p>
          <p>
            (b) we will not be liable to you, or any of your Authorised Users, for any loss of profit (including anticipated profit), loss of benefit (including anticipated
            benefit), loss of revenue, loss of business, loss of goodwill, loss of opportunity, loss of savings (including anticipated savings), loss of reputation, loss of use
            and/or loss or corruption of data, whether under statute, contract, equity, tort (including negligence), indemnity or otherwise.
          </p>
          <p>
            (c) Despite anything to the contrary, to the maximum extent permitted by law, we will have no Liability, and you release and discharge us from all Liability, arising
            from or in connection with any:
          </p>
          <p className="m0">(1) event or circumstance beyond our reasonable control;</p>
          <p className="m0">(2) acts or omissions of you, your Authorised Users or your employees, agents, or contractors (Personnel);</p>
          <p className="m0">
            (3) any defect, error, omission, lack of functionality or suitability (or the absence of, or reduction in, any anticipated result, outcome or benefit), inaccessibility
            of, interruption or outage with respect to the Platform;
          </p>
          <p className="m0">(4) use of the Platform and/or Content;</p>
          <p className="m0">(5) interaction you, or your Authorised Users or Personnel, have with other users whether in person or online;</p>
          <p className="m0">(6) Content which is incorrect, incomplete or out-of-date; or</p>
          <p className="m0">(7) breach of these Terms or any law.</p>
          <p>
            (d) You agree that, to the maximum extent permitted by law, these Terms exclude all terms, conditions and warranties implied by statute, in fact or on any other basis,
            except to the extent such terms, conditions and warranties are fully expressed in these Terms.
          </p>
          <p>
            (e) Each User is responsible for their own acts, omissions, statements and/or negligence. For example, without limitation, if a person is injured (including death) or
            property is damaged while a Volunteer is volunteering with a Charity, in person or at an event, we are not responsible, and the person(s) or entity(ies) other than us
            most responsible for causing such harm shall be liable.
          </p>
          <p>(f) This clause will survive the termination or expiry of these Terms.</p>
          <p>
            Indemnity : To the maximum extent permitted by law, you must indemnify us, defend us, and hold us harmless, against any allegation, claim, demand, lawsuit, action, or
            other Liability suffered by us arising from or in connection with your use, or any of your Authorised User’s or Personnel’s access to or use, of our Platform or any
            breach of these Terms or any applicable laws by you. This indemnity is a continuing obligation, independent from the other obligations under these Terms, and continues
            after these Terms end. It is not necessary for us to suffer or incur any Liability before enforcing a right of indemnity under these Terms.
          </p>
          <h2>Termination and Disputes</h2>
          <p>
            (a) You may cancel your Account and terminate these Terms at any time by sending us an email to the address at the end of these Terms. You, or an Authorised User, can
            also deactivate your Account at any time via the Platform.
          </p>
          <p>(b) We may terminate these Terms for convenience at any time by giving you 30 days’ notice via email to the email address in your Account.</p>
          <p>
            (c) At our sole discretion, we may suspend your Account or terminate these Terms immediately without notice if (i) you are, or an Authorised User is, in breach of these
            Terms, any applicable laws, regulations or third party rights, (ii) as a Volunteer you have received poor Reviews or we have received complaints about you or (iii) as a
            Charity we discover that you are not a registered 501(c)(3) charity with the IRS, or for Charities operating or registered overseas, that the Charity is not registered
            or does not have a current registration with the relevant regulator in that Charity’s country of establishment or we have received complaints about you from Volunteers,
            or (iv) for any other reason if, in our discretion, we believe we may be subject to harm if we do not do so.
          </p>
          <p>
            (d) Disputes: In the event of any dispute arising from, or in connection with, these Terms (Dispute), you must give written notice to us setting out the details of the
            Dispute and proposing a resolution within 30 days of the dispute, or lose your rights to do so. We may give you written notice of a Dispute at any time, in which case,
            you must meet with us within 7 days after receiving the notice, at least once to attempt to resolve the Dispute or agree on the method of resolving the Dispute by other
            means, in good faith. All aspects of every such conference, except the fact of the occurrence of the conference, will be privileged. If the parties do not resolve the
            Dispute or (if the Dispute is not resolved) agree on an alternate method to resolve the Dispute, within 21 days after receipt of the notice, the Dispute may be referred
            by either party (by notice in writing to the other party) to litigation.
          </p>
          <h2>Severance</h2>
          <p>
            If a provision of these Terms is held to be void, invalid, illegal or unenforceable, that provision must be read down as narrowly as necessary to allow it to be valid
            or enforceable. If it is not possible to read down a provision (in whole or in part), that provision (or that part of that provision) is severed from these Terms
            without affecting the validity or enforceability of the remainder of that provision or the other provisions in these Terms.
          </p>
          <h2>Jurisdiction</h2>
          <p>
            Your use of our Platform and these Terms are governed by the laws of the State of New York. You irrevocably and unconditionally submit to the exclusive jurisdiction of
            the courts operating in the Borough of Manhattan, New York, New York and any courts entitled to hear appeals from those courts and waive any right to object to
            proceedings being brought in those courts.
          </p>
          <p>
            Our Platform may be accessed throughout the United States of America and overseas. We make no representation that our Platform complies with the laws (including
            intellectual property laws) of any country outside the United States of America. If you access our Platform from outside the United States of America, you do so at your
            own risk and are responsible for complying with the laws of the jurisdiction where you access our Platform.
          </p>
          <h2>Electronic Transactions</h2>
          <p>
            You acknowledge and agree that by clicking on any button labelled “Submit”, “Order”, “I Accept”, “I Agree” or such similar links as may be used by us on the Platform
            (or otherwise) to accept these Terms, you are agreeing to and intend to be legally bound by that electronic signature and are entering into a legally binding agreement.
          </p>
          <h2>Changes and Updates</h2>
          <p>
            We reserve the right to change or update these Terms at any time. Any such changes or updates will be posted here and will be effective when posted. By accessing or
            using the Platform, you agree to its then current terms. If you object to any such changes, your sole recourse will be to cease using the Platform. Continued use of the
            Services following such notice of any such changes will indicate your acknowledgement of such changes and agreement to be bound by such changes.
          </p>
          <p className="bold">For any questions and notices, please contact us at:</p>
          <p>Worthy Network Inc. trading as Worthy</p>
          <p>
            <span className="bold">Email:</span> <a href="mailto: mail@worthycause.com">mail@worthycause.com</a>
          </p>
          <p>
            <span className="bold">Last updated: </span> 7 April 2023
          </p>
        </div>
        <div className="sidebar">
          <a href="#terms">Terms of Service</a>
          <a href="#acceptance">Acceptance</a>
          <a href="#contact">Contact</a>
        </div>
      </div>
    </div>
  );
}

export default TermsAndConditions;
