import React, { useEffect, useRef } from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { Button } from '../common';
import { localStorage } from '../../lib/storage';
import { location } from '../../lib/location';
import fetchUserById from '../../queries/user/fetchUserById';

// Styles
import './styles/homepageStyles.css';

// Assets
import IllustrationThree from './assets/illustration-3.png';
import GabrielProfile from './assets/gabriel.png';
import GabrielIcon from '../onboarding/assets/gabriel.png';
import JonathanIcon from '../onboarding/assets/jonathan.png';
import RebeccaIcon from '../onboarding/assets/rebecca.png';
import ArrowLeft from './assets/arrow-left.svg';
import ArrowRight from './assets/arrow-right.svg';

export default function Homepage(props) {
  const reviewsRef = useRef();

  const userByIdData = useQuery(fetchUserById, {
    variables: {
      id: localStorage.get('userID'),
    },
  });

  // Effect on mounting
  useEffect(() => {
    const getRoughLocation = async () => {
      const roughLocationIpData = await location.getRoughLocationByIP();
      // Save country on localStorage
      localStorage.save('country', _.get(roughLocationIpData, 'data.country_code'));
      // Save possible timezone for contingency
      localStorage.save('timezone', _.get(roughLocationIpData, 'data.time_zone.name'));
      // Save city
      localStorage.save('city', _.get(roughLocationIpData, 'data.city'));
      // Save state
      localStorage.save('state', _.get(roughLocationIpData, 'data.region'));
    };

    getRoughLocation();
  }, []);

  // Effect once user has been loaded
  useEffect(() => {
    const isOnboarded = _.get(userByIdData, 'data.userByID.IsOnboarded');
    if (isOnboarded === false) {
      props.history.push({
        pathname: '/onboarding/welcome',
      });
    }
  }, [_.get(userByIdData, 'data.userByID._id')]);

  const slide = direction => {
    let left;
    const { scrollLeft } = reviewsRef.current;
    const { clientWidth } = reviewsRef.current.childNodes[0];

    switch (direction) {
      case 'prev':
        left = scrollLeft - clientWidth;
        break;
      case 'next':
      default:
        left = scrollLeft + clientWidth;
        break;
    }

    reviewsRef.current.scroll({
      left,
      behavior: 'smooth',
    });
  };

  const userID = localStorage.get('userID');

  return (
    <div className="homepageContainerNew">
      <div className="introSection">
        <div className="wrapperContent">
          <div className="content">
            <>
              <h1 className="mbottom40">Join the network for charities and volunteers</h1>
              <div className="buttonsContainer">
                {userID ? null : (
                  <Link to="/sign-up">
                    <Button label="Sign Up" />
                  </Link>
                )}
                <Link to="/charities">
                  <Button className="btn_transparent dark" label="Browse Charities" />
                </Link>
              </div>
            </>
          </div>
          <div className="background" />
        </div>
      </div>
      <div className="forVolunteerSection">
        <div className="wrapperContent">
          <div className="background" />
          <div className="content">
            <div>
              <p className="small">For volunteers</p>
              <div className="line" />
              <h1 className="mbottom30">Charities need your skills & expertise. Offer them a helping hand.</h1>
              <p className="mbottom32">Charities jugggle hundreds of tasks on a daily basis. Join Worthy to offer your skills to charities who need them most.</p>
              <Link to="/for-volunteers">
                <Button className="btn_transparent" label="Learn More" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="networkSection">
        <div className="content">
          <div className="head">
            <p>The network for charities</p>
            <h1>Why is Worthy different?</h1>
          </div>
          <div className="section">
            <div className="wrapperContent">
              <img alt="Illustration" src={IllustrationThree} />
              <div className="contentSection">
                <h1>Your charity network is your ticket to accessing skilled volunteers.</h1>
                <p className="small">Give your volunteers recognition for their hours, and offer them feedback to strengthen their profile.</p>
                <Link to="/charities">
                  <Button className="btn_transparent dark" label="Browse Charity Networks" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="reviewsSection">
        <div className="wrapperContent">
          <div className="profile">
            <div className="background">
              <img src={GabrielProfile} alt="Gabriel Profile" />
            </div>
          </div>
          <div className="section">
            <h1>See how others are using{'\n'}Worthy</h1>
            <div className="reviews" ref={reviewsRef}>
              <div className="review" id="review-1">
                <p>“I wasn’t satisfied with just my 9-to-5. I wanted more meaning in my work. I knew I had the skills to help others, I just wasn’t sure how.”</p>
                <div className="by">
                  <img src={GabrielIcon} alt="Gabriel" />
                  <div className="byName">
                    <p>Sammy</p>
                    <p>Digital Content Creator</p>
                  </div>
                </div>
              </div>
              <div className="review" id="review-2">
                <p>“It really helped us find some great volunteers. From social media people to finance and accounting. Worthy is a great platform for networking!”</p>
                <div className="by">
                  <img src={RebeccaIcon} alt="Person" />
                  <div className="byName">
                    <p>Michael</p>
                    <p>Charity manager</p>
                  </div>
                </div>
              </div>
              <div className="review" id="review-3">
                <p>“I wasnt satisfied with just my 9-to-5. I wanted more meaning in my work. I knew I had the skills to help others, I just wasn’t sure how.”</p>
                <div className="by">
                  <img src={JonathanIcon} alt="Person" />
                  <div className="byName">
                    <p>Alessandra</p>
                    <p>Volunteer HR</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="buttonsContainer">
              <button type="button" onClick={() => slide('prev')}>
                <img src={ArrowLeft} alt="Arrow Left" />
              </button>
              <button type="button" onClick={() => slide('next')}>
                <img src={ArrowRight} alt="Arrow Right" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="forCharitiesSection">
        <div className="wrapperContent">
          <div className="content">
            <div>
              <p className="small">For charities</p>
              <div className="line" />
              <h1 className="mbottom30">A community-based platform to help build and manage your volunteers.</h1>
              <p className="mbottom32">Offload work to your skilled & talented volunteers.</p>
              <Link to="/for-charities">
                <Button label="Learn More" />
              </Link>
            </div>
          </div>
          <div className="background" />
        </div>
      </div>
    </div>
  );
}
