import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Input, Button, List, Notification } from '../common';
import { userUtils } from '../../lib/user';

import QuestionIcon from './assets/question.svg';
import QuestionIconActive from './assets/question-active.svg';
import PersonIcon from './assets/person.svg';
import PersonIconActive from './assets/person-active.svg';
import VerifiedIcon from './assets/verified.svg';
import VerifiedIconActive from './assets/verified-active.svg';

// Mutations
import createMessageMutation from '../../mutations/messages/createMessage';

// Styles
import './styles/faqsStyles.css';

function FAQPage(props) {
  const [sectionSelected, setSectionSelected] = useState(1);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [notificationContent, setNotificationContent] = useState('');
  const [emailValid, setEmailValid] = useState('');

  // Mutations
  const [createMessage] = useMutation(createMessageMutation);

  const faqs = [
    {
      title: 'What does it cost to use?',
      content: (
        <>
          Worthy<span className="symbol">TM</span> is free for volunteers. <br /> <br />
          At this stage, the platform is also free for Charities. We plan to charge Charities a fee that scales based on the number of users and or functions.
        </>
      ),
      section: 1,
    },
    {
      title: 'What do you do with my data?',
      content: (
        <span>
          For information on how Worthy<span className="symbol">TM</span> handles your data please see our <a href="/privacy">Privacy Policy</a>.
        </span>
      ),
      section: 1,
    },
    {
      title: (
        <>
          Is Worthy<span className="symbol">TM</span> a not-for-profit?
        </>
      ),
      content: (
        <>
          Worthy<span className="symbol">TM</span> is a registered C-Corp in the State of Delaware. The company is operated for profit.
        </>
      ),
      section: 1,
    },
    {
      title: 'How do I know I can trust the charities on the platform?',
      content:
        'We endeavour only to permit charities that are registered with the IRS as 501(c)(3) charities. If you have concerns regarding one of the charities on the platform, please contact us by filling the form below. For more information on the charities registered with the IRS, please see the IRS website.',
      section: 1,
    },
    {
      title: 'How do I report a bug or issue with the platform? ',
      content: (
        <span>
          Please contact us using the form below or email us at <a href="mailto:mail@worthycause.com">mail@worthycause.com</a>.
        </span>
      ),
      section: 1,
    },
    {
      title: 'How do I register an account?',
      content: (
        <span>
          Go to the <a href="/sign-up">Sign Up</a> page and follow the prompts. As you consider registering an account, please read our Terms of Service, Terms of Use and Privacy
          Policy. You’ll need to verify your account and will receive an email. If you don’t receive an email, please contact us below.
        </span>
      ),
      section: 2,
    },
    {
      title: 'How do I apply for tasks?',
      content: (
        <span>
          You can search for tasks on the <a href="/task/search">Browse Tasks</a> page or by reviewing opportunities displayed on the pages of charities. Once you have found a task
          that appeals, you’ll need to apply for that opportunity following the prompts.
        </span>
      ),
      section: 2,
    },
    {
      title: 'What happens if I have a bad experience during a task or want to report a charity?',
      content: (
        <span>
          Please contact us by filling the form below or by email at <a href="mailto:mail@worthycause.com">mail@worthycause.com</a> as soon as possible with the details of your
          experience.
        </span>
      ),
      section: 2,
    },
    {
      title: 'How do I delete my account?',
      content: <span>Please use the Delete Account feature within Settings.</span>,
      section: 2,
    },
    {
      title: 'How do I register a charity on the platform?',
      content: (
        <span>
          To register an account on the platform, you will first require a user account. You can register a user account using the <a href="/sign-up">Sign Up</a> page.
          <br />
          <br />
          Once you have a user account and are logged in, go to the <a href="/charity/register/details">Register Charity</a> page and follow the instructions.
        </span>
      ),
      section: 3,
    },
    {
      title: 'What details are required to register a charity?',
      content: (
        <span>
          We require all charities on the platform to be registered with the IRS as 501(c)(3) charities. For us to verify this, we will require your charity EIN and contact details
          for your organisation. By registering a charity on the platform, you acknowledge you are entitled and have permission to act on behalf of the charity.
        </span>
      ),
      section: 3,
    },
    {
      title: (
        <>
          How long does it take for Worthy<span className="symbol">TM</span> to verify my charity?
        </>
      ),
      content: (
        <span>
          Once you have applied to register your charity, we will endeavour to verify your charity within 1-2 business days. You will receive an email confirmation once registered.
        </span>
      ),
      section: 3,
    },
    {
      title: 'How do I edit the charity details?',
      content: (
        <span>
          Navigate to the relevant charity profile page while logged in as an administrator for the charity. There is an option to Edit Charity Details at the top of the page. You
          can also edit charity details within the Settings section. If you are having issues editing the details for your charity, please contact us by submitting the form below.
        </span>
      ),
      section: 3,
    },
    {
      title: 'How do I edit the users representing my charity?',
      content: (
        <span>
          Navigate to Settings and then Access and Privileges while logged in as a Charity Administrator. Only Charity Administrators can add, edit or remove users and user
          privileges.
        </span>
      ),
      section: 3,
    },
    {
      title: 'How do I delete a charity?',
      content: (
        <span>
          To remove a charity from the platform please contact us by email at <a href="mailto:mail@worthycause.com">mail@worthycause.com</a> or by filling the form below.
        </span>
      ),
      section: 3,
    },
  ];

  const onClickSend = async () => {
    await createMessage({
      variables: {
        message: {
          type: 4, // FAQS
          tag: 'CONTACT_FAQS',
          body: message,
          email: {
            name,
            email,
          },
        },
      },
    });
    setName('');
    setEmail('');
    setMessage('');
    setNotificationContent(<span>Thanks for your message. Someone will contact you by email as soon as possible</span>);
  };

  const renderIcon = (section, isSelected) => {
    switch (section) {
      case 1:
        return <img alt="Question" src={isSelected ? QuestionIconActive : QuestionIcon} />;
      case 2:
        return <img alt="Person" src={isSelected ? PersonIconActive : PersonIcon} />;
      case 3:
        return <img alt="Verified" src={isSelected ? VerifiedIconActive : VerifiedIcon} />;
      default:
        return <img alt="Question" src={isSelected ? QuestionIconActive : QuestionIcon} />;
    }
  };

  const renderTypeContainer = (section, title, className = '') => {
    const isSelected = sectionSelected === section;
    return (
      <div className={`helpType ${isSelected ? 'selected' : ''} ${className}`} onClick={() => setSectionSelected(section)}>
        {renderIcon(section, isSelected)}
        <h2>{title}</h2>
      </div>
    );
  };

  const renderFaqsContentContainer = () => {
    const faqsContent = faqs.filter(item => item.section === sectionSelected);

    return (
      <List
        className="defaultListStyles faqsList"
        values={faqsContent.map((item, index) => {
          return {
            name: <p className="listTitle m0">{item.title}</p>,
            component: (
              <p className="m0 answer" key={index}>
                {item.content}
              </p>
            ),
            hoverColor: '#F1F3F6',
          };
        })}
      />
    );
  };

  return (
    <div className="faqsContainer">
      <div className="header">
        <h1>Help & FAQs</h1>
        <p>Find support and answers to frequently asked questions.</p>
      </div>
      <div className="internalContainerStyle">
        <div className="helpTypeContainer">
          {renderTypeContainer(1, 'General')}
          {renderTypeContainer(2, 'Volunteers', 'mleft8 mright8')}
          {renderTypeContainer(3, 'Charities')}
        </div>
        <div className="faqsContentContainer">{renderFaqsContentContainer()}</div>
      </div>
      <div className="getInTouchContainer">
        <div className="content">
          <div className="information">
            <h1>Get in touch</h1>
            <p>mail@worthycause.com</p>
            <div className="social-media">
              <a href="https://www.twitter.com" target="_blank" rel="noreferrer">
                <img src="icons/socials/twitter.svg" alt="Twitter" />
              </a>
              <a href="https://www.instagram.com" target="_blank" rel="noreferrer">
                <img src="icons/socials/instagram.svg" alt="Instagram" />
              </a>
              <a href="https://www.tiktok.com" target="_blank" rel="noreferrer">
                <img src="icons/socials/tiktok.svg" alt="TikTok" />
              </a>
            </div>
          </div>
          <div className="fields">
            <Input name="name" placeholder="Name" type="text" value={name} onChange={value => setName(value)} />
            <Input
              name="email"
              placeholder="Email"
              type="email"
              errorText={emailValid}
              value={email}
              onChange={value => setEmail(value)}
              onBlur={() => {
                if (userUtils.isValidEmail(email) === false) {
                  setEmailValid('Please enter a valid email.');
                } else {
                  setEmailValid('');
                }
              }}
            />
            <Input name="message" placeholder="Message" type="text" multiLine value={message} onChange={value => setMessage(value)} />
            <Button label="Send" className="btnSend" onClick={onClickSend} disabled={!(name && email && message && !emailValid)} />
          </div>
        </div>
      </div>
      <Notification content={notificationContent} />
    </div>
  );
}

export default FAQPage;
