import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { getTaskStatus, getSumittedHours, getApprovedHours, getTaskDueDateLabel, hasTaskValidSubmission, getReviewedSubmission } from 'lib/task';
import { getAppliedSubmission, getAssignedSubmission, getCompletedSubmission } from 'lib/task_submission';
import { userUtils } from 'lib/user';
import UpcomingIcon from 'assets/svg/upcoming.svg';
import InProgressIcon from 'assets/svg/progress.svg';
import AwaitingReviewIcon from 'assets/svg/awaiting-review.svg';
import CompletedIcon from 'assets/svg/completed.svg';
import NotAssignedIcon from 'assets/svg/not-assigned.svg';
import { Table } from '../../common';

// External components
import { ModalReviewTimesheets } from './ModalReviewTimesheets/view';
import { ModalLeaveFeedback } from './ModalLeaveFeedback/modalLeaveFeedback';
import { ModalDeleteTask } from './ModalDeleteTask/view';
import { ModalWithdrawSubmission } from './ModalWithdrawSubmission/view';
import { ModalAssignVolunteer } from './ModalAssignVolunteer/view';
import { ModalInviteVolunteer } from './ModalInviteVolunteer/view';
import { ModalMarkAsComplete } from './ModalMarkAsComplete/view';
import { ModalContinueTask } from './ModalContinueTask/view';

// Queries
import fetchTasksByUserId from '../../../queries/task/fetchTasksByUserId';
import fetchTasksByCharityId from '../../../queries/task/fetchTasksByCharityId';

import './styles.scss';

const tableHeaders = [
  { label: 'Task name', value: 'name' },
  { label: 'Status', value: 'status' },
  { label: 'Timesheets', value: 'timesheets' },
  { label: 'Volunteers', value: 'volunteers' },
  { label: 'Actions', value: 'actions' },
];

const tableVolunteerHeaders = [
  { label: 'Task name', value: 'name' },
  { label: 'Status', value: 'status' },
  { label: 'Charity & Manager', value: 'charity manager' },
  { label: 'Timesheets', value: 'timesheets' },
  { label: 'Due', value: 'due' },
  { label: 'Actions', value: 'actions' },
];

const TasksList = ({ userData, history }) => {
  const [openAssignVolunteerModal, setOpenAssignVolunteerModal] = useState(null);
  const [openInviteVolunteerModal, setOpenInviteVolunteerModal] = useState(null);
  const [openDeleteTaskModal, setOpenDeleteTaskModal] = useState(null);
  const [openReviewTimesheetModal, setOpenReviewTimesheetModal] = useState(null);
  const [openLeaveFeedbackModal, setOpenLeaveFeedbackModal] = useState(null);
  const [openMarkAsCompleteModal, setOpenMarkAsCompleteModal] = useState(false);
  const [openContinueTaskModal, setOpenContinueTaskModal] = useState(false);
  const [openWithdrawSubmissionModal, setOpenWithdrawSubmissionModal] = useState(false);
  const [charityId, setCharityId] = useState('');
  const [taskId, setTaskId] = useState('');
  const [taskCreator, setTaskCreator] = useState('');
  const [taskFrequency, setTaskFrequency] = useState(0);
  const [taskName, setTaskName] = useState('');
  const [taskSubmissions, setTaskSubmissions] = useState([]);
  const [taskSubmissionId, setTaskSubmissionId] = useState('');
  const [volunteerIds, setVolunteerIds] = useState([]);

  const isCharityManager = userUtils.isCharityManager(userData);

  const tasksByUser = useQuery(fetchTasksByUserId, {
    variables: {
      _user_id: _.get(userData, '_id'),
    },
  });
  const tasksByUserData = _.get(tasksByUser, 'data.tasksByUserID') || [];

  const tasksByCharityResults = useQuery(fetchTasksByCharityId, {
    variables: {
      _charity_id: _.get(userData, 'CharitiesAdmin[0]._id'),
    },
    skip: _.size(userData?.CharitiesAdmin) === 0,
  });
  const tasksByCharityData = _.get(tasksByCharityResults, 'data.tasksByCharityID') || [];

  // Combine both tasksByUser and tasksByCharity data points
  const tasksToDisplay = _.concat(tasksByUserData, tasksByCharityData);
  const tasksVolunteerAppliedToDisplay = tasksToDisplay.filter(
    task => task.TaskSubmissions.some(submission => submission._user_id === _.get(userData, '_id') && submission.status === 1) === true,
  );
  const tasksVolunteerAssignedToDisplay = tasksToDisplay.filter(
    task => task.TaskSubmissions.some(submission => submission._user_id === _.get(userData, '_id') && submission.status > 2) === true,
  );

  const getTimesheetProperties = task => {
    if (getTaskStatus(task) === 'In progress') {
      return {
        isGroupLink: true,
        title1: `Submitted: ${getSumittedHours(task)}`,
        title2: `Approved: ${getApprovedHours(task)}`,
        linkCallback: () => {
          setOpenReviewTimesheetModal(task._id);
          setCharityId(task._charity_id);
          setTaskCreator(_.get(task, '_user_id'));
        },
      };
    }
    if (_.get(task, 'status') === 1 && !isCharityManager) {
      return {
        isGroupLink: true,
        title1: `Submitted: ${getSumittedHours(task)}`,
        title2: `Approved: ${getApprovedHours(task)}`,
        linkCallback: () => {
          setOpenReviewTimesheetModal(task._id);
          setCharityId(task._charity_id);
          setTaskCreator(_.get(task, '_user_id'));
        },
      };
    }
    if (_.get(task, 'status') === 1 && !hasTaskValidSubmission(task)) {
      return {
        linkTitle: 'Invite Volunteer',
        linkCallback: () => setOpenInviteVolunteerModal(task._id),
      };
    }
    if (_.get(task, 'status') === 1 && hasTaskValidSubmission(task)) {
      return {
        linkTitle: 'Assign Volunteer',
        linkCallback: () => setOpenAssignVolunteerModal(task._id),
      };
    }
    if (getTaskStatus(task) === 'Awaiting review') {
      return {
        isGroupLink: true,
        title1: `Submitted: ${getSumittedHours(task)}`,
        title2: `Approved: ${getApprovedHours(task)}`,
        linkCallback: () => {
          setOpenReviewTimesheetModal(task._id);
          setCharityId(task._charity_id);
          setTaskCreator(_.get(task, '_user_id'));
        },
      };
    }
    if (getTaskStatus(task) === 'Completed') {
      return {
        isGroupLink: true,
        title2: `Approved: ${getApprovedHours(task)}`,
        linkCallback: () => {
          setOpenReviewTimesheetModal(task._id);
          setCharityId(task._charity_id);
          setTaskCreator(_.get(task, '_user_id'));
        },
      };
    }
  };

  const getVolunteerProperties = ({ task, appliedSubmission, assignedSubmission, reviewedSubmission }) => {
    if (getTaskStatus(task) === 'In progress') {
      return {
        isMultiTitle: true,
        title1: `${_.size(appliedSubmission)} applicants`,
        title2: `${_.size(assignedSubmission)} assigned`,
        multiTitleClassName: 'multiTitle',
      };
    }
    if (getTaskStatus(task) === 'Upcoming' && !hasTaskValidSubmission(task)) {
      return {
        isMultiTitle: true,
        title1: '0 applicants',
        multiTitleClassName: 'multiTitle',
      };
    }
    if (getTaskStatus(task) === 'Upcoming' && hasTaskValidSubmission(task)) {
      return {
        isMultiTitle: true,
        title1: `${_.size(appliedSubmission)} applicants`,
        title2: `${_.size(assignedSubmission)} assigned`,
        multiTitleClassName: 'multiTitle',
      };
    }
    if (getTaskStatus(task) === 'Awaiting review') {
      return {
        isMultiTitle: true,
        title1: `${_.size(reviewedSubmission)} / ${_.size(_.get(task, 'TaskSubmissions'))} reviewed`,
        multiTitleClassName: 'multiTitle',
      };
    }
    if (getTaskStatus(task) === 'Completed') {
      return {
        isMultiTitle: true,
        title1: `${_.size(_.get(task, 'TaskSubmissions'))} reviewed`,
        multiTitleClassName: 'multiTitle',
      };
    }
  };

  const getActionProperties = ({ task }) => {
    if (getTaskStatus(task) === 'In progress') {
      return {
        isBtnGroup: true,
        isVolunteer: false,
        btnActions: [
          {
            actionTitle: 'Review Timesheets',
            actionCallback: () => {
              setOpenReviewTimesheetModal(task._id);
              setTaskCreator(_.get(task, '_user_id'));
            },
          },
          {
            actionTitle: 'Mark all as Complete',
            actionCallback: () => {
              setOpenMarkAsCompleteModal(true);
              setVolunteerIds(_.map(task.TaskSubmissions, item => item._user_id));
              setTaskId(_.get(task, '_id'));
              setTaskName(_.get(task, 'name'));
              setTaskCreator(_.get(task, '_user_id'));
              setTaskFrequency(_.get(task, 'frequency'));
              setCharityId(task._charity_id);
              setTaskSubmissionId(_.get(task, 'TaskSubmissions[0]._id'));
            },
          },
          {
            actionTitle: 'Invite Volunteers',
            actionCallback: () => setOpenInviteVolunteerModal(task._id),
          },
          {
            actionTitle: 'Assign Volunteers',
            actionCallback: () => setOpenAssignVolunteerModal(task._id),
          },
          {
            actionTitle: 'Delete Task',
            actionCallback: () => {
              setOpenDeleteTaskModal(true);
              setVolunteerIds(_.map(task?.TaskSubmissions, item => item._user_id));
              setTaskFrequency(_.get(task, 'frequency'));
              setTaskId(_.get(task, '_id'));
              setCharityId(task._charity_id);
            },
          },
        ],
      };
    }
    if (getTaskStatus(task) === 'Upcoming' && !hasTaskValidSubmission(task)) {
      return {
        isBtnGroup: true,
        isVolunteer: false,
        btnActions: [
          {
            actionTitle: 'Invite Volunteers',
            actionCallback: () => setOpenInviteVolunteerModal(task._id),
          },
          {
            actionTitle: 'Delete Task',
            actionCallback: () => {
              setOpenDeleteTaskModal(true);
              setVolunteerIds(_.map(task?.TaskSubmissions, item => item._user_id));
              setTaskFrequency(_.get(task, 'frequency'));
              setTaskId(_.get(task, '_id'));
              setCharityId(task._charity_id);
            },
          },
        ],
      };
    }
    if (getTaskStatus(task) === 'Upcoming' && hasTaskValidSubmission(task)) {
      return {
        isBtnGroup: true,
        isVolunteer: false,
        btnActions: [
          {
            actionTitle: 'Assign Volunteers',
            actionCallback: () => setOpenAssignVolunteerModal(task._id),
          },
          {
            actionTitle: 'Invite Volunteers',
            actionCallback: () => setOpenInviteVolunteerModal(task._id),
          },
          {
            actionTitle: 'Delete Task',
            actionCallback: () => {
              setOpenDeleteTaskModal(true);
              setVolunteerIds(_.map(task?.TaskSubmissions, item => item._user_id));
              setTaskFrequency(_.get(task, 'frequency'));
              setTaskId(_.get(task, '_id'));
              setCharityId(task._charity_id);
            },
          },
        ],
      };
    }
    if (getTaskStatus(task) === 'Awaiting review') {
      return {
        isBtnGroup: true,
        isVolunteer: false,
        btnActions: [
          {
            actionTitle: 'Review Timesheets',
            actionCallback: () => {
              setOpenReviewTimesheetModal(task._id);
              setTaskCreator(_.get(task, '_user_id'));
            },
          },
          {
            actionTitle: 'Recommence Task',
            actionCallback: () => {
              setOpenContinueTaskModal(true);
              setVolunteerIds(_.map(task.TaskSubmissions, item => item._user_id));
              setTaskCreator(_.get(task, '_user_id'));
              setTaskFrequency(_.get(task, 'frequency'));
              setTaskId(_.get(task, '_id'));
              setCharityId(task._charity_id);
              setTaskSubmissionId(_.get(task, 'TaskSubmissions[0]._id'));
            },
          },
          {
            actionTitle: 'Leave Feedback',
            actionCallback: () => {
              setOpenLeaveFeedbackModal(true);
              setTaskId(_.get(task, '_id'));
              setTaskName(_.get(task, 'name'));
              setCharityId(_.get(task, '_charity_id'));
              setTaskSubmissions(_.get(task, 'TaskSubmissions'));
            },
          },
        ],
      };
    }
    if (getTaskStatus(task) === 'Completed') {
      return {
        isBtnGroup: true,
        isVolunteer: false,
        btnActions: [
          {
            actionTitle: 'Edit Feedback',
            actionCallback: () => {
              setOpenLeaveFeedbackModal(true);
              setTaskId(_.get(task, '_id'));
              setTaskName(_.get(task, 'name'));
              setCharityId(_.get(task, '_charity_id'));
              setTaskSubmissions(_.get(task, 'TaskSubmissions'));
            },
          },
          {
            actionTitle: 'Review Timesheet',
            actionCallback: () => {
              setOpenReviewTimesheetModal(task._id);
              setTaskCreator(_.get(task, '_user_id'));
            },
          },
        ],
      };
    }
    return {
      title: '',
    };
  };

  const getVolunteerAssignedActionProperties = ({ task }) => {
    if (getTaskStatus(task) === 'In progress') {
      return {
        isBtnGroup: true,
        isVolunteer: true,
        charityManagerInfo: _.get(task, 'Charity.charity_users[0].user'),
        btnActions: [
          {
            actionTitle: 'Edit Timesheets',
            actionCallback: () => {
              setOpenReviewTimesheetModal(task._id);
              setTaskCreator(_.get(task, '_user_id'));
            },
          },
          {
            actionTitle: 'Mark as Complete',
            actionCallback: () => {
              setOpenMarkAsCompleteModal(true);
              setVolunteerIds(_.map(task?.TaskSubmissions, item => item._user_id));
              setTaskCreator(_.get(task, '_user_id'));
              setTaskFrequency(_.get(task, 'frequency'));
              setTaskId(_.get(task, '_id'));
              setTaskSubmissionId(_.get(task, 'TaskSubmissions').filter(item => item._user_id === _.get(userData, '_id'))[0]._id);
            },
          },
          {
            actionTitle: 'Withdraw',
            actionCallback: () => {
              setOpenWithdrawSubmissionModal(true);
              setTaskCreator(_.get(task, '_user_id'));
              setTaskId(_.get(task, '_id'));
              setTaskSubmissionId(_.get(task, 'TaskSubmissions').filter(item => item._user_id === _.get(userData, '_id'))[0]._id);
            },
          },
        ],
      };
    }
    if (getTaskStatus(task) === 'Upcoming') {
      return {
        isBtnGroup: true,
        isVolunteer: true,
        charityManagerInfo: _.get(task, 'Charity.charity_users[0].user'),
        btnActions: [
          {
            actionTitle: 'Edit Timesheets',
            actionCallback: () => {
              setOpenReviewTimesheetModal(task._id);
              setTaskCreator(_.get(task, '_user_id'));
            },
          },
          {
            actionTitle: 'Withdraw',
            actionCallback: () => {
              setOpenWithdrawSubmissionModal(true);
              setTaskCreator(_.get(task, '_user_id'));
              setTaskId(_.get(task, '_id'));
              setTaskSubmissionId(_.get(task, 'TaskSubmissions').filter(item => item._user_id === _.get(userData, '_id'))[0]._id);
            },
          },
        ],
      };
    }
    if (getTaskStatus(task) === 'Awaiting review') {
      return {
        isBtnGroup: true,
        isVolunteer: true,
        charityManagerInfo: _.get(task, 'Charity.charity_users[0].user'),
        btnActions: [
          {
            actionTitle: 'Edit Timesheets',
            actionCallback: () => {
              setOpenReviewTimesheetModal(task._id);
              setTaskCreator(_.get(task, '_user_id'));
            },
          },
          {
            actionTitle: 'Recommence Task',
            actionCallback: () => {
              setOpenContinueTaskModal(true);
              setVolunteerIds(_.map(task.TaskSubmissions, item => item._user_id));
              setTaskCreator(_.get(task, '_user_id'));
              setTaskFrequency(_.get(task, 'frequency'));
              setTaskId(_.get(task, '_id'));
              setCharityId(task._charity_id);
              setTaskSubmissionId(_.get(task, 'TaskSubmissions').filter(item => item._user_id === _.get(userData, '_id'))[0]._id);
            },
          },
          {
            actionTitle: 'Withdraw',
            actionCallback: () => {
              setOpenWithdrawSubmissionModal(true);
              setTaskCreator(_.get(task, '_user_id'));
              setTaskId(_.get(task, '_id'));
              setTaskSubmissionId(_.get(task, 'TaskSubmissions').filter(item => item._user_id === _.get(userData, '_id'))[0]._id);
            },
          },
        ],
      };
    }
    if (getTaskStatus(task) === 'Completed') {
      return {
        title: '',
      };
    }
  };

  const getVolunteerAppliedActionProperties = ({ task }) => {
    if (getTaskStatus(task) === 'Upcoming') {
      return {
        isBtnGroup: true,
        isVolunteer: true,
        charityManagerInfo: _.get(task, 'Charity.charity_users[0].user'),
        btnActions: [
          {
            actionTitle: 'Withdraw',
            actionCallback: () => {
              setOpenWithdrawSubmissionModal(true);
              setTaskCreator(_.get(task, '_user_id'));
              setTaskId(_.get(task, '_id'));
              setTaskSubmissionId(_.get(task, 'TaskSubmissions').filter(item => item._user_id === _.get(userData, '_id'))[0]._id);
            },
          },
        ],
      };
    }
    return {
      title: '',
    };
  };

  const getCharityManagerProperties = task => {
    return {
      isMultiTitle: true,
      title1: _.get(task, 'Charity.name'),
      linkURL1: `${'/charity/'}${_.get(task, 'Charity._id')}`,
      title2: `${_.get(task, 'Charity.charity_users[0].user.firstname')} ${_.get(task, 'Charity.charity_users[0].user.lastname')}`,
      linkURL2: `${'/profile/'}${_.get(task, '_user_id')}`,
      multiTitleClassName: 'linkTitle',
    };
  };

  const getTaskStatusProperties = task => {
    if (getTaskStatus(task) === 'Upcoming') {
      return {
        isIconTitle: true,
        iconTitle: 'Upcoming',
        icon: UpcomingIcon,
        className: 'upcoming',
      };
    }
    if (getTaskStatus(task) === 'In progress') {
      return {
        isIconTitle: true,
        iconTitle: 'In progress',
        icon: InProgressIcon,
        className: 'in-progress',
      };
    }
    if (getTaskStatus(task) === 'Awaiting review') {
      return {
        isIconTitle: true,
        iconTitle: 'Awaiting review',
        icon: AwaitingReviewIcon,
        className: 'awaiting-review',
      };
    }
    if (getTaskStatus(task) === 'Completed') {
      return {
        isIconTitle: true,
        iconTitle: 'Completed',
        icon: CompletedIcon,
        className: 'completed',
      };
    }
  };

  const getTaskAppliedStatusProperties = task => {
    if (getTaskStatus(task) === 'Upcoming') {
      return {
        isIconTitle: true,
        iconTitle: 'Upcoming',
        icon: UpcomingIcon,
        className: 'upcoming',
      };
    }
    if (getTaskStatus(task) === 'In progress') {
      return {
        isIconTitle: true,
        iconTitle: 'Not assigned',
        icon: NotAssignedIcon,
        className: 'not-assigned',
      };
    }
  };

  const parseCMBodyValues = (tasks = []) => {
    return tasks.map(task => {
      const appliedSubmission = getAppliedSubmission(_.get(task, 'TaskSubmissions'));
      const assignedSubmission = getAssignedSubmission(_.get(task, 'TaskSubmissions'));
      const reviewedSubmission = getReviewedSubmission(task);
      const taskTimesheets = _.get(assignedSubmission, 'TaskTimesheet');
      const taskReviews = _.get(reviewedSubmission, 'TaskReview');
      const actionProperties = getActionProperties({
        task,
        assignedSubmission,
        reviewedSubmission,
        taskTimesheets,
        taskReviews,
        taskId: task._id,
      });
      const volunteerProperties = getVolunteerProperties({ task, appliedSubmission, assignedSubmission, reviewedSubmission });
      const timesheetProperties = getTimesheetProperties(task);
      const taskStatusProperties = getTaskStatusProperties(task);
      const contentForRow = [
        {
          linkTitle: _.get(task, 'name'),
          linkURL: `${'/task/'}${_.get(task, '_id')}`,
          linkTitleClassName: 'linkTitle',
        },
        taskStatusProperties,
        timesheetProperties,
        volunteerProperties,
        actionProperties,
      ];

      return {
        content: contentForRow,
      };
    });
  };

  const parseVolunteerAssignedBodyValues = (tasks = []) => {
    return tasks.map(task => {
      const appliedSubmission = getAppliedSubmission(_.get(task, 'TaskSubmissions'));
      const assignedSubmission = getAssignedSubmission(_.get(task, 'TaskSubmissions'));
      const completedSubmission = getCompletedSubmission(_.get(task, 'TaskSubmissions'));
      const taskTimesheets = _.get(assignedSubmission, 'TaskTimesheet');
      const taskReviews = _.get(completedSubmission, 'TaskReview');
      const actionProperties = getVolunteerAssignedActionProperties({
        task,
      });
      const timesheetProperties = getTimesheetProperties(task);
      const charityManagerProperties = getCharityManagerProperties(task);
      const taskStatusProperties = getTaskStatusProperties(task);
      const contentForRow = [
        {
          linkTitle: _.get(task, 'name'),
          linkURL: `${'/task/'}${_.get(task, '_id')}`,
          linkTitleClassName: _.size(tasksVolunteerAssignedToDisplay) === 0 && getTaskStatus(task) === 'In progress' ? 'linkGreyTitle' : 'linkTitle',
        },
        taskStatusProperties,
        charityManagerProperties,
        timesheetProperties,
        {
          title: getTaskDueDateLabel(task),
          titleClassName: _.size(tasksVolunteerAssignedToDisplay) === 0 && getTaskStatus(task) === 'In progress' && 'textDarkGrey',
        },
        actionProperties,
      ];

      return {
        content: contentForRow,
      };
    });
  };

  const parseVolunteerAppliedBodyValues = (tasks = []) => {
    return tasks.map(task => {
      const appliedSubmission = getAppliedSubmission(_.get(task, 'TaskSubmissions'));
      const assignedSubmission = getAssignedSubmission(_.get(task, 'TaskSubmissions'));
      const completedSubmission = getCompletedSubmission(_.get(task, 'TaskSubmissions'));
      const taskTimesheets = _.get(assignedSubmission, 'TaskTimesheet');
      const taskReviews = _.get(completedSubmission, 'TaskReview');
      const actionProperties = getVolunteerAppliedActionProperties({
        task,
      });
      const timesheetProperties = { title: '' };
      const charityManagerProperties = getCharityManagerProperties(task);
      const taskStatusProperties = getTaskAppliedStatusProperties(task);
      const contentForRow = [
        {
          linkTitle: _.get(task, 'name'),
          linkURL: `${'/task/'}${_.get(task, '_id')}`,
          linkTitleClassName: getTaskStatus(task) === 'In progress' ? 'linkGreyTitle' : 'linkTitle',
        },
        taskStatusProperties,
        charityManagerProperties,
        timesheetProperties,
        {
          title: getTaskDueDateLabel(task),
          titleClassName: getTaskStatus(task) === 'In progress' && 'textDarkGrey',
        },
        actionProperties,
      ];

      return {
        content: contentForRow,
      };
    });
  };

  return (
    <div className="tasksListContainer">
      {isCharityManager ? (
        <Table
          headerValues={tableHeaders.map(header => {
            return { title: header.label };
          })}
          bodyDisplayRowCheckbox={false}
          bodyValues={parseCMBodyValues(tasksToDisplay)}
          containerClassNameStructure={['medium', 'narrow', 'narrow', 'narrow', 'narrow', 'narrow']}
        />
      ) : (
        <>
          {_.size(tasksVolunteerAssignedToDisplay) !== 0 && (
            <>
              <div className="sectionTitle">Assigned tasks</div>
              <Table
                headerValues={tableVolunteerHeaders.map(header => {
                  return { title: header.label };
                })}
                bodyDisplayRowCheckbox={false}
                bodyValues={parseVolunteerAssignedBodyValues(tasksVolunteerAssignedToDisplay)}
                containerClassNameStructure={['medium', 'narrow', 'narrow', 'narrow', 'narrow', 'narrow']}
              />
            </>
          )}
          {_.size(tasksVolunteerAppliedToDisplay) !== 0 && (
            <>
              <div className="sectionTitle ptop40">Tasks applied for</div>
              <Table
                headerValues={tableVolunteerHeaders.map(header => {
                  return { title: header.label };
                })}
                bodyDisplayRowCheckbox={false}
                bodyValues={parseVolunteerAppliedBodyValues(tasksVolunteerAppliedToDisplay)}
                containerClassNameStructure={['medium', 'narrow', 'narrow', 'narrow', 'narrow', 'narrow']}
              />
            </>
          )}
        </>
      )}
      {openAssignVolunteerModal && <ModalAssignVolunteer onClose={() => setOpenAssignVolunteerModal(false)} taskId={openAssignVolunteerModal} history={history} />}
      {openInviteVolunteerModal && <ModalInviteVolunteer onClose={() => setOpenInviteVolunteerModal(false)} taskId={openInviteVolunteerModal} />}
      {openReviewTimesheetModal && (
        <ModalReviewTimesheets
          isOpen={openReviewTimesheetModal}
          onClose={() => setOpenReviewTimesheetModal(false)}
          taskId={openReviewTimesheetModal}
          taskCreator={taskCreator}
          isCharityManager={isCharityManager}
          charityId={charityId}
          userId={_.get(userData, '_id')}
        />
      )}
      {openMarkAsCompleteModal && (
        <ModalMarkAsComplete
          isCharityManager={isCharityManager}
          taskSubmissionId={taskSubmissionId}
          taskId={taskId}
          taskCreator={taskCreator}
          taskFrequency={taskFrequency}
          taskName={taskName}
          userId={_.get(userData, '_id')}
          volunteerIds={volunteerIds}
          onClose={() => setOpenMarkAsCompleteModal(false)}
          charityId={charityId}
          onOpenReviewTimesheet={setOpenReviewTimesheetModal}
          onSetCharityId={setCharityId}
        />
      )}
      {openContinueTaskModal && (
        <ModalContinueTask
          isCharityManager={isCharityManager}
          taskSubmissionId={taskSubmissionId}
          taskId={taskId}
          taskFrequency={taskFrequency}
          taskCreator={taskCreator}
          userId={_.get(userData, '_id')}
          volunteerIds={volunteerIds}
          onClose={() => setOpenContinueTaskModal(false)}
          charityId={charityId}
        />
      )}
      {openLeaveFeedbackModal && (
        <ModalLeaveFeedback taskId={taskId} taskName={taskName} taskSubmissions={taskSubmissions} charityId={charityId} onClose={() => setOpenLeaveFeedbackModal(false)} />
      )}
      {openDeleteTaskModal && (
        <ModalDeleteTask
          taskId={taskId}
          userId={_.get(userData, '_id')}
          taskFrequency={taskFrequency}
          charityId={charityId}
          volunteerIds={volunteerIds}
          onClose={() => setOpenDeleteTaskModal(false)}
        />
      )}
      {openWithdrawSubmissionModal && (
        <ModalWithdrawSubmission
          taskSubmissionId={taskSubmissionId}
          taskCreator={taskCreator}
          taskId={taskId}
          userId={_.get(userData, '_id')}
          onClose={() => setOpenWithdrawSubmissionModal(false)}
        />
      )}
    </div>
  );
};

export default TasksList;
