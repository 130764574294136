import gql from 'graphql-tag';

export default gql`
  query fetchNotificationsByUserIdPagination($user_id: ID!, $page_id: Int) {
    notificationsByUserIdPagination(user_id: $user_id, page_id: $page_id) {
      _id
      User {
        _id
        firstname
        lastname
      }
      ActionUser {
        _id
        firstname
        lastname
      }
      Task {
        _id
        name
        Charity {
          _id
          picture
        }
      }
      isRead
      isVolunteer
      description
      created
    }
  }
`;
