import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import upload from 'superagent';
import moment from 'moment';
import md5 from 'md5';
import { v4 as uuidv4 } from 'uuid';
import CameraIcon from './cameraIcon';
import { globalVariables } from '../../../lib/globalVariables';
import LoadingOverlay from '../LoadingOverlay/view';

// Styles
import './styles/fileUploadStyles.css';

class FileUpload extends Component {
     constructor(props) {
         super(props);

         this.state = {
             previewImage: '',
             loading: false
         };

         this.onDrop = this.onDrop.bind(this);
     }

     componentWillMount() {
         if (this.props.previewImage) {
             this.setState({
                 previewImage: this.props.previewImage,
                 imageURL: this.props.previewImage
             });
         }
     }

     componentWillReceiveProps(newProps) {
         if (this.props.previewImage !== newProps.previewImage) {
             this.setState({
                 previewImage: newProps.previewImage,
                 imageURL: newProps.previewImage
             });
         }
     }

     onDrop(files) {
         const filename = this.generateFileName(uuidv4(), files[0]);
         this.setState({
             loading: true,
             previewImage: (files[0].preview) ? files[0].preview : ''
         });
         upload.post(globalVariables.getEnvironmentVariables().uploadURI)
             .attach('uploadToAws', files[0], filename)
             .end((e, f) => {
                 if (f.status !== 200) {
                     alert('File too large. Please try a different one');
                     this.setState({ loading: false });
                 } else {
                     this.setState({
                         loading: false,
                         imageURL: `${f.text}`
                     });
                     if (this.props.onFinishUpload) this.props.onFinishUpload(f.text);
                 }
             });
     }

     generateFileName(uniqueID, file) {
         const uniqueFilename = md5(`#${moment().valueOf()}F1l3${uniqueID}#`);
         const extension = file.type.split('/')[1];
         return `${uniqueFilename}.${extension}`;
     }

     render() {
         return (
            <div className={`fileUploadComponent ${this.props.className || ''}`}>
                <Dropzone
                    onDrop={this.onDrop}
                    multiple={false}
                    className={`dropzone ${this.state.imageURL ? 'hasImage' : ''}`}
                    style={{
                        width: 164,
                        height: 164,
                        backgroundColor: '#E0E0E0',
                        borderRadius: '50%',
                        backgroundImage: this.state.imageURL ? `url(${this.state.imageURL})` : '',
                        ...this.props.customStyles
                    }}
                >
                    {this.state.loading &&
                        <LoadingOverlay
                            style={{ opacity: 0.5, position: 'relative' }}
                        />
                    }
                    {!this.state.previewImage &&
                        <div className="previewImageContainer">
                            <CameraIcon className="cameraIcon" />
                            <span className="label">{this.props.label}</span>
                            {this.props.instructions &&
                                <h5 className="instructions">{this.props.instructions}</h5>
                            }
                        </div>
                    }
                    {this.state.imageURL &&
                        <div className="editButtons">
                            <CameraIcon
                                color="#fff"
                                width={40}
                                height={40}
                                className="cameraIcon"
                            />
                            <p>Edit</p>
                        </div>
                    }
                </Dropzone>
            </div>
        );
    }
}

export default FileUpload;
