import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';
import { messageUtils } from '../../lib/message';
import { localStorage } from '../../lib/storage';

function ForumItem({ item, itemSelected, dragHandleProps, commonProps }) {
  const { onMouseDown } = dragHandleProps;
  const userId = localStorage.get('userID');
  const handleMouseDown = event => {
    document.body.style.overflow = 'hidden';
    onMouseDown(event);
  };

  return (
    <div
      className="sidebarSelectableRow"
      onMouseDown={e => handleMouseDown(e)}
      onMouseUp={() => {
        document.body.style.overflow = 'visible';
      }}
    >
      <div className="rowBody">
        <Link to={`/dashboard/inbox/macro/${item._id}`} className={`${item.isSelected ? 'selected' : ''} ${item.unreadMessages > 0 ? 'hasUnseenMessages' : ''} forum`}>
          {_.get(item, 'name')}
        </Link>
        {item.unreadMessages > 0 && (
          <div className="unseenMessageBadge">
            <span>{item.unreadMessages > 9 ? '9+' : item.unreadMessages}</span>
          </div>
        )}
      </div>
    </div>
  );
}

export default ForumItem;
