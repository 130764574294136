import React, { useState, useCallback } from 'react';
import Skeleton from 'react-loading-skeleton';
import Popover from 'material-ui/Popover';
import { useQuery, useMutation } from '@apollo/client';
import _ from 'lodash';
import NotificationIcon from 'assets/svg/notifications.svg';
import UnreadIcon from 'assets/svg/unread.svg';
import CalendarCheckGreyIcon from 'assets/svg/calendar-check-grey.svg';
import 'react-loading-skeleton/dist/skeleton.css';
import './styles.scss';

// Query
import fetchNotificationsByUserIdPagination from '../../../queries/notification/fetchNotificationsByUserIdPagination';

// // Mutation
import updateNotificationMutation from '../../../mutations/notification/updateNotification';

export const NotificationsPopover = ({ history, totalCount, open, userId, anchorEl, requestClose }) => {
  const [updateNotification] = useMutation(updateNotificationMutation);
  const [notifications, setNotifications] = useState([]);
  const [pageNo, setPageNo] = useState(0);
  const [isScrollTop, setIsScrollTop] = useState(true);
  const handleReadNotification = useCallback(
    async id => {
      const updatedNotification = await updateNotification({
        variables: {
          _id: id,
          user_id: userId,
          isMarkAllAsRead: false,
        },
      });
      const data = notifications;
      const temp = [...data];
      const objIndex = temp.findIndex(obj => obj._id === id);
      temp[objIndex] = _.get(updatedNotification, 'data.updateNotification');
      setNotifications(temp);
      history.push('/dashboard/task-manager');
      requestClose();
    },
    [notifications],
  );

  const handleMarkAllAsRead = useCallback(async () => {
    const updatedNotification = await updateNotification({
      variables: {
        _id: '',
        user_id: userId,
        isMarkAllAsRead: true,
      },
    });
    const temp = notifications.map(notification => ({ ...notification, isRead: true }));
    setNotifications(temp);
  }, [notifications]);

  const { loading, error, data } = useQuery(
    fetchNotificationsByUserIdPagination,
    {
      variables: {
        user_id: userId,
        page_id: pageNo,
      },
      onCompleted: () => {
        const temp = notifications.concat(_.get(data, 'notificationsByUserIdPagination'));
        setNotifications(temp);
      },
    },
    [pageNo],
  );

  const handleScroll = useCallback(() => {
    const element = document.getElementsByClassName('notificationsGroup');
    if (element[0].scrollTop === 0) setIsScrollTop(true);
    else setIsScrollTop(false);
  }, []);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onRequestClose={requestClose}
      className={isScrollTop ? 'notificationsPopover' : 'notificationsPopover ptop15'}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      targetOrigin={{ horizontal: 'right', vertical: 'top' }}
      useLayerForClickAway
    >
      {isScrollTop ? (
        <div className="flexBetween pleft20 pright20 mtop0 mbottom24">
          <h3 className="textBlack fontPoppins mtop0 mbottom0">Notifications</h3>
          <div onClick={handleMarkAllAsRead}>
            <h5 className="textBlack fontPoppins mtop0 mbottom0 forceCursor">Mark all as read</h5>
          </div>
        </div>
      ) : (
        <div className="flexCenter pleft20 pright20">
          <h4 className="textBlack fontPoppins mtop0 mbottom15">Notifications</h4>
        </div>
      )}
      <div className="divider" />
      {loading && pageNo === 0 && (
        <div className="notificationsGroup">
          {new Array(5).fill(null).map((item, index) => (
            <div className="skeleton-container">
              <div>
                <Skeleton circle height={45} width={45} className="skeleton-image" />
              </div>
              <div className="skeleton-description">
                <Skeleton height={13} width={266} />
                <Skeleton height={13} width={266} />
                <Skeleton height={13} width={161} />
                <div className="skeleton-time">
                  <Skeleton height={13} width={77} />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {!loading && notifications?.length === 0 && (
        <div className="notificationsEmpty">
          <img src={NotificationIcon} alt="Notification Icon" />
          <h3 className="textBlack fontPoppins mtop12 mbottom12">No Notifications yet</h3>
          <div>It&#39;s only just the beginning!</div>
        </div>
      )}
      {notifications?.length !== 0 && (
        <div className="notificationsGroup" onScroll={handleScroll}>
          {notifications?.map((notification, index) => (
            <div key={index} className="notificationsContainer" onClick={() => handleReadNotification(notification._id)}>
              <div className="notifications">
                <img className="charityPicture mright15" src={notification.Task?.Charity?.picture} alt="user_image" />
                <div className="width100">
                  <div className="flexBetween">
                    <div className="description">
                      {notification.isVolunteer ? `${notification.User.firstname} ${notification.User.lastname} ${notification.description}` : `${notification.description}`}
                    </div>
                    {!notification.isRead && <img src={UnreadIcon} alt="Unread Icon" />}
                  </div>
                  <div className="flexStart mtop10">
                    <img className="mright4" src={CalendarCheckGreyIcon} alt="CalendarCheck Icon" />
                    <div className="detail">{notification.Task?.name}</div>
                  </div>
                  <div className="action mtop10">{notification.isVolunteer ? 'Review Applications' : 'View in Task Manager'}</div>
                </div>
              </div>
              <div className="flexEnd time">3 min ago</div>
            </div>
          ))}
          {!loading && totalCount > notifications.length && (
            <div
              className="loadmore mtop8 forceCursor"
              onClick={() => {
                setPageNo(pageNo + 1);
              }}
            >
              Load more ...
            </div>
          )}
          {loading &&
            new Array(5).fill(null).map((item, index) => (
              <div className="skeleton-container">
                <div>
                  <Skeleton circle height={45} width={45} className="skeleton-image" />
                </div>
                <div className="skeleton-description">
                  <Skeleton height={13} width={266} />
                  <Skeleton height={13} width={266} />
                  <Skeleton height={13} width={161} />
                  <div className="skeleton-time">
                    <Skeleton height={13} width={77} />
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
    </Popover>
  );
};
