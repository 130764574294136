import React, { useState, useEffect, useMemo } from 'react';
import { withRouter } from 'react-router';
import Select from 'react-select';
import { useLazyQuery, useQuery } from '@apollo/client';
import _ from 'lodash';
import searchTasks from 'queries/task/searchTasks';
import { FlatButton } from 'components/common/FlatButton/view';
import { localStorage } from 'lib/storage';
import { TASK_FREQUENCY, TASK_TYPE } from 'lib/task';
import { getSkillMacros } from 'lib/utils';
import fetchSkills from '../../../queries/skills/fetchSkills';
import { Input, Causes, LoadingOverlay } from '../../common';
import TaskCard from './taskCard';
import './styles/searchTasksStyle.scss';

function SearchTasks() {
  const userId = localStorage.get('userID');
  const [loading, setLoading] = useState(false);
  const [showCauses, setShowCauses] = useState(false);
  const [selectedCauses, setSelectedCauses] = useState([]);
  const [filterSkills, setFilterSkills] = useState([]);
  const [keywords, setKeywords] = useState('');
  const [skillsFilter, setSkillsFilter] = useState([]);
  const [frequencyFilter, setFrequencyFilter] = useState(0);
  const [locationsFilter, setLocationsFilter] = useState([]);
  const skillsResults = useQuery(fetchSkills);
  const skillsData = _.get(skillsResults, 'data.skills') || [];
  const [searchQuery, { data }] = useLazyQuery(searchTasks);
  const { searchTasks: tasks = [] } = data || {};

  const filtered = useMemo(
    () =>
      tasks.filter(task => {
        if (skillsFilter.length > 0) {
          const match = task.TaskSkills.some(({ Macro }) => skillsFilter.some(({ value }) => value === Macro._id));
          if (!match) return false;
        }
        if (frequencyFilter.length > 0 && !frequencyFilter.some(({ value }) => value === task.frequency)) return false;
        if (locationsFilter.length > 0) {
          if (!task.location && task.type === TASK_TYPE.FACE_TO_FACE) return false;
          if (!locationsFilter.includes(task.type === TASK_TYPE.FACE_TO_FACE ? task.location.state : 'Remote')) return false;
        }
        return true;
      }),
    [tasks, skillsFilter, frequencyFilter, locationsFilter],
  );

  const onSearch = async () => {
    setLoading(true);
    await searchQuery({
      variables: {
        keyword: keywords,
        causes: selectedCauses,
      },
    });
    setLoading(false);
  };

  const onResetFilters = () => {
    setSkillsFilter([]);
    setFrequencyFilter([]);
    setLocationsFilter([]);
  };

  const macros = skillsData.reduce((acc, curr) => {
    if (acc.some(({ _id }) => _id === curr.Macro._id)) return acc;
    return [...acc, curr.Macro];
  }, []);

  const taskMacros = tasks.reduce((acc, curr) => {
    const items = getSkillMacros(curr.TaskSkills);
    items.forEach(item => {
      if (acc.every(({ _id }) => _id !== item._id)) acc.push(item);
    });
    return acc;
  }, []);

  const taskFrequencies = tasks.reduce((acc, curr) => {
    if (!acc.includes(curr.frequency)) acc.push(curr.frequency);
    return acc;
  }, []);

  const taskLocations = tasks.reduce((acc, curr) => {
    const location = curr.type === TASK_TYPE.FACE_TO_FACE ? curr.location.state : 'Remote';
    if (!acc.includes(location)) acc.push(location);
    return acc;
  }, []);

  const [myNetwork, allTasks] = filtered.reduce(
    (acc, curr) => {
      if (curr.Charity.charity_followers.some(({ user_id }) => user_id === userId)) {
        acc[0].push(curr);
      } else {
        acc[1].push(curr);
      }
      return acc;
    },
    [[], []],
  );

  useEffect(() => {
    onSearch();
  }, [selectedCauses]);

  return (
    <div className="searchTasks">
      {loading && <LoadingOverlay />}
      <div className="header">
        <div className="headerContent">
          <h1>Search tasks & opportunities</h1>
          <div className="inputContainer">
            <Input placeholder="Search by charity name, skill, or keyword" value={keywords} onChange={setKeywords} />
            <FlatButton label="Search All" onClick={onSearch} filled />
          </div>
          <div className="causesContainer">
            <div className="buttonsContainer">
              <button className={`selectCausesBtn ${showCauses ? 'show' : ''}`} type="button" onClick={() => setShowCauses(val => !val)}>
                Select Causes
              </button>
              <FlatButton label="Clear all" onClick={() => setSelectedCauses([])} />
            </div>
            {showCauses ? (
              <Causes onGetNewCausesArray={newSelectedCauses => setSelectedCauses(newSelectedCauses)} selectedValues={selectedCauses} containerClass="searchTasksCauses" />
            ) : null}
          </div>
        </div>
      </div>
      <div className="tasksContainer">
        {tasks.length > 0 && (
          <>
            <div className="filterContainer">
              <div className="head">
                <h1 className="mRightAuto">Filters</h1>
                <FlatButton label="Reset Filters" onClick={onResetFilters} />
              </div>
              <div className="filter">
                {taskMacros.length > 1 && (
                  <div>
                    <h1>Skills</h1>
                    <Select
                      isMulti
                      value={skillsFilter}
                      options={taskMacros.map(macro => ({
                        value: macro._id,
                        label: macro.name,
                      }))}
                      onChange={options => {
                        setSkillsFilter(options);
                      }}
                    />
                  </div>
                )}
                {taskFrequencies.length > 1 && (
                  <div>
                    <h1>Commitment</h1>
                    <Select
                      isMulti
                      value={frequencyFilter}
                      options={[
                        {
                          value: TASK_FREQUENCY.ONGOING,
                          label: 'Ongoing Opportunity',
                        },
                        {
                          value: TASK_FREQUENCY.ONE_OFF,
                          label: 'One-off Task',
                        },
                      ]}
                      onChange={value => {
                        setFrequencyFilter(value);
                      }}
                    />
                  </div>
                )}
                {taskLocations.length > 1 && (
                  <div>
                    <h1>Location</h1>
                    <Select
                      isMulti
                      value={locationsFilter.map(val => ({
                        value: val,
                        label: val,
                      }))}
                      options={taskLocations.map(val => ({
                        value: val,
                        label: val,
                      }))}
                      onChange={options => {
                        setLocationsFilter(options.map(({ value }) => value));
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="tasks">
              {myNetwork.length > 0 && (
                <div className="myNetwork">
                  <h1>In my network</h1>
                  {myNetwork.map(task => (
                    <TaskCard key={task._id} task={task} userId={userId} />
                  ))}
                </div>
              )}
              {allTasks.length > 0 && (
                <div className="browseAll">
                  <h1>Browse All</h1>
                  {allTasks.map(task => (
                    <TaskCard key={task._id} task={task} />
                  ))}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default withRouter(SearchTasks);
