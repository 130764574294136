import gql from 'graphql-tag';

export default gql`
  query FetchTaskByID($_id: ID!) {
    taskByID(_id: $_id) {
      _charity_id
      _user_id
      name
      description
      TaskSkills {
        _id
        name
        skill_color
        skill_label_color
        Macro {
          _id
          name
          color
        }
      }
      volunteers_needed
      location {
        street
        city
        state
        postcode
      }
      Charity {
        _id
        picture
        name
        description
        Causes {
          _id
          name
        }
        charity_followers {
          user_id
        }
      }
      frequency
      oneoff_frequency_config {
        start
        end
        is_date_flexible
      }
      ongoing_frequency_config {
        type
        hours_per_week
      }
      TaskSubmissions {
        _id
        User {
          _id
          firstname
          lastname
          picture
        }
        created
        status
        TaskTimesheet {
          _id
          _task_submission_id
          name
          status
          start
          end
        }
      }
    }
  }
`;
