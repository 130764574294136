import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import fetchCharityById from 'queries/charity/fetchCharityById';
import { LoadingOverlay, MembersModal } from 'components/common';
import AboutEmptyIcon from 'components/charity/assets/about-empty.svg';
import defaultUserImage from 'components/charity/assets/defaultUserImage.png';
import { FlatButton } from 'components/common/FlatButton/view';
import { NetworkList } from 'components/common/NetworkList/view';
import { CharityDetailsHeader } from './detailsHeader/view';
import { CharityDetailsTasks } from './tasks/view';
import { ShareModal } from './shareModal/view';
import { localStorage } from '../../../lib/storage';
import './styles.scss';

const CharityDetails = ({ history, match }) => {
  const { charity_id: charityId } = match.params;
  const { loading, data } = useQuery(fetchCharityById, {
    variables: { _id: charityId },
    fetchPolicy: 'network-only',
  });
  const [showShare, setShowShare] = useState(false);
  const [isEditCharity, setIsEditCharity] = useState(false);
  const [showMembers, setShowMembers] = useState(false);

  if (!data) {
    return <LoadingOverlay style={{ opacity: 0.8 }} />;
  }

  const { charityById } = data;

  return (
    <div className="charityDetailsContainer col-xs-12">
      <div className="header-background"></div>
      <CharityDetailsHeader charity={charityById} onShare={() => setShowShare(true)} isEditCharity={isEditCharity} setIsEditCharity={setIsEditCharity} history={history} />
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-8">
            <div className="sectionContainer">
              {charityById.description ? (
                <>
                  <h2 className="bold mtop0">About</h2>
                  <p className="mbottom16">{charityById.description}</p>
                  {!!localStorage.get('userId') && <FlatButton label="Edit bio" onClick={() => setIsEditCharity(true)} />}
                </>
              ) : (
                <div className="flexCenter">
                  <img src={AboutEmptyIcon} alt="About Empty Icon" />
                  <div className="subTitle mtop16 mbottom16">Tell us about the charity</div>
                  <div className="content mbottom16">Write a short description about the charity. What is your mission and purpose?</div>
                  <FlatButton label="Add bio" onClick={() => setIsEditCharity(true)} />
                </div>
              )}
            </div>
            <CharityDetailsTasks charity={charityById} />
          </div>
          <div className="col-xs-12 col-md-4">
            <div className="sectionContainer flexCenter">
              <img src={_.get(charityById, 'charity_users.0.user.picture', defaultUserImage)} className="charityManagerLogo mbottom16" alt="Charity logo" />
              <div className="chairtyManagerName mbottom8">
                {`${_.get(charityById, 'charity_users.0.user.firstname', '')} ${_.get(charityById, 'charity_users.0.user.lastname', '')}`}
              </div>
              <div className="charityManager mbottom8">Charity Manager</div>
              <h4 className="charityManagerDescription">{_.get(charityById, 'charity_users.0.user.about', '')}</h4>
            </div>
            <NetworkList users={charityById.charity_followers} onShowAll={() => setShowMembers(true)} />
          </div>
        </div>
      </div>
      {showShare && (
        <ShareModal
          charity={charityById}
          onClose={() => setShowShare(false)}
          title={charityById.name}
          emailSubject={`${charityById.name} is on Worthy`}
          emailBody={`Take a look at ${charityById.name}'s profile on Worthy! ${window.location.href}`}
        />
      )}
      {showMembers && <MembersModal users={_.map(charityById.charity_followers, followers => followers.user)} title={charityById.name} onClose={() => setShowMembers(false)} />}
    </div>
  );
};

export default withRouter(CharityDetails);
