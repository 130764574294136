import React, { useState, useEffect, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import _ from 'lodash';
import { Button, DatePicker, TimePicker, Input, LoadingOverlay } from 'components/common';
import { FlatButton } from 'components/common/FlatButton/view';
import { CustomTextFieldStyle } from 'components/task/common/Elements';
import createTaskTimeSheetMutation from 'mutations/task/createTaskTimeSheet';
import updateTaskTimeSheetMutation from 'mutations/task/updateTaskTimeSheet';
import deleteTaskTimeSheetMutation from 'mutations/task/deleteTaskTimeSheet';
import markAsCompleteMutation from 'mutations/task/markAsComplete';
import updateTaskSubmissionMutation from 'mutations/task/updateTaskSubmission';
import updateMultiTaskTimeSheetMutation from 'mutations/task/updateMultiTaskTimeSheet';
import createNotificationMutation from 'mutations/notification/createNotification';
import fetchUserById from 'queries/user/fetchUserById';
import submissionsByTask from 'queries/task/taskSubmissionsByTask';
import { localStorage } from 'lib/storage';
import CheckWhiteIcon from 'assets/svg/check-white.svg';
import { TimeSheet } from './Timesheet';
import './styles.scss';

export const TimeSheetsBySubmission = ({ taskId, taskCreator, taskSubmission, isCharityManager }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [timeSheetName, setTimeSheetName] = useState('');
  const [flexibleDate, setFlexibleDate] = useState(false);
  const [flexibleTime, setFlexibleTime] = useState(false);
  const [timeSheets, setTimeSheets] = useState([]);
  const [isAllApproved, setIsAllApproved] = useState(false);
  const [createTaskTimeSheet] = useMutation(createTaskTimeSheetMutation);
  const [updateTaskTimeSheet] = useMutation(updateTaskTimeSheetMutation);
  const [deleteTaskTimeSheet] = useMutation(deleteTaskTimeSheetMutation);
  const [updateTaskSubmission] = useMutation(updateTaskSubmissionMutation);
  const [markAsComplete] = useMutation(markAsCompleteMutation);
  const [updateMultiTaskTimeSheet] = useMutation(updateMultiTaskTimeSheetMutation);
  const [createNotification] = useMutation(createNotificationMutation);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (taskSubmission) {
      const data = _.get(taskSubmission, 'TaskTimesheet');
      setTimeSheets(data);
      if (data && data.some(element => element.status === 1)) setIsAllApproved(false);
      else setIsAllApproved(true);
    }
  }, [taskSubmission]);

  const handleAddTimeSheet = useCallback(async () => {
    setIsLoading(true);
    startDate.setHours(startTime.split(':')[0]);
    startDate.setMinutes(startTime.split(':')[1]);
    endDate.setHours(endTime.split(':')[0]);
    endDate.setMinutes(endTime.split(':')[1]);
    const timesheet = await createTaskTimeSheet({
      variables: {
        taskTimeSheet: {
          _task_submission_id: taskSubmission._id,
          name: timeSheetName,
          status: 1,
          start: startDate.getTime(),
          end: endDate.getTime(),
        },
      },
    });
    const updatedSubmissionData = await updateTaskSubmission({
      variables: {
        id: taskSubmission._id,
        taskSubmission: {
          status: 4,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: fetchUserById,
          variables: {
            id: localStorage.get('userID'),
          },
        },
      ],
    });
    const updatedSubmission = _.get(updatedSubmissionData, 'data.updateTaskSubmission');
    if (updatedSubmission) setIsLoading(false);
    const temp = timeSheets;
    if (_.get(timesheet, 'data.createTaskTimesheet')) {
      const k = [...temp];
      k.push(_.get(timesheet, 'data.createTaskTimesheet'));
      if (k && k.some(element => element.status === 1)) setIsAllApproved(false);
      else setIsAllApproved(true);
      setTimeSheets(k);
      setStartTime('');
      setEndTime('');
      setStartDate(new Date());
      setEndDate(new Date());
      setTimeSheetName('');
    }
    const notification = {
      _user_id: [taskCreator],
      _action_user_id: localStorage.get('userID'),
      _task_id: taskId,
      isRead: false,
      isVolunteer: true,
      description: 'submitted a timesheet',
    };
    await createNotification({
      variables: {
        notification,
      },
    });
  }, [timeSheets, startTime, endTime, timeSheetName]);

  const handleEditTimeSheet = useCallback(
    async (timesheetId, taskSubId) => {
      setIsLoading(true);
      startDate.setHours(startTime.split(':')[0]);
      startDate.setMinutes(startTime.split(':')[1]);
      endDate.setHours(endTime.split(':')[0]);
      endDate.setMinutes(endTime.split(':')[1]);
      const timesheet = await updateTaskTimeSheet({
        variables: {
          id: timesheetId,
          taskTimeSheet: {
            _task_submission_id: taskSubId,
            name: timeSheetName,
            start: startDate.getTime(),
            end: endDate.getTime(),
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: submissionsByTask,
            variables: {
              taskId,
            },
          },
        ],
      });
      const temp = timeSheets;
      const updatedTimeSheet = _.get(timesheet, 'data.updateTaskTimesheet');
      if (updatedTimeSheet) {
        setIsLoading(false);
        const k = [...temp];
        const foundIndex = k.findIndex(item => item._id === updatedTimeSheet._id);
        k[foundIndex] = updatedTimeSheet;
        setTimeSheets(k);
        setStartTime('');
        setEndTime('');
        setStartDate(new Date());
        setEndDate(new Date());
        setTimeSheetName('');
        setIsEdit(false);
      }
      const notification = {
        _user_id: [taskCreator],
        _action_user_id: taskCreator,
        _task_id: taskId,
        isRead: false,
        isVolunteer: true,
        description: 'updated a timesheet',
      };
      await createNotification({
        variables: {
          notification,
        },
      });
    },
    [timeSheets, startDate, endDate, startTime, endTime, timeSheetName],
  );

  const handleApproveTimesheet = useCallback(
    async (timeSheetId, taskSubId) => {
      setIsLoading(true);
      const timesheet = await updateTaskTimeSheet({
        variables: {
          id: timeSheetId,
          taskTimeSheet: {
            _task_submission_id: taskSubId,
            status: 2,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: submissionsByTask,
            variables: {
              taskId,
            },
          },
        ],
      });
      const temp = timeSheets;
      const updatedTimeSheet = _.get(timesheet, 'data.updateTaskTimesheet');
      if (updatedTimeSheet) {
        setIsLoading(false);
        const k = [...temp];
        const foundIndex = k.findIndex(item => item._id === updatedTimeSheet._id);
        k[foundIndex] = { ...k[foundIndex], status: 2 };
        if (k && k.some(element => element.status === 1)) setIsAllApproved(false);
        else setIsAllApproved(true);
        setTimeSheets(k);
        setStartTime('');
        setEndTime('');
        setStartDate(new Date());
        setEndDate(new Date());
        setTimeSheetName('');
      }
      const notification = {
        _user_id: [_.get(taskSubmission, 'User._id')],
        _action_user_id: taskCreator,
        _task_id: taskId,
        isRead: false,
        isVolunteer: false,
        description: 'Your timesheet was approved',
      };
      await createNotification({
        variables: {
          notification,
        },
      });
    },
    [timeSheets, startTime, endTime, timeSheetName],
  );

  const handleApproveMultiTimeSheet = useCallback(async () => {
    setIsLoading(true);
    const res = await updateMultiTaskTimeSheet({
      variables: {
        taskSubmissionId: taskSubmission._id,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: submissionsByTask,
          variables: {
            taskId,
          },
        },
      ],
    });
    const updatedTimesheets = _.get(res, 'data.updateMultiTaskTimesheet');
    if (updatedTimesheets.length > 0) {
      setIsLoading(false);
      const temp = timeSheets.map(approvedTimeSheet => {
        const result = { ...approvedTimeSheet, status: 2 };
        return result;
      });
      if (temp && temp.some(element => element.status === 1)) setIsAllApproved(false);
      else setIsAllApproved(true);
      setTimeSheets([...temp]);
    }
  }, [timeSheets]);

  const handleEditTimesheetRequest = useCallback(async timesheet => {
    setTimeSheetName(_.get(timesheet, 'name'));
    setStartDate(new Date(_.get(timesheet, 'start')));
    setEndDate(new Date(_.get(timesheet, 'end')));
    setStartTime(moment(new Date(_.get(timesheet, 'start'))).format('HH:mm'));
    setEndTime(moment(new Date(_.get(timesheet, 'end'))).format('HH:mm'));
    setIsEdit(_.get(timesheet, '_id'));
  }, []);

  const handleDeleteTimesheet = useCallback(
    async timeSheetId => {
      setIsLoading(true);
      const timesheet = await deleteTaskTimeSheet({
        variables: {
          id: timeSheetId,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: submissionsByTask,
            variables: {
              taskId,
            },
          },
        ],
      });
      const temp = timeSheets;
      const deletedTimeSheetId = _.get(timesheet, 'data.deleteTaskTimesheet._id');
      if (deletedTimeSheetId) {
        const k = temp.filter(item => item._id !== deletedTimeSheetId);
        if (k.length === 0) {
          const updatedSubmissionData = await updateTaskSubmission({
            variables: {
              id: taskSubmission._id,
              taskSubmission: {
                status: 3,
              },
            },
            awaitRefetchQueries: true,
            refetchQueries: [
              {
                query: fetchUserById,
                variables: {
                  id: localStorage.get('userID'),
                },
              },
            ],
          });
          const updatedSubmission = _.get(updatedSubmissionData, 'data.updateTaskSubmission');
          if (updatedSubmission) setIsLoading(false);
        }
        setTimeSheets(k);
        setStartTime('');
        setEndTime('');
        setStartDate(new Date());
        setEndDate(new Date());
        setTimeSheetName('');
        setIsLoading(false);
      }
    },
    [timeSheets, startTime, endTime, timeSheetName],
  );

  const handleMarkAsComplete = useCallback(async () => {
    setIsLoading(true);
    await markAsComplete({
      variables: {
        taskId: taskSubmission._task_id,
        submissionId: taskSubmission._id,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: submissionsByTask,
          variables: {
            taskId,
          },
        },
      ],
    });
    setIsLoading(false);
  }, [taskSubmission._id, taskSubmission._task_id]);

  return (
    <div className="reviewTimesheetsContainer">
      {isLoading ? (
        <div className="flexCenter relative">
          <LoadingOverlay style={{ opacity: 0.8 }} />
        </div>
      ) : (
        <>
          <div className="mtop8">
            <div className="flex row pleft20 pright20">
              <div className="col-xs-4">
                <h4 className="fontPoppins header">Name</h4>
              </div>
              <div className="col-xs-4">
                <h4 className="fontPoppins header">Time entry</h4>
              </div>
              <div className="col-xs-2">
                <h4 className="fontPoppins header">Total</h4>
              </div>
            </div>
            <div className="timeSheetsContainer">
              {timeSheets.length === 0 ? (
                <div className="fontPoppins normal empty-content">No timesheet submitted</div>
              ) : (
                timeSheets.map(timesheet => (
                  <TimeSheet
                    key={timesheet._id}
                    timesheet={timesheet}
                    isCharityManager={isCharityManager}
                    handleApproveTimesheet={handleApproveTimesheet}
                    handleDeleteTimesheet={handleDeleteTimesheet}
                    handleEditTimesheetRequest={handleEditTimesheetRequest}
                  />
                ))
              )}
            </div>
          </div>
          {timeSheets.length !== 0 && taskSubmission.status !== 5 && (
            <div className="btn_group_approve_complete">
              {isCharityManager && (
                <div className={`btn_approve_all_cover ${!isAllApproved ? 'enable_approve' : 'disable_approve'}`}>
                  <FlatButton label="Approve all" className="btn_approve_all" onClick={() => handleApproveMultiTimeSheet()} />
                </div>
              )}
              <div className={`btn_mark_as_complete_cover ${isAllApproved ? 'enable_complete' : 'disable_complete'}`}>
                <Button label="Mark as complete" className="btn_mark_as_complete" onClick={() => handleMarkAsComplete()} />
              </div>
            </div>
          )}
          {(isEdit || (!isCharityManager && taskSubmission.status !== 5)) && (
            <div className="border_top ptop20">
              <div>
                <div className="mbottom16 add_time_entry">Add new time entry</div>
                <Input className="edit_name" name="name" placeholder="What are you working on?" type="text" value={timeSheetName} onChange={value => setTimeSheetName(value)} />
              </div>
              <div className="flex row">
                <div className="col-xs-3">
                  <DatePicker
                    placeholder="Start date"
                    disabled={flexibleDate}
                    minDate={new Date()}
                    value={moment(startDate).toDate()}
                    format="DD MMMM"
                    textFieldStyle={CustomTextFieldStyle}
                    onChange={(e, value) => setStartDate(value)}
                  />
                </div>
                <div className="col-xs-3">
                  <DatePicker
                    placeholder="End date"
                    disabled={flexibleDate}
                    minDate={moment(startDate).toDate()}
                    value={moment(endDate).toDate()}
                    format="DD MMMM"
                    textFieldStyle={CustomTextFieldStyle}
                    onChange={(e, value) => setEndDate(value)}
                  />
                </div>
                <div className="col-xs-3">
                  <TimePicker placeholder="Start time" disabled={flexibleTime} value={startTime} style={CustomTextFieldStyle} onChange={setStartTime} />
                </div>
                <div className="col-xs-3">
                  <TimePicker placeholder="End time" disabled={flexibleTime} value={endTime} style={CustomTextFieldStyle} onChange={setEndTime} />
                </div>
                <div className="col-xs-4 flexColumn flexEnd">
                  {isEdit ? (
                    <Button
                      label="Edit time entry"
                      className="btn_edit"
                      disabled={!startTime || !endTime || !timeSheetName}
                      onClick={() => handleEditTimeSheet(isEdit, taskSubmission._id)}
                    />
                  ) : (
                    <Button label="Add time entry" className="btn_add" disabled={!startTime || !endTime || !timeSheetName} onClick={handleAddTimeSheet} />
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
