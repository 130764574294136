import React from 'react';
import '../styles/legalStyles.css';

function PrivacyPolicy() {
  return (
    <div className="legalContainerNew">
      <div className="header">
        <h1>Privacy Policy</h1>
      </div>
      <div className="mainContainer">
        <div className="content">
          <h1 id="privacy">Privacy Policy</h1>
          <p>
            This privacy policy sets out how Worthy Network Inc., a Delaware registered C Corporation dba Worthy (“Worthy”, “Company”, “we”, “us” or “our”) collects, uses and
            protects any information that you give us when you use our websites, mobile apps, social media pages, or any other products or services offered by us (“Services”). We
            are committed to ensuring that your privacy is protected. If we ask you to provide certain information by which you can be identified when using this website, then you
            can be assured that it will be used only in accordance with this privacy statement, as updated from time to time.
          </p>
          <h2>Links to Other Websites, Apps and Services</h2>
          <p>
            Our websites and other Services may contain links to other third-party websites, apps and services of interest. However, once you have used these links to leave our
            site, you should note that we have no control over such third-party services. Therefore, we cannot be responsible for the protection and privacy of any information that
            you provide while visiting such sites and services, and they are not governed by this privacy statement. You should exercise caution and should review the privacy
            statement(s) applicable to the third-party sites and services in question.
          </p>
          <h2>Collection of Your Information</h2>
          <p>We collect or receive information about your interactions with us. Depending on how you use our Services, your information may include, without limitation:</p>
          <ul>
            <li>
              registration-related information (such as name, addresses, email addresses, telephone numbers, occupation and information imported from social log in permissions
              granted to us);
            </li>
            <li>information about the Services that you use, how often you use them, and your responses to the offerings and advertisements presented or made available by us;</li>
            <li>information about the searches you perform on our websites or in our other Services and how you use the results of those searches;</li>
            <li>
              transaction-related information (such as credit card or other preferred means of payment, billing information, credit card number, expiration date, billing address
              and/or a history of purchases through our Services);
            </li>
            <li>customer service information about you as a user of our Services;</li>
            <li>location data;</li>
            <li>information about any devices, connections and methods used to access and interact with us; </li>
            <li>other information specifically related to your use of Services, including information that you publicly post using tools made available by us;</li>
            <li>
              information based on your usage of our Services (“Usage Data”), which may be collected automatically by us or the services (or third-party services within our
              Services), which can include the IP addresses or domain names of the computers utilized by the users who use the Services, the URI addresses (Uniform Resource
              Identifier), the time of the request, the method utilized to submit the request to the server, the size of the file received in response, the numerical code
              indicating the status of the server’s answer (such as successful outcome and error), the country of origin, the features of the browser and the operating system
              utilized by the user, the various time details per visit (such as the time spent on each page within the Services) and the details about the path followed within the
              Services with special reference to the sequence of pages visited, other parameters about the device operating system and/or the user’s IT environment, and data,
              conversion rates, marketing and conversion data and statistics, reports, analytics data, reviews and surveys. Usage Data may sometimes be essentially anonymous when
              collected, but could be used indirectly to identify a person;
            </li>
            <li>
              we collect personal information from all communications with Service end users including, without limitation, text messages, faxes, telephone calls, and regular
              “snail mail,“ as well as from third-party outside sources including database vendors.
            </li>
          </ul>
          <p>In some countries, including in the European Economic Area, some of this information may be considered “personal data” under applicable data protection laws.</p>
          <p>
            Your information may be supplemented with additional information from other companies such as publicly available information, information about households and other
            information that we may append or match to your information.
          </p>
          <p>
            We may also receive or collect certain technical information when you use our Services. This may include your browser or operating system, your manner of connecting to
            the Internet and the name of your Internet service provider or wireless carrier; your Internet protocol (IP) address; information about referring websites or services
            (websites or services you used immediately prior to using our websites or other Services; exiting websites or services (immediately after using our website or other
            Services); and data relating to malfunctions or problems occurring when you use our Services. Additionally, we may collect information about other software on your
            device for the limited purpose of protecting your security or improving your online experience.
          </p>
          <h2>How Your Information may be used</h2>
          <p>Your information may be used for purposes that include, without limitation:</p>
          <ul>
            <li>to operate and improve the Services available through us;</li>
            <li>to personalize the content and advertisements provided to you (including to present offers to you on behalf of business partners and advertisers);</li>
            <li>to fulfill your requests for tools, software, functionality, features and other products, and services;</li>
            <li>to communicate with you and respond to your inquiries;</li>
            <li>to conduct research about your use of our products; and</li>
            <li>to help offer you other products, features or services that may be of interest;</li>
            <li>to provide customer support and technical assistance;</li>
            <li>for administration of your account;</li>
            <li>to create user profiles;</li>
            <li>to create de-identified analytical information.</li>
          </ul>
          <p>
            We reserve the right to make full use of Usage Data. For example, we may use Usage Data to provide better service to Service visitors or end users, customize the
            Services based on your preferences, compile and analyze statistics and trends about the use of our Services and otherwise administer and improve our Services.{' '}
          </p>
          <h2>Cookies</h2>
          <p>
            We may use cookies, flash cookies, local shared objects, web beacons, pixels, single pixel GIFs, clear GIFs, and/or other technologies along with your information to
            enhance and personalize your experience and gather information that helps us optimize our Services. You can choose to accept or decline some cookies. Most web browsers
            automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. However, it is important to remember that many of our
            Services may not function properly if your cookies are disabled.
          </p>
          <p>
            Our Third Party Vendors or Third Party Services (as defined below) may use their own cookies and/or other third-party cookies together (subject to their own privacy
            policies) to (a) inform, optimize, and serve ads across the web based on your past visits to our Services and others and (b) report to us how your ad impressions, other
            uses of ad services, and interactions with these ad impressions and ad services are related to visits to our site. If and to the extent from time to time we use Double
            Click or another Google brand for ad serving, analytics, remarketing, retargeting, etc., you can set certain preferences and opt-outs using Google Ads Settings, and
            Google Analytics’ currently available opt-outs, found here <a href="https://www.google.com/ads/preferences/">https://www.google.com/ads/preferences/</a> and here{' '}
            <a href="https://adssettings.google.com/">https://adssettings.google.com/</a>. Website users may opt out of Google’s use of cookies by visiting the{' '}
            <a href="https://support.google.com/ads/answer/7395996">Google Advertising Opt-out Page</a>. Website users may opt out of Google Analytics by visiting the{' '}
            <a href="https://tools.google.com/dlpage/gaoptout">Google Analytics Opt-out Page</a>. Website users may opt out of third party vendor use of cookies by visiting the{' '}
            <a href="https://optout.networkadvertising.org/?c=1">Network Advertising Initiative Opt-out Page</a>.
          </p>
          <h2>Sharing of Your Information</h2>
          <p>
            We do not rent or sell your personally identifiable information (such as name, address, telephone number and credit card information) to unaffiliated third parties for
            their marketing purposes. We may share your information with third parties to provide products and services you have requested, when we have your consent, or as
            described in this Privacy Policy.
          </p>
          <p>
            We may share aggregated, non-personally identifiable information, publicly and with our partners like publishers, advertisers or connected sites. For example, we may
            share information publicly to show trends about the general use of our websites and/or other products or services.
          </p>
          <p>
            The contents of your online communications, as well as other information about you as a user of our Services, may be accessed and disclosed under the following
            circumstances: in response to lawful governmental requests or legal process (for example, a court order, search warrant or subpoena), in other circumstances in which we
            have a good-faith belief that a crime has been or is being committed by a user of our Services, that an emergency exists that poses a threat to the safety of you or
            another person, when necessary to protect either our rights or our property or for us to render the service you have requested.
          </p>
          <p>
            Many of our Services let you share information with others. Remember that when you share information publicly, others besides us have access to it, and it may be
            indexable by search engines, or copied, forwarded, saved or archived by others.
          </p>
          <p>
            Our offerings may include features or functionalities provided by third parties (“Third Party Vendors”), or may integrate or interact with third-party products and
            services (including, without limitation, via APIs, plug ins, links, frames, embedding, or other interactions) (“Third Party Services”). For example, without limitation,
            we may use a third party for ad serving, retargeting, remarketing and/or for analytics, in which case such third party may have access to your data, subject to its
            policies. In the process of providing such functionalities within our services, your browser or other tools or technology may send certain information to the Third
            Party Provider and/or Third Party Services. The use of these third-party-provided features or functionalities is subject to their own privacy policies.{' '}
          </p>
          <p>
            Business partners or other third parties may receive data about groups of our users, but, except as otherwise permitted herein, do not receive information that
            personally identifies you. We may use agents and contractors in order to help operate our Services. Their use of information is limited to these purposes, except as
            otherwise permitted herein.
          </p>
          <p>
            In the event that ownership of us was to change as a result of a merger, acquisition, or transfer to another company, your information may be transferred. If such a
            transfer results in a material change in the use of your information, you will be provided notice (which may be via updates to this page) about your ability to decline
            to permit such a transfer.
          </p>
          <p>
            Any personal information which we may collect via the Services may be stored and processed in our servers located in the United States or in any other country in which
            we, or our affiliates, subsidiaries or agents, maintain facilities. You consent to any such transfer of personal information outside your country of residence to any
            such location.
          </p>
          <p>
            General Disclosure Policy. We reserve the right to disclose your personal information as described below. We reserve the right to disclose Usage Data without
            restriction.
          </p>
          <p>
            Affiliated Entities. We reserve the right to provide your personal information and Usage Data to any affiliated entities we may have, including our subsidiaries.
            Affiliated entities are entities that we control or that control us.
          </p>
          <p>
            Service Providers. We reserve the right to provide access to your personal information and Usage Data to our trusted service providers that assist us with the operation
            and maintenance of our sites and services. For example, we may contract with third parties to process payments, host our servers, provide security and provide
            production, fulfillment, optimization, analytics and reporting services. We will use commercially reasonable efforts to ensure that our service providers will be given
            access to your information only as is reasonably necessary to provide the services for which they are contracted.
          </p>
          <p>
            Successors. If we sell or otherwise transfer part or all of our business or assets to another organization, such as in the course of an acquisition, merger, bankruptcy
            or liquidation, we may transfer your personal information and Usage Data.
          </p>
          <p>
            Legal Process, Enforcement and Security Notice. We reserve the right to disclose your personal information and Usage Data if we have a good-faith belief that access,
            use, preservation or disclosure of such information is reasonably necessary (i) to satisfy any applicable law, regulation, legal process or enforceable governmental
            request (such as for example, to comply with a subpoena or court order), (ii) to detect, prevent, and address fraud or other illegal activity and (iii) to investigate,
            respond to, or enforce violations of our rights or the security of our sites and services.{' '}
          </p>
          <p>
            When We Participate in a Joint Venture with Marketing Partners. We may participate with another company or individual for purposes of jointly promoting our products,
            services, promotions or contests or their products, services, promotions or contests. We reserve the right to disclose your personal information to them for purposes of
            (i) compensation, transaction processing, fulfillment and support, and (ii) for purposes of offering you other products, services, promotions and contests. These joint
            venture marketing partners may also contact you about other products, services, promotions or contests.{' '}
          </p>
          <p>We may also use your data in other ways described herein, and as otherwise permitted or required by applicable laws.</p>
          <h2>Display of Advertising</h2>
          <p>
            Your information may be used for the presentation of advertisements. We may use ad networks to customize and display advertising on our Services. We may share certain
            information about you as a user (such as age, zip code or other information we have collected or received) with certain ad network and service providers to help them
            deliver more relevant content and advertisements through their networks. We may from time to time work with other companies for certain services such as analytics or
            advertising, and you may be subject to their privacy policies as well, though you may opt out through them directly or contact us with questions.
          </p>
          <h2>Your Choices about Your Information</h2>
          <p>You may choose to restrict the collection or use of your personal information in the following ways:</p>
          <ul>
            <li>
              Whenever you are asked to fill in a form on our website or our other Services, consider what information to include and exclude; in addition, sometimes there may be a
              box that you can click to indicate that you do not want the information to be used by anybody for direct marketing purposes
            </li>
            <li>
              We may provide you with access to your registration information and the ability to edit this information in your account settings dashboard and profile pages. Please
              be aware that even after you delete or update information within our Services, we may not immediately delete residual copies from our active servers and may not
              remove information from our backup systems. Similarly, if and to the extent any information is indexable by search engines (including, without limitation, public
              profile information), it may not be updated by such search engines when we update it, and old versions may be archived by them or by third parties outside our
              control.
            </li>
            <li>Some of our Services may provide you with additional information and choices about your privacy, which you should review.</li>
            <li>
              If you have previously agreed to our using your personal information for direct marketing purposes, you may change your mind at any time by writing to us using the
              contact information below.
            </li>
          </ul>
          <h2>Our Commitment to Security</h2>
          <p>
            We have established safeguards to help prevent unauthorized access to or misuse of your information, but cannot guarantee that your information will never be disclosed
            in a manner inconsistent with this Privacy Policy (for example, as a result of unauthorized acts by third parties that violate applicable law or our and our affiliated
            providers’ policies). To protect your privacy and security, we may use passwords or other technologies to register or authenticate you and enable you to take advantage
            of our Services, and before granting access or making corrections to your information.
          </p>
          <h2>YOUR CALIFORNIA PRIVACY RIGHTS – California Consumer Privacy Act</h2>
          <p>
            For California residents: We may share your personal information with third parties and affiliated third parties (such as local, state and regional affiliates and
            affiliate alliances), but they do not share your name for their direct marketing purposes. As these third parties and this category of affiliated third parties are
            considered an unaffiliated party under California law, you may opt-out of our disclosure of personal information to third parties for their direct marketing purposes.
            To opt out, please contact us as described in the “How to Contact Us” Section below.
          </p>
          <p>
            We will continue to provide your information to local, state and regional affiliates and affiliate alliances identified in your membership application or account for
            the purpose of processing your membership in such affiliate and affiliate alliance programs.
          </p>
          <p>
            The California Consumer Privacy Act (CCPA), effective January 1, 2020, gives California consumers enhanced rights with respect to their personal information that is
            collected by businesses. First, California consumers may opt out of having their personal information sold to other persons or parties. Second, they have a right to
            know:
          </p>
          <ol>
            <li>What specific pieces of information a business has about the consumer; </li>
            <li>Categories of personal information it has collected about the consumer;</li>
            <li>Categories of sources from which the personal information is collected;</li>
            <li>Categories of personal information that the business sold or disclosed for a business purpose about the consumer;</li>
            <li>Categories of third parties to whom the personal information was sold or disclosed for a business purpose; and</li>
            <li>The business or commercial purpose for collecting or selling personal information. </li>
          </ol>
          <p>In addition, California consumers can request that the personal information a business has collected about them be deleted from the business’s systems and records.</p>
          <p>
            Company may be considered a covered business under the CCPA as it collects and processes the personal information of California consumers. This Privacy Policy provides
            the required notices to California consumers. The CCPA also prohibits covered businesses from providing discriminatory treatment to California consumers if they
            exercise their rights under the Act.{' '}
          </p>
          <p>
            We do not rent or sell your personally identifiable information (such as name, address, telephone number and credit card information) to unaffiliated third parties for
            their marketing purposes. We may share your information with third parties to provide products and services you have requested, when we have your consent, or as
            described in this Privacy Policy.{' '}
          </p>
          <p>
            To make a “request to know” or request to delete your personal information, send us an e-mail at <a href="mailto: mail@worthycause.com">mail@worthycause.com</a> (Please
            put either “Request to Know” or “Request to Delete” in the subject heading of your email.) We will use commercially reasonable efforts to honor these requests whether
            or not you would qualify as a California consumer under the CCPA.
          </p>
          <p>
            If and to the extent we are considered a covered business under the CCPA: We will confirm receipt of your request within 10 days along with a description of what steps
            we will take to verify and respond. We must provide the requested information or delete your personal information within 45 days of receipt of your request but can use
            an additional 45 days, but we must let you know the additional time is needed.
          </p>
          <p>
            When contacting us, we may ask you to provide certain, limited personal information, such as your name, email address and/or account login ID and/or password, to verify
            your request and to match with our records and systems. This is also to protect against fraud. We will not retain this personal information or use it for any other
            purpose. Also please be advised that we need to search our records and systems only for the preceding 12 months.
          </p>
          <p>
            Residents of other states may also have similar rights to request information about or delete their personal information. To inquire about exercising these rights,
            please contact us at <a href="mailto: mail@worthycause.com">mail@worthycause.com</a>.
          </p>
          <p>
            Pursuant to California’s “Shine The Light law (California Civil Code § 1798.983), California residents are entitled, once a year and free of charge, to request the
            disclosure of certain categories of personal information to third parties for their own direct marketing purposes in the preceding calendar year, if any. Under the law,
            a business should either provide California customers certain information upon request or permit California customers to opt out of this type of sharing. You may
            request this information by contacting us at <a href="mailto: mail@worthycause.com">mail@worthycause.com</a> and indicate in the email subject line, “California Shine
            The Light Request.” Please include your mailing address, state of residence and email address with your request.
          </p>
          <h2>YOUR RIGHTS AS A VISITOR FROM THE EUROPEAN ECONOMIC AREA (EEA)</h2>
          <p>
            If you are a European resident, our legal basis for collecting and using your personal data or information is to do so with your consent; where we need the personal
            data or information for performance of a contract, or where the collection and use is in our legitimate interests and not overridden by your data protection interests
            or fundamental rights and freedoms. In some cases, we may also have a legal obligation to collect the personal information in question. If we collected your personal
            data or information with your consent, you may withdraw your consent at any time.
          </p>
          <p>You also have the right to:</p>
          <ul>
            <li>Access your personal data or information;</li>
            <li>Delete, or request deletion of, your personal data or information;</li>
            <li>Object to or restrict processing of your personal information; </li>
            <li>Request portability of your personal information;</li>
            <li>Complain to your local data protection authority at any time;</li>
            <li>Object to automated decision making; and</li>
            <li>Update your personal data or information.</li>
          </ul>
          <p>To withdraw consent or exercise these rights, please contact us as described in the “How to Contact Us” Section below.</p>
          <p>
            Withdrawing your consent will not affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it affect processing of your personal
            information conducted in reliance on lawful processing grounds other than consent.
          </p>
          <p>
            If we ask you to provide personal data to us to comply with a legal requirement or enter into a contract, we will inform you of this and let you know whether providing
            us with your personal data is required and if not, the consequences of not sharing your personal data with us.{' '}
          </p>
          <p>
            Similarly, if we collect and use your personal information in reliance on our or a third party’s legitimate interests and those interests are not already listed above
            (see “Information Use“ section), we will let you know what those legitimate interests are.
          </p>
          <h2>DATA STORAGE AND RETENTION (International Transfers)</h2>
          <p>
            Your personal information may be stored on servers in the United States and may also be stored or processed in other countries by our service providers, if and to the
            extent compliant with law. You understand and agree that We may collect, use, disclose, and otherwise process the information you provide as described in this Privacy
            Statement even if you are from an area outside the United States. Your personal information may be disclosed in response to inquiries or requests from government
            authorities or to respond to judicial process in the United States. We will retain your personal information for as long as it is needed to provide you with the
            Services, or to fulfill any legal or contractual obligations we may have, or as otherwise permitted herein and by law.
          </p>
          <p>
            If you are a resident of the EEA: Your personal information may be transferred to and processed in the United States, which has data protection laws that are different
            than those in your country and may not be as protective.
          </p>
          <h2>DO NOT TRACK DISCLOSURES (EEA and California)</h2>
          <p>
            Some web browsers incorporate a “Do Not Track“ feature that signals to websites and Services that you visit that you do not want to have your online activity tracked.
            Each browser communicates “Do Not Track“ signals to websites differently, making it unworkable to honor each and every request correctly. In order to alleviate any
            communication error between browsers and our Services, we do not respond to or honor “Do Not Track“ signals at this time. As the technology and communication between
            browser and website improves, we may elect in our discretion to reevaluate the ability to honor “Do Not Track“ signals and may make changes to our policy. You may
            adjust your browser or operating system settings to limit this tracking or to decline cookies, but by doing so, you may not be able to use certain features on the
            Services or take full advantage of all of our offerings. Check the “Help” menu of your browser or operating system to learn how to adjust your tracking settings or
            cookie preferences. To learn more about the use of cookies or other technologies to deliver more relevant advertising and your choices about not having this information
            used by certain Service Providers (defined below), please click <a href="http://www.networkadvertising.org/choices/">here</a>. On your mobile device, you can adjust
            your privacy and advertising settings to limit your tracking for advertising or control whether you receive more relevant advertising. Note that our systems may not
            recognize Do Not Track headers or requests from some or all browsers.
          </p>
          <h1 id="contact">How to Contact Us</h1>
          <p>
            If you have any questions or concerns about this Privacy Policy or its implementation, you may contact us at{' '}
            <a href="mailto: mail@worthycause.com">mail@worthycause.com</a>. If you believe your information is wrong, we strive to give you ways to update it quickly or to delete
            it unless we have to keep that information for legitimate business or legal purposes. When updating your personal information, we may ask you to verify your identity
            before we can act on your request. We may reject requests that are unreasonably repetitive, require disproportionate technical effort (for example, developing a new
            system or fundamentally changing an existing practice), risk the privacy of others, or would be extremely impractical (for instance, requests for information residing
            on backup tapes).{' '}
          </p>
          <h2>Changes to this Privacy Policy </h2>
          <p>
            We may update this Privacy Policy from time to time, and so you should review this Policy periodically. Your continued use of any Services constitutes your acceptance
            of any such changes.{' '}
          </p>
          <h2>Last Updated: April 5, 2022</h2>
        </div>
        <div className="sidebar">
          <a href="#privacy">Privacy Policy</a>
          <a href="#contact">Contact</a>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
