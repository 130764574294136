import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Button } from '../common';
import { localStorage } from '../../lib/storage';

import './styles/forVolunteerStyles.css';

import IllustrationEight from './assets/illustration-8.svg';
import IllustrationNine from './assets/illustration-9.svg';
import IllustrationTen from './assets/illustration-10.svg';
import IllustrationEleven from './assets/illustration-11.png';
import CheckIcon from './assets/check.svg';
import StarIcon from './assets/star.svg';
import ExperienceImage from './assets/experience.png';

function ForVolunteersPage({ history }) {
  const moveTo = (regPathname, unregPathname, action) => {
    localStorage.get('userID') ? history.push({ pathname: regPathname }) : history.push({ pathname: unregPathname, state: { action } });
  };

  return (
    <div className="forVolunteersPage">
      <div className="introSection">
        <div className="wrapperContent">
          <div className="content">
            <div>
              <h1 className="mbottom30">Your favourite charity needs your help</h1>
              <p>Charities are burdened with daily tasks that take time away from serving their cause.</p>
            </div>
            <div>
              <h1>Join a charity network and offer your skills</h1>
              <p className="mbottom24">By joining a charity network, you’re raising your hand to offer them help.</p>
              <p className="mbottom30">Charities will also reach out when they need your skills.</p>
              <Link to={{ pathname: localStorage.get('userID') ? '/charities' : '/sign-up' }}>
                <Button label="Join Now" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="createProfileSection">
        <div className="wrapperContent">
          <div className="background" />
          <div className="content">
            <div>
              <p className="small">Volunteering on your time</p>
              <div className="line" />
              <h1 className="mbottom30">Choose how and when you’ll help out.</h1>
              <p className="mbottom32">Find opportunities that match your skillset and the causes you care about.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="howItWorksSection">
        <div className="wrapperContent">
          <div className="content">
            <p className="small">Build your profile with a purpose</p>
            <h1>How it works</h1>
            <div className="contentHowItWorks">
              <div>
                <img src={IllustrationEight} alt="Illustration" />
                <h2>Add skills to{'\n'}your profile</h2>
                <p>Let Charities know what skills and abilities you have to offer.</p>
              </div>
              <div>
                <img src={IllustrationNine} alt="Illustration" />
                <h2>Complete tasks for your Charities</h2>
                <p>Search for opportunities or respond to requests from charities.</p>
              </div>
              <div>
                <img src={IllustrationTen} alt="Illustration" />
                <h2>Time track your contribution to build your profile</h2>
                <p>Submit your time contributions to the Charity for approval and recieve a star rating.</p>
              </div>
            </div>
            <Button className="register-btn" label="Register Now" onClick={() => moveTo(`/profile/${localStorage.get('userID')}`, '/sign-up')} />
          </div>
        </div>
      </div>
      <div className="verifiedRecordSection">
        <div className="wrapperContent">
          <div className="content">
            <div>
              <img className="mbottom8" src={StarIcon} alt="Star" />
              <h1 className="mbottom40">A verified record of your contributions.</h1>
              <div className="point">
                <img src={CheckIcon} alt="Star" />
                <p>Time tracking & timesheets</p>
              </div>
              <div className="point">
                <img src={CheckIcon} alt="Star" />
                <p>Charity verified contributions</p>
              </div>
              <div className="point">
                <img src={CheckIcon} alt="Star" />
                <p>Export your transcript in PDF</p>
              </div>
              <p className="mtop40">Use your transcript for future employment or volunteering opportunities.</p>
            </div>
          </div>
          <div className="img-container">
            <img src={ExperienceImage} alt="Experience" />
          </div>
        </div>
      </div>
      <div className="searchCharitiesSection">
        <div className="wrapperContent">
          <div className="img-container">
            <img src={IllustrationEleven} alt="Illustration" />
          </div>
          <div className="content">
            <h1>“Worthy has given me the opportunity to combine my passion, skillset & greater purpose”</h1>
            <p>Alex Richardson, Graphic Designer</p>
            <Link to={{ pathname: '/charities' }}>
              <Button label="Search Charities" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(ForVolunteersPage);
