import React, { useState, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import _ from 'lodash';
import Modal from 'components/common/Modal/view';
import { Button, LoadingOverlay } from 'components/common';
import CloseIcon from 'assets/svg/close.svg';

// Mutations
import updateUserMutation from '../../../../mutations/user/updateUser';

// Styles
import '../styles/profileModals.scss';

export const ModalAddAbout = ({ open, userByID, onClose }) => {
  const [bio, setBio] = useState(userByID.about);
  const [isLoading, setIsLoading] = useState(false);
  // Mutations
  const [updateUser] = useMutation(updateUserMutation);

  const handleUpdateBio = useCallback(async () => {
    setIsLoading(true);
    const updatedData = await updateUser({
      variables: {
        id: _.get(userByID, '_id'),
        user: {
          about: bio,
        },
      },
    });
    setIsLoading(false);
    onClose();
  }, [bio]);

  const title = `${userByID.about ? 'Edit' : 'Add'} Bio`;

  return (
    <Modal open={open} onClose={onClose} className="addBioContainer">
      {isLoading && <LoadingOverlay style={{ opacity: 0.8 }} />}
      <h2 className="title mbottom10 mtop0">{title}</h2>
      <div className="closeIcon" onClick={onClose}>
        <img src={CloseIcon} alt="Close Icon" />
      </div>
      <div className="flexCenter">
        <textarea
          className="bioEditor"
          id="bio"
          name="bio"
          placeholder="Write a short description to encourage charities to work with you."
          onChange={e => setBio(e.target.value)}
          value={bio}
        />
        <Button
          label={title}
          type="button"
          labelStyle={{
            paddingLeft: 30,
            paddingRight: 30,
          }}
          onClick={handleUpdateBio}
        />
      </div>
    </Modal>
  );
};
