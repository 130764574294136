import React from 'react';
import { withRouter } from 'react-router';
import { localStorage } from '../../../../lib/storage';

// Styles
import './styles/footerStyles.css';

function Footer(props) {
  const onClickRegisterCharity = () => {
    if (!localStorage.get('userID')) {
      props.history.push({ pathname: '/sign-up', state: { action: 'registerCharity' } });
    } else {
      props.history.push({ pathname: '/charity/register/details' });
    }
  };

  return (
    <div className="footerContainer">
      <div className="leftContainer">
        <a href="/">
          <img src="/logo-registered-white.svg" alt="Worthy logo" className="logo" />
        </a>
        <p className="copyright">© Worthy Network Inc.</p>
      </div>
      <div className="centreContainer">
        <div className="yourAccountContainer">
          <h3>Your Account</h3>
          {localStorage.get('userID') ? (
            <div className="linksContainer">
              <a href={`/profile/${localStorage.get('userID')}`}>Your Profile</a>
              <a href="/dashboard/task-manager">Task Manager</a>
              <a href="/dashboard/inbox">Messages</a>
              <a href="/contact">Help</a>
            </div>
          ) : (
            <div className="linksContainer">
              <a href="/sign-up">Sign Up</a>
              <a href="/login">Log in</a>
              <a href="/contact">Help</a>
            </div>
          )}
        </div>
        <div className="aboutContainer">
          <h3>About</h3>
          <div className="linksContainer">
            <a href="/for-volunteers">For Volunteers</a>
            <a href="/for-charities">For Charities</a>
            <a onClick={onClickRegisterCharity}>Register a Charity</a>
          </div>
        </div>
        <div className="searchContainer">
          <h3>Search</h3>
          {localStorage.get('userID') && <a href="/task/search">Search Tasks</a>}
          <a href="/charities">Search Charities</a>
        </div>
      </div>
      <div className="termsContainer">
        <div className="socialMediaContainer">
          <a href="https://www.twitter.com" target="_blank" rel="noreferrer">
            <img src="/icons/socials/twitter-white.svg" alt="Twitter" />
          </a>
          <a href="https://www.instagram.com" target="_blank" rel="noreferrer">
            <img src="/icons/socials/instagram-white.svg" alt="Instagram" />
          </a>
          <a href="https://www.tiktok.com" target="_blank" rel="noreferrer">
            <img src="/icons/socials/tiktok-white.svg" alt="TikTok" />
          </a>
        </div>
        <a href="/terms">Terms of Service</a>
        <a href="/terms-use">Terms of Use</a>
        <a href="/privacy">Privacy Policy</a>
      </div>
    </div>
  );
}

export default withRouter(Footer);
