/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Spinner from 'react-spinkit';
import { useLazyQuery } from '@apollo/client';
import _ from 'lodash';
import { MembersList } from 'components/common/MembersList/view';
import Input from 'components/common/Input';
import Popover from 'components/common/Popover/view';
import fetchUserByKeyword from 'queries/user/fetchUserByKeyword';
import SearchIcon from 'assets/svg/search.svg';
import './styles.scss';

export const CharityManagerSearch = ({ apolloClient, onUserClicked, selectedUsers, displayAsPopover = true, shouldSelectUsers = false }) => {
  const textInput = useRef(null);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);

  const [getUsers, { data }] = useLazyQuery(fetchUserByKeyword, {
    onCompleted: () => setLoading(false),
    client: apolloClient,
  });
  const searchFunc = useMemo(() => _.debounce(getUsers, 1000), [getUsers]);

  useEffect(() => {
    if (!data) return;
    setUsers(data.usersByKeyword);
  }, [data]);

  useEffect(() => {
    if (!search) {
      setUsers([]);
      setLoading(false);
      searchFunc.cancel();
      return;
    }
    searchFunc({
      variables: {
        keyword: search,
      },
    });
    setLoading(true);
  }, [search]);

  useEffect(() => {
    return searchFunc.cancel;
  }, []);

  const resetState = () => {
    setUsers([]);
    setSearch('');
  };

  const renderMembersList = () => {
    return (
      <div className="membersList flexColumn">
        {loading && <Spinner fadeIn="none" className="mLeftAuto mRightAuto" />}
        {!loading && users.length === 0 && <div className="bold mleft32 mright32">No matching volunteers. Try searching for a broader skillset!</div>}
        <MembersList
          users={users}
          selectedUsers={selectedUsers}
          shouldSelectUsers={shouldSelectUsers}
          onClick={user => {
            // If we are not showing right icons it means we are redirecting to the users profile and we should clear the state
            if (!shouldSelectUsers) resetState();
            if (onUserClicked) onUserClicked(user);
          }}
        />
      </div>
    );
  };

  const renderUsers = () => {
    if (displayAsPopover) {
      return (
        <Popover open anchorEl={textInput.current} className="searchPopover" useLayerForClickAway={false} handleRequestClose={resetState}>
          {renderMembersList()}
        </Popover>
      );
    }
    return renderMembersList();
  };

  return (
    <>
      <label className="charityManagerSearch">
        <img src={SearchIcon} alt="search" />
        <Input id="searchInput" placeholder="Search for volunteers or skillset" ref={textInput} value={search} onChange={setSearch} />
      </label>
      {(search || loading || users.length > 0) && renderUsers()}
    </>
  );
};
