import React, { useState } from 'react';
import _ from 'lodash';
import { useMutation } from '@apollo/client';
import { FlatButton } from 'components/common/FlatButton/view';
import { ModalBase } from 'components/common/ModalBase/view';
import { SkillsList } from 'components/common/SkillsList/view';
import { getSkillMacros } from 'lib/utils';
import createNotificationMutation from '../../../../mutations/notification/createNotification';
import './styles.scss';

export const ApplyModal = ({ userId, task, onClose, onApply }) => {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [createNotification] = useMutation(createNotificationMutation);

  const apply = async () => {
    setLoading(true);
    await onApply();
    const notification = {
      _user_id: [_.get(task, '_user_id')],
      _action_user_id: userId,
      _task_id: _.get(task, '_id'),
      isRead: false,
      isVolunteer: false,
      description: _.get(task, 'frequency') === 1 ? 'applied to your task' : 'applied to your ongoing opportunity',
    };
    await createNotification({
      variables: {
        notification,
      },
    });
    setOpen(false);
  };

  return (
    <ModalBase open={open} className="applyModal" onClose={onClose}>
      <h1 className="fontPoppins textBlack textCenter mtop0 mbottom24">Apply for this opportunity</h1>
      <div className="divider mbottom24" />
      <div className="innerContainer">
        <div className="flexRow alignCenter">
          <img className="charityLogo mright8" alt="Charity logo" src={task.Charity.picture} />
          <div className="textBlack">{task.Charity.name}</div>
        </div>
        <h2 className="textBlack">{task.name}</h2>
        <SkillsList skills={task.TaskSkills} className="mbottom56" macros={getSkillMacros(task.TaskSkills)} />
      </div>
      <FlatButton filled label="Apply now" className="mLeftAuto mRightAuto applyBtn" onClick={apply} disabled={loading} />
    </ModalBase>
  );
};
