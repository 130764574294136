import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import Linkify from 'react-linkify';
import { withRouter } from 'react-router';
import { localStorage } from '../../lib/storage';
import { utils } from '../../lib/utils';
import { charityUtils } from '../../lib/charity';
import { userUtils } from '../../lib/user';
import { Input, Image, MembersModal } from '../common';

// Queries
import searchMessages from '../../queries/messages/searchMessages';
import fetchUserById from '../../queries/user/fetchUserById';
import fetchCharityFollowersByMacro from '../../queries/charity/fetchCharityFollowersByMacro';
import fetchCharityById from '../../queries/charity/fetchCharityById';

// Mutations
import createMessageMutation from '../../mutations/messages/createMessage';
import updateUnreadMessagesDataMutation from '../../mutations/user/updateUnreadMessagesData';

// Styles
import './styles/messagesComponentStyles.scss';

function MessagesComponent(props) {
  const [messageToSend, setMessageToSend] = useState('');
  const [isScrolled, setIsScrolled] = useState(false);
  const [membersInChat, setMembersInChat] = useState(0);
  const [chatTitle, setChatTitle] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const [messagesToDisplay, setMessagesToDisplay] = useState([]);
  const [filterDate, setFilterDate] = useState(0);

  const anchorPointRef = useRef(null);
  const scrollViewRef = useRef(null);

  // Mutations
  const [createMessage] = useMutation(createMessageMutation);
  const [updateUnreadMessagesData] = useMutation(updateUnreadMessagesDataMutation);

  const _current_user_id = localStorage.get('userID');
  const searchMessagesVariables = {};
  const formOrGroupInfo = {};

  // set the variables for search messages graphql query
  searchMessagesVariables._user_id = _current_user_id;
  if (props.macro) {
    searchMessagesVariables._macro_id = props.macro;
    searchMessagesVariables._charity_id = props.charity;
    formOrGroupInfo.macroId = props.macro;
    formOrGroupInfo.charityId = props.charity;
  }
  if (props._to_user_id) searchMessagesVariables._to_user_id = props._to_user_id;
  if (props._group_id) {
    searchMessagesVariables._group_id = props._group_id;
    formOrGroupInfo.groupId = props._group_id;
  }
  const macroData = _.find(props.macrosData, macro => macro._id === props.macro);
  const groupData = _.find(props.groupData, group => group._id === props._group_id);

  // Fetch user details for direct messages
  const userByIdResults = useQuery(fetchUserById, {
    variables: {
      id: _.get(props, '_to_user_id'),
    },
  });
  const userToData = _.get(userByIdResults, 'data.userByID');

  // Fetch user details for the connected user
  const currentUserIdResults = useQuery(fetchUserById, {
    variables: {
      id: _current_user_id,
    },
  });
  const currentUserData = _.get(currentUserIdResults, 'data.userByID');
  const isCharityManager = userUtils.isCharityManager(currentUserData);

  // fetch the messages
  const [searchMessagesLazy, searchMessagesLazyResults] = useLazyQuery(searchMessages);
  const searchMessagesResults = useQuery(searchMessages, {
    variables: {
      search: {
        ...searchMessagesVariables,
        direction: 1,
        filter_date: filterDate,
      },
    },
    fetchPolicy: 'network-only',
    pollInterval: 1600,
  });
  const fetchMessagesLazy = _.get(searchMessagesLazyResults, 'data.searchMessages', { messages: [], totalCount: 0 });
  const fetchMessages = _.get(searchMessagesResults, 'data.searchMessages');

  const fetchCharityByIdResults = useQuery(fetchCharityById, {
    variables: {
      _id: props.charity,
    },
    skip: !props.charity,
  });
  const charityByIdData = _.get(fetchCharityByIdResults, 'data.charityById');

  // Group messages by date
  const groupedMessagesByDate = utils.groupByDates(messagesToDisplay, 'created', 'dddd Do MMMM');

  const focusOnMessageToSendComponent = () => {
    const messageToSendComponent = document.getElementById('messageToSend');
    if (messageToSendComponent) messageToSendComponent.focus();
  };

  const getPlaceholderText = () => {
    // Function to define the placeholder text for the message text box
    // Macro skill: Message {macro skill name}
    // Private message: Message {firstname} {lastname}
    // Group message: Message {group name}
    const channelName = getChannelNameContext();
    if (props.macro || props._group_id) return `Message ${channelName}`;
    return `Message ${_.get(userToData, 'firstname')} ${_.get(userToData, 'lastname')}`;
  };

  const onClickSendMessage = async event => {
    if (event) event.preventDefault();
    if (_.size(messageToSend) <= 0) return;
    const messageParams = {
      type: 1, // IN APP
      _from_user_id: _current_user_id,
      body: messageToSend,
      context: {},
    };
    if (props._to_user_id) messageParams._to_user_id = props._to_user_id;
    if (props.macro) {
      messageParams.context._macro_id = props.macro;
      messageParams.context._charity_id = props.charity;
    }
    if (props._group_id) messageParams.context._group_id = props._group_id;

    await createMessage({
      variables: {
        message: messageParams,
      },
    });
    setMessageToSend('');
  };

  const scrollToBottom = () => {
    const messageTextBoxComponent = document.getElementById('anchorPoint');
    if (messageTextBoxComponent) {
      setTimeout(() => {
        messageTextBoxComponent.scrollIntoView(false);
        setTimeout(() => setIsScrolled(true), 100);
      }, 100);
    }
  };

  const handleScroll = async e => {
    const top = e.target.scrollHeight + e.target.scrollTop === e.target.clientHeight;
    const bottom = e.target.scrollTop === 0;
    if (top) {
      console.log('Top');
      const limit = totalCount - messagesToDisplay.length;
      if (limit) {
        await searchMessagesLazy({
          variables: { search: { ...searchMessagesVariables, direction: -1, filter_date: messagesToDisplay[0].created } },
          fetchPolicy: 'network-only',
        });
      }
    }
    if (bottom) {
      updateUnreadMessagesData({
        variables: {
          id: _current_user_id,
          formOrGroupInfo,
        },
      });
    }
  };

  useEffect(() => {
    // When selecting the forum or chat group
    (async () => {
      console.log('Macro, Group changed');
      setMessagesToDisplay([]);
      setIsScrolled(false);
      focusOnMessageToSendComponent();
      await searchMessagesLazy({ variables: { search: { ...searchMessagesVariables, skip: 0, limit: 20 } }, fetchPolicy: 'network-only' });
    })();
  }, [props.macro, props._group_id]);

  useEffect(() => {
    console.log(fetchMessages);
    if (fetchMessages && fetchMessages?.messages?.length > 0) {
      console.log('fetch the message periodically: ', fetchMessages);
      const newMessages = fetchMessages.messages.reverse();
      setMessagesToDisplay([...messagesToDisplay, ...newMessages]);
      setTotalCount(_.get(fetchMessages, 'totalCount'));
    }
  }, [fetchMessages]);

  useEffect(() => {
    if (fetchMessagesLazy?.messages?.length > 0) {
      console.log('fetch lazy messages: ', fetchMessagesLazy);
      if (fetchMessagesLazy.status === 1) {
        // response of reading the messages when selecting group or forum first
        setMessagesToDisplay([...fetchMessagesLazy.messages].reverse());
      } else if (fetchMessagesLazy.status === -1) {
        console.log('this is the response when scrolling top');
        // response of reading more messages when scrolling the top
        const newMessages = fetchMessagesLazy.messages.reverse();
        setMessagesToDisplay([...newMessages, ...messagesToDisplay]);
      }
      setTotalCount(_.get(fetchMessagesLazy, 'totalCount'));
    }
  }, [fetchMessagesLazy]);

  useEffect(() => {
    console.log('messages to display changed: ', messagesToDisplay);
    const lastMessage = messagesToDisplay.length ? messagesToDisplay.at(-1) : null;
    setFilterDate(lastMessage ? lastMessage.created : 0);
    if (scrollViewRef.current) {
      const bottom = scrollViewRef.current.scrollTop === 0;
      if (bottom) {
        updateUnreadMessagesData({
          variables: {
            id: _current_user_id,
            formOrGroupInfo,
          },
        });
      }
    }
  }, [messagesToDisplay]);

  const renderComponentDecorator = (href, text, key) => {
    return (
      <a href={href} key={key} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    );
  };

  const renderTextMessage = message => {
    const isSentByYou = localStorage.get('userID') === message._from_user_id;
    const isSentByCharityAdmin = charityUtils.isCharityAdmin(_.get(message, 'context.Charity'), message._from_user_id);

    return (
      <div key={message._id} className="messageBox">
        <Image source={_.get(message, 'UserFrom.picture')} alt="User profile picture" className="picture" />
        <div className="messageBoxContent">
          <p className="nameContent">
            {_.get(message, 'UserFrom.firstname')} {_.get(message, 'UserFrom.lastname')}
            {isSentByYou ? ' (you)' : ''}
            {isSentByCharityAdmin ? <span> · {_.get(message, 'context.Charity.name')}</span> : ''}
          </p>
          <p className="body">{message.body}</p>
        </div>
      </div>
    );
  };

  const renderMessages = messages => {
    return _.map(messages, message => {
      // Check whether or not it is a System Message
      // if (!props.hideAutomatedMessages && message.type === 2) return renderSystemMessage(message);

      // If it's not either a System Message or a Status Update then render the proper message
      return renderTextMessage(message);
    });
  };

  const getChannelNameContext = () => {
    if (props.macro) return _.get(macroData, 'name');
    if (props._group_id) return _.get(groupData, 'name');
    return '';
  };

  const getGenericMessageTitle = ({ channelName }) => {
    if (isCharityManager) return `Send your first message to your ${channelName} Network`;
    if (_.get(charityByIdData, 'name')) return `Say hi 👋 to ${charityByIdData.name}'s ${channelName} Network`;
    return `Say hi 👋 to ${channelName}`;
  };

  const getGenericMessageContent = ({ channelName }) => {
    if (isCharityManager) return <p>Volunteers who connect with your charity and have {channelName} skills will appear in this channel.</p>;
    return <p>Introduce yourself and describe what you can do to help.</p>;
  };

  const renderMessagesContainer = () => {
    const channelName = getChannelNameContext();
    const genericMessageTitle = getGenericMessageTitle({ channelName });
    const genericMessageContent = getGenericMessageContent({ channelName });
    return (
      <div className="messagesContentContainer">
        {_.size(groupedMessagesByDate) > 0 ? (
          <div className={`messagesContent ${isScrolled ? 'isScrolled' : ''}`} ref={scrollViewRef} onScroll={handleScroll}>
            {_.map(groupedMessagesByDate, date => {
              return (
                <div className="dateContainer" key={date.day}>
                  <div className="dateTitleContainer">
                    <p className="dateTitle">{date.day}</p>
                  </div>
                  <Linkify componentDecorator={renderComponentDecorator}>{renderMessages(date.group)}</Linkify>
                </div>
              );
            })}
            <div ref={anchorPointRef} id="anchorPoint">
              Last message
            </div>
          </div>
        ) : (
          renderGenericMessagesContainer({
            title: genericMessageTitle,
            content: genericMessageContent,
            imgURL: '/illustrations/noMessagesInChannel2.svg',
          })
        )}
        <div className="messageTextBox">
          <Input
            placeholder={getPlaceholderText()}
            value={messageToSend}
            underlineShow={false}
            multiLine
            rows={1}
            rowsMax={3}
            hasNoBorder
            style={{ color: '#6E7581' }}
            id="messageToSend"
            onChange={value => setMessageToSend(value)}
            onFocus={() => {
              if (props.onFocus) props.onFocus();
            }}
            onKeyDown={e => {
              if (e.keyCode === 13 && !e.shiftKey) {
                onClickSendMessage(e);
              }
            }}
          />
          <div className="actionsContainer">
            <a onClick={onClickSendMessage} className={`sendMessageButton ${_.size(messageToSend) <= 0 ? 'disabled' : ''}`}>
              <img src="/icons/send.svg" alt="Send message button" />
            </a>
          </div>
        </div>
      </div>
    );
  };

  const renderGenericMessagesContainer = ({ title, content, imgURL }) => {
    return (
      <div className="genericMessagesContainer">
        <h2>{title}</h2>
        {content}
        <img src={imgURL} alt="No messages" />
      </div>
    );
  };

  const renderMessagesHeader = ({ title, peopleInChat }) => {
    return (
      <div className="messageHeader">
        <h2>{title}</h2>
        <a
          className="messageContextContainer"
          onClick={() => {
            setChatTitle(title);
            setMembersInChat(peopleInChat);
          }}
        >
          <img src="/icons/profileIcon.svg" alt="Profile Icon" />
          <span>{_.size(peopleInChat)}</span>
        </a>
      </div>
    );
  };

  const renderDirectMessages = () => {
    let peopleInChat = [];
    if (props._group_id) {
      // Include everyone who are in the group
      peopleInChat = _.get(groupData, 'Users');
    } else {
      // Direct messages are always messages between two people
      peopleInChat = [userToData, currentUserData];
    }
    // Check whether it's a group message or a direct message
    const title = props._group_id ? `${_.get(groupData, 'name')}` : `${_.get(userToData, 'firstname', '')} ${_.get(userToData, 'lastname', '')}`;
    return (
      <>
        {renderMessagesHeader({ title, peopleInChat })}
        {renderMessagesContainer()}
      </>
    );
  };

  const renderChannelMessages = () => {
    return (
      <Query
        query={fetchCharityFollowersByMacro}
        variables={{
          _id: props.charity,
          _macro_id: props.macro,
        }}
      >
        {({ loading, error, data }) => {
          if (loading || error) return null;
          const followersByMacro = _.clone(_.get(data, 'charityFollowersByMacro'));
          // We should add the charity manager that is connected to the followers by macro. That's because we need to display
          if (currentUserData) followersByMacro.push(currentUserData);
          return (
            <>
              {renderMessagesHeader({ title: _.get(macroData, 'name'), peopleInChat: followersByMacro })}
              {_.size(followersByMacro) > 1
                ? renderMessagesContainer()
                : renderGenericMessagesContainer({
                    title: 'No one here yet!',
                    content: (
                      <p>
                        Invite volunteers to connet with your charity. Volunteer with <span className="macroName">{_.get(macroData, 'name')}</span> skills will appear in this
                        channel.
                      </p>
                    ),
                    imgURL: '/illustrations/noMessagesInChannel.svg',
                  })}
            </>
          );
        }}
      </Query>
    );
  };

  return (
    <div className="messagesComponent">
      {props.macro && props.charity ? renderChannelMessages() : renderDirectMessages()}
      {_.size(membersInChat) > 0 && chatTitle && <MembersModal users={membersInChat} title={chatTitle} onClose={() => setMembersInChat(0)} />}
    </div>
  );
}

export default withRouter(MessagesComponent);
