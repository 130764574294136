import gql from 'graphql-tag';

export default gql`
  query FetchMacros {
    macros {
      _id
      name
      category
    }
  }
`;
