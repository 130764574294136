import React, { Component } from 'react';
import { graphql } from '@apollo/client/react/hoc';
import { withRouter } from 'react-router';
import _ from 'lodash';
import { globalVariables } from '../../../lib/globalVariables';
import {
    Input,
    FileUpload,
    Button,
    LoadingOverlay
} from '../../common';

// Queries
import fetchCharityById from '../../../queries/charity/fetchCharityById';
import fetchAmbassadorById from '../../../queries/ambassador/fetchAmbassadorById';

// Mutations
import createAmbassadorMutation from '../../../mutations/ambassador/createAmbassador';
import updateAmbassadorMutation from '../../../mutations/ambassador/updateAmbassador';

// Styles
import './styles/ambassadorCreateStyles.css';

class CreateAmbassador extends Component {
    constructor() {
        super();

        this.state = {
            ambassador: {
                fullname: '',
                picture: '',
                description: '',
                bio: '',
                social: {
                    twitter: '',
                    instagram: ''
                },
                quote: {
                    content: '',
                    author: ''
                }
            },
            error: '',
            loading: false
        };

        this.onUpdateAmbassadorInput = this.onUpdateAmbassadorInput.bind(this);
        this.onUpdateAmbassadorSocialInput = this.onUpdateAmbassadorSocialInput.bind(this);
        this.onUpdateAmbassadorQuoteInput = this.onUpdateAmbassadorQuoteInput.bind(this);
        this.renderHelperArea = this.renderHelperArea.bind(this);
        this.onSubmitForm = this.onSubmitForm.bind(this);
    }

    componentWillReceiveProps(newProps) {
        if (_.size(newProps.ambassadorData.ambassadorById) > 0) {
            const { ambassadorById } = newProps.ambassadorData;
            this.setState({
                ambassador: {
                    ...this.state.ambassador,
                    fullname: ambassadorById.fullname,
                    picture: ambassadorById.picture,
                    description: ambassadorById.description,
                    bio: ambassadorById.bio,
                    social: {
                        ...this.state.ambassador.social,
                        twitter: ambassadorById.social.twitter,
                        instagram: ambassadorById.social.instagram
                    },
                    quote: {
                        ...this.state.ambassador.quote,
                        content: ambassadorById.quote.content,
                        author: ambassadorById.quote.author
                    }
                }
            });
        }
    }

    onUpdateAmbassadorInput(value, fieldName) {
        this.setState({
            ambassador: {
                ...this.state.ambassador,
                [fieldName]: value
            }
        });
    }

    onUpdateAmbassadorSocialInput(value, fieldName) {
        this.setState({
            ambassador: {
                ...this.state.ambassador,
                social: {
                    ...this.state.ambassador.social,
                    [fieldName]: value
                }
            }
        });
    }

    onUpdateAmbassadorQuoteInput(value, fieldName) {
        this.setState({
            ambassador: {
                ...this.state.ambassador,
                quote: {
                    ...this.state.ambassador.quote,
                    [fieldName]: value
                }
            }
        });
    }

    onSubmitForm(event) {
        if (event) event.preventDefault();

        this.setState({ loading: true });

        // It should check if the ambassador Id is coming in the router state.
        //   Yes -> It should update the current ambassador
        //   No  -> It should create a new ambassador and assign him to the charity
        if (_.has(this.props.location, 'state.ambassadorId')) {
            this.props.updateAmbassador(this.props.location.state.ambassadorId, this.state.ambassador)
                .then(() => {
                    this.setState({ loading: false });
                    this.props.history.goBack();
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                        error
                    });
                });
        } else {
            this.props.createAmbassador(this.state.ambassador, this.props.location.state.charity_id)
                .then(() => {
                    this.setState({ loading: false });
                    this.props.history.goBack();
                })
                .catch((error) => {
                    this.setState({
                        loading: false,
                        error
                    });
                });
        }
    }

    renderHelperArea(title, countState, maxCount) {
        return (
            <div className="bioHelper">
                <h4>{title}</h4>
                <h4 className="count">{`${countState}/${maxCount}`}</h4>
            </div>
        );
    }

    render() {
        if (!_.get(this.props.location, 'state.charity_id')) {
            // If there's not Charity id to assign to it should redirect to the homepage
            this.props.history.replace({ pathname: '/' });
        }
        return (
          <div className="createAmbassadorContainer">
            <div className="contentContainer col-xs-12">
              {this.state.loading && <LoadingOverlay style={{ opacity: 0.8 }} />}
              {this.state.error && (
                <div className="errorMessage">
                  <p className="error">{this.state.error}</p>
                </div>
              )}
              <div className="headerCreateAmbassador textCenter">
                <h1>Add Ambassador</h1>
                <p>Adding an ambassador giver you more exposure and say more things here about the benefits of adding one</p>
              </div>
              <div className="createAmbassadorContent col-xs-12 p0">
                <div className="defaultMainRootContentContainer">
                  <form onSubmit={this.onSubmitForm}>
                    <div className="col-xs-12 col-sm-5 leftContainer">
                      <h3>Ambassador details</h3>
                      <div className="uploadPictureContainer">
                        <FileUpload
                          onFinishUpload={filename =>
                            this.onUpdateAmbassadorInput(`${filename.replace('https://kudos-files.s3.ap-southeast-2.amazonaws.com/', '').split('?')[0]}`, 'picture')
                          }
                          previewImage={this.state.ambassador.picture}
                          label="PROFILE PIC"
                          instructions="Upload 800X800 pixels for best quality"
                        />
                      </div>
                      <Input
                        name="fullname"
                        placeholder="Ambassador Full Name"
                        helpText="Name and last name"
                        type="text"
                        value={this.state.ambassador.fullname}
                        onChange={value => this.onUpdateAmbassadorInput(value, 'fullname')}
                      />
                      <Input
                        name="description"
                        placeholder="Ambassador Description"
                        helperArea={() => this.renderHelperArea('Explain what the ambassador does in a short sentence', this.state.ambassador.description.length, 200)}
                        type="text"
                        value={this.state.ambassador.description}
                        onChange={value => {
                          // Only allow 400 charachters
                          if (value.length <= 200) this.onUpdateAmbassadorInput(value, 'description');
                        }}
                        rowsMax={5}
                        multiLine
                      />
                      <Input
                        name="twitter"
                        placeholder="Twitter Profile"
                        helpText="Add twitter url"
                        type="text"
                        value={this.state.ambassador.social.twitter}
                        onChange={value => this.onUpdateAmbassadorSocialInput(value, 'twitter')}
                      />
                      <Input
                        name="instagram"
                        placeholder="Instagram Profile"
                        helpText="Add Instagram url"
                        type="text"
                        value={this.state.ambassador.social.instagram}
                        onChange={value => this.onUpdateAmbassadorSocialInput(value, 'instagram')}
                      />
                    </div>
                    <div className="col-xs-12 col-sm-6 rightContainer">
                      <div className="rightContainerInternal">
                        <h3>Bio</h3>
                        <Input
                          name="bio"
                          placeholder="Ambassador Bio"
                          helperArea={() => this.renderHelperArea('Add bio and explain more for the user', this.state.ambassador.bio.length, 400)}
                          type="text"
                          value={this.state.ambassador.bio}
                          onChange={value => {
                            // Only allow 400 charachters
                            if (value.length <= 400) this.onUpdateAmbassadorInput(value, 'bio');
                          }}
                          rowsMax={7}
                          multiLine
                        />
                        <h3 className="mtop40">Featured quote</h3>
                        <Input
                          name="quote"
                          placeholder="Quote"
                          helperArea={() => this.renderHelperArea('Optional quote from the ambassador or someone else', this.state.ambassador.quote.content.length, 25)}
                          type="text"
                          value={this.state.ambassador.quote.content}
                          onChange={value => {
                            // Only allow 25 charachters
                            if (value.length <= 25) this.onUpdateAmbassadorQuoteInput(value, 'content');
                          }}
                        />
                        <Input
                          name="author"
                          placeholder="Author"
                          type="text"
                          value={this.state.ambassador.quote.author}
                          onChange={value => this.onUpdateAmbassadorQuoteInput(value, 'author')}
                        />
                      </div>
                      <div className="buttonsContainer col-xs-12">
                        <Button label="Save" type="submit" className="btnDefault btnSave" disabled={!(this.state.ambassador.fullname && this.state.ambassador.description)} />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        );
    }
}

const ambassadorByIDContainer = graphql(fetchAmbassadorById, {
    name: 'ambassadorData',
    options: ({ location }) => ({
        variables: { _id: _.get(location, 'state.ambassadorId') || null },
    }),
});

const createAmbassadorMutationContainer = graphql(createAmbassadorMutation, {
    props: ({ mutate }) => ({
        createAmbassador: (ambassador, charity_id) => mutate({
            variables: { ambassador, charity_id },
            refetchQueries: [{
                query: fetchCharityById,
                variables: { _id: charity_id },
            }]
        }),
    }),
});

const updateAmbassadorMutationContainer = graphql(updateAmbassadorMutation, {
    props: ({ mutate, ownProps }) => ({
        updateAmbassador: (_id, ambassador) => mutate({
            variables: { _id, ambassador },
            refetchQueries: [{
                query: fetchCharityById,
                variables: { _id: ownProps.location.state.charity_id },
            }]
        }),
    }),
});

export default withRouter(_.flowRight(
    createAmbassadorMutationContainer,
    updateAmbassadorMutationContainer,
    ambassadorByIDContainer
)(CreateAmbassador));
