import gql from 'graphql-tag';

export default gql`
  mutation updateNotification($_id: ID, $user_id: ID, $isMarkAllAsRead: Boolean) {
    updateNotification(_id: $_id, user_id: $user_id isMarkAllAsRead: $isMarkAllAsRead) {
      _id
      User {
        _id
        firstname
        lastname
      }
      ActionUser {
        _id
        firstname
        lastname
      }
      Task {
        _id
        name
        Charity {
          _id
          picture
        }
      }
      isRead
      isVolunteer
      description
      created
    }
  }
`;
