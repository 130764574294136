import React, { useMemo, useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { PDFDownloadLink, PDFViewer, pdf } from '@react-pdf/renderer';
import _ from 'lodash';
import upload from 'superagent';
import { FlatButton } from 'components/common/FlatButton/view';
import CertificationTemplate from './template';
import { localStorage } from '../../../lib/storage';
import { userUtils } from '../../../lib/user';
import { globalVariables } from '../../../lib/globalVariables';

// queries
import fetchUserById from '../../../queries/user/fetchUserById';
import fetchTaskByUserId from '../../../queries/task/fetchTasksByUserId';

// mutation
import updateUserMutation from '../../../mutations/user/updateUser';

// scss
import './index.scss';

const Certification = () => {
  const [loading, setLoading] = useState(true);
  const [rate, setRate] = useState('0');
  const [hours, setHours] = useState(0.0);
  const [certId, setCertId] = useState('');
  const [url, setUrl] = useState('');

  const userByIdResult = useQuery(fetchUserById, {
    variables: {
      id: localStorage.get('userID'),
    },
  });
  const tasksHistoryByUserId = useQuery(fetchTaskByUserId, {
    variables: {
      _user_id: localStorage.get('userID'),
      status: 3,
    },
    fetchPolicy: 'network-only',
  });
  const [updateUser] = useMutation(updateUserMutation);

  const convertTasksToWorkhistories = tasks => {
    const charities = [];
    const workHistoriesData = [];
    let totalHours = 0;
    _.forEach(tasks, task => {
      const charityId = _.get(task, 'Charity._id');
      const taskName = _.get(task, 'name');
      const category = _.get(task, 'TaskSkills[0].Macro.name');
      const date = new Date(task.created).toLocaleDateString('en-US', { year: 'numeric', month: 'short' });

      let hoursData = 0;
      const taskSubmissions = _.get(task, 'TaskSubmissions');
      if (taskSubmissions) {
        hoursData = _.reduce(
          taskSubmissions,
          (hrs, taskSubmission) => {
            const taskTimesheets = _.get(taskSubmission, 'TaskTimesheet');
            if (!taskTimesheets || !_.size(taskTimesheets)) {
              return 0;
            }
            return (
              hrs +
              _.reduce(
                taskTimesheets,
                (subHrs, taskTimesheet) => {
                  return subHrs + taskTimesheet.end - taskTimesheet.start;
                },
                0,
              )
            );
          },
          0,
        );
        hoursData /= 1000 * 3600;
      }
      totalHours += hoursData;

      const index = charities.indexOf(charityId);

      if (index === -1) {
        charities.push(charityId);
        workHistoriesData.push({ charity: _.get(task, 'Charity.name'), works: [{ task: taskName, category, date, hours: hoursData }] });
      } else {
        workHistoriesData[index].works.push({ task: taskName, category, date, hours: hoursData });
      }
    });

    return { workHistoriesData, totalHours };
  };

  const user = useMemo(() => {
    const userData = _.get(userByIdResult, 'data.userByID');
    setRate(userUtils.getAverageStarRating(_.get(userData, 'TaskReviews', [])));
    setCertId(`WO_${_.get(userData, '_id', '')}`);
    return userData;
  }, [userByIdResult]);
  const workHistories = useMemo(() => {
    const tasks = _.get(tasksHistoryByUserId, 'data.tasksByUserID');
    const { workHistoriesData, totalHours } = convertTasksToWorkhistories(tasks);
    setHours(totalHours);
    return workHistoriesData;
  }, [tasksHistoryByUserId]);

  useEffect(() => {
    if (user && workHistories) {
      setLoading(false);
    }
  }, [user, workHistories]);
  useEffect(() => {
    const uploadBlob = async () => {
      if (!loading) {
        const blob = await pdf(<CertificationTemplate user={{ ...user, rate, hours, certId }} workHistories={workHistories} />).toBlob();
        const res = await upload.post(globalVariables.getEnvironmentVariables().uploadURI).attach('uploadToAws', blob, `${certId}.pdf`);
        if (res.statusCode === 200) {
          // await updateUser({
          //   variables: {
          //     id: localStorage.get('userID'),
          //     user: {
          //       certification_file: res.text.split('?')[0],
          //     },
          //   },
          // });
        }
      }
    };

    uploadBlob();
  }, [loading]);

  return (
    <div className="certification">
      {loading ? (
        <p style={{ margin: '0' }}>Fetching Data now ...</p>
      ) : (
        <div className="main">
          <div className="toolbar">
            <PDFDownloadLink document={<CertificationTemplate user={{ ...user, rate, hours, certId }} workHistories={workHistories} />} fileName={`${certId}.pdf`}>
              <FlatButton label="Download as PDF" />
            </PDFDownloadLink>
          </div>
          <PDFViewer showToolbar={false} style={{ width: '100%', height: '100%' }}>
            <CertificationTemplate user={{ ...user, rate, hours, certId }} workHistories={workHistories} />
          </PDFViewer>
        </div>
      )}
    </div>
  );
};

export default Certification;
