import React from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { SkillsList } from 'components/common/SkillsList/view';
import { TASK_FREQUENCY, TASK_TYPE } from 'lib/task';
import { getSkillMacros } from 'lib/utils';
import fetchTasks from 'queries/task/fetchTasks';
import CalendarIcon from 'assets/svg/calendar.svg';
import LocationIcon from 'assets/svg/location.svg';
import './styles.scss';

export const OtherOpportunities = () => {
  const { loading, data } = useQuery(fetchTasks);
  const { tasks = [] } = data || {};
  const amountOfHours = ['Less than 5 hours per week', 'Between 5-10 hours per week', 'Between 10-20 hours per week', 'More than 20 hours per week'];
  const getTaskFrequency = task => {
    if (task.frequency === TASK_FREQUENCY.ONE_OFF) {
      return `${task.oneoff_frequency_config.hours_per_volunteer}hrs per volunteer`;
    }
    return `${amountOfHours[task.ongoing_frequency_config.hours_per_week - 1]}`;
  };

  return (
    <div className="row">
      {tasks.slice(0, 3).map(task => (
        <div className="col-md-4 col-xs-12">
          <div className="opportunityCard">
            <div className="flexRow alignCenter mbottom32">
              <img className="charityLogo  mright16" src={task.Charity.picture} alt="charity logo" />
              <div>
                <Link to={`/task/${task._id}`}>
                  <h3 className="fontPoppins mtop0 mbottom8">{task.name}</h3>
                </Link>
                <div className="textGrey">{task.Charity.name}</div>
              </div>
            </div>
            <SkillsList skills={task.TaskSkills} macros={getSkillMacros(task.TaskSkills)} className="mbottom32" />
            <div className="textGrey flexRow mbottom16">
              <img src={CalendarIcon} alt="calendar" className="mright8" />
              {task.frequency === TASK_FREQUENCY.ONE_OFF ? 'One Off' : 'Ongoing'}
              &nbsp;·&nbsp;
              {getTaskFrequency(task)}
            </div>
            <div className="textGrey flexRow mbottom24">
              <img src={LocationIcon} alt="location" className="mright8" />
              {task.type === TASK_TYPE.REMOTE ? 'Remote' : 'Face to Face'}
            </div>
            <div className="textGrey">{task.description}</div>
          </div>
        </div>
      ))}
    </div>
  );
};
