import React, { useState } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import moment from 'moment';
import { userUtils } from 'lib/user';
import { localStorage } from 'lib/storage';
import StarIcon from './assets/star.svg';
import { FlatButton } from 'components/common/FlatButton/view';
import fetchUserById from 'queries/user/fetchUserById';
import NetworkEmptyIcon from 'assets/svg/network-empty.svg';
import CalendarIcon from './assets/calendar.svg';
import updateUserMutation from '../../../mutations/user/updateUser';
import { VolunteerExperience } from './VolunteerExperience/view';
import { ProfileBio } from './ProfileBio/view';
import { CharityManagerMenu } from './CharityManagerMenu/view';
import { ProfileCauses } from './ProfileCauses/view';
import { ProfileSkills } from './ProfileSkills/view';
import { CharitiesModal } from './CharitiesModal/view';
import { FileUpload, LoadingOverlay, MessageButton } from '../../common';
import './styles.scss';

function Profile({ match }) {
  const currentUserId = localStorage.get('userID');
  const { user_id: userId } = match.params;
  const apolloClient = useApolloClient();
  const [showNetwork, setShowNetwork] = useState(false);
  const [updateUser] = useMutation(updateUserMutation);
  const { data } = useQuery(fetchUserById, {
    variables: {
      id: userId,
    },
  });
  const { data: currentData, loading } = useQuery(fetchUserById, {
    variables: {
      id: currentUserId,
    },
    skip: !currentUserId,
  });

  const onUpdateUser = userUpdate => {
    updateUser({
      variables: {
        id: userId,
        user: userUpdate,
      },
      refetchQueries: [
        {
          query: fetchUserById,
          variables: { id: userId },
        },
      ],
    });
  };

  if (!data || loading) return <LoadingOverlay />;
  const { userByID } = data;
  const currentUser = _.get(currentData, 'userByID');
  const isManager = userUtils.isCharityManager(userByID);
  const currentManager = userUtils.isCharityManager(currentUser);
  const avgStarRating = userUtils.getAverageStarRating(userByID.TaskReviews);
  const sumHours = userUtils.getSumOfHours(userByID.TaskReviews);
  const currentAdmin = userUtils.isAdmin(userId);

  return (
    <div className="profileMockup">
      <div className="profileHead">
        <div className="content">
          <div className="info">
            {currentAdmin ? (
              <FileUpload
                onFinishUpload={filename => onUpdateUser({ picture: `${filename.replace('https://kudos-files.s3.ap-southeast-2.amazonaws.com/', '').split('?')[0]}` })}
                label="YOUR IMAGE"
                previewImage={userByID.picture}
                className="profilePicture"
              />
            ) : (
              <img src={userByID.picture} alt="Avatar" className="profileImg" />
            )}
            <div>
              <h1 className="name">
                {userByID.firstname} {userByID.lastname}
              </h1>
              <p className="desc">{userUtils.getLocationtext(userByID.location, false)}</p>
              <p className="desc">Member since {moment(userByID.created).format('MMMM YYYY')}</p>
              {isManager ? (
                <div className="flexRow">
                  {userByID.CharitiesAdmin.map(({ _id, picture }) => (
                    <img key={_id} src={picture} className="charityLogo mright8" alt="Charity logo" />
                  ))}
                  <h3 className="m0 fontPoppins light">Charity Manger</h3>
                </div>
              ) : (
                <div className="ratings">
                  <div>
                    <img src={StarIcon} alt="Star" />
                    <p>{avgStarRating}</p>
                  </div>
                  <div>
                    <img src={CalendarIcon} alt="Calendar" />
                    <p>{sumHours} hrs</p>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="buttons">
            {!currentAdmin && currentUserId && <MessageButton users={[userByID]} />}
            {currentManager && !isManager && <CharityManagerMenu user={userByID} charityId={currentUser.CharitiesAdmin[0]._id} />}
          </div>
        </div>
      </div>
      <div className="contentContainer">
        <div className="experienceSection">
          {/* eslint-disable-next-line react/no-danger */}
          <ProfileBio user={userByID} />
          {isManager ? (
            <div className="experienceContainer">
              {userByID.CharitiesAdmin.map(({ _id, name, picture, website }) => (
                <div className="charityRole" key={_id}>
                  <img src={picture} alt="Icon" className="charityLogo mright8" />
                  <h3 className="m0 mRightAuto bold fontPoppins">
                    <Link to={`/charity/${_id}`}>{name}</Link>
                  </h3>
                  <h3 className="m0 fontPoppins normal">Charity Manager</h3>
                </div>
              ))}
            </div>
          ) : (
            <VolunteerExperience userId={userByID._id} userName={userByID.firstname} viewerId={_.get(currentUser, '_id')} taskReviews={userByID.TaskReviews} />
          )}
        </div>
        <div className="skillSection">
          {userByID.CharitiesAdmin.map(({ _id, picture, name, description }) => (
            <div className="charityRoleContainer" key={_id}>
              <img src={picture} className="charityLogo mbottom16" alt="Charity logo" />
              <div className="mbottom8">Charity Manager</div>
              <div className="textlightGrey mbottom16">{name}</div>
              <h4 className="charityDescription fontPoppins textBlack normal">{description}</h4>
            </div>
          ))}
          <ProfileSkills user={userByID} updateUser={onUpdateUser} />
          <div className="networksContainer">
            <div className="title">
              <h2>Network</h2>
              {userByID.CharitiesFollowing.length !== 0 && <FlatButton label={`View All (${userByID.CharitiesFollowing.length})`} onClick={() => setShowNetwork(true)} />}
              {showNetwork && <CharitiesModal charities={userByID.CharitiesFollowing} apolloClient={apolloClient} onClose={() => setShowNetwork(false)} />}
            </div>
            <div className="flexContainer">
              {userByID.CharitiesFollowing.map(({ _id, name, picture }) => (
                <Link key={_id} className="flexItem charityContainer" to={`/charity/${_id}`}>
                  <img src={picture} alt="Charity Logo" className="charityLogo" />
                  <h4 className="charityTitle fontPoppins">{name}</h4>
                </Link>
              ))}
              {userByID.CharitiesFollowing.length === 0 && (
                <div className="flexCenter textCenter wFull">
                  <img src={NetworkEmptyIcon} alt="Network Empty Icon" />
                  <div className="emptySubtitle mtop16">Connect with Charities</div>
                  <div className="emptyDescription mtop8 mbottom16">Grow your network and search for charities on Worthy</div>
                  <FlatButton label="Search Charities" href="/charities" />
                </div>
              )}
            </div>
          </div>
          <ProfileCauses user={userByID} />
        </div>
      </div>
    </div>
  );
}

export default withRouter(Profile);
