import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import _ from 'lodash';
import { userUtils } from 'lib/user';
import ModalChangePassword from './modalChangePassword';
import ModalVerifyEmail from './modalVerifyEmail';
import ModalDeleteAccount from './modalDeleteAccount';
import { GoogleInput, Input, Button, Notification, LoadingOverlay, Snackbar } from '../common';

// queries
import fetchUserById from '../../queries/user/fetchUserById';

// mutations
import updateUserMutation from '../../mutations/user/updateUser';
import resendUserConfirmationEmailMutation from '../../mutations/user/resendUserConfirmationEmail';

function AccountPanel({ history }) {
  const [user, setUser] = useState(null);
  const [isCharityAdmin, setIsCharityAdmin] = useState(false);
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [location, setLocation] = useState(null);
  const [locationText, setLocationText] = useState('');
  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState('');
  const [emailNotification, setEmailNotification] = useState('');
  const [loading, setLoading] = useState(false);
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false);
  const [openVerifyEmailModal, setOpenVerifyEmailModal] = useState(false);
  const [openDeleteAccountModal, setOpenDeleteAccountModal] = useState(false);
  const [notificationContent, setNotificationContent] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [formError, setFormError] = useState('');

  const [updateUser] = useMutation(updateUserMutation);
  const [resendUserConfirmationEmail] = useMutation(resendUserConfirmationEmailMutation);
  const { data, refetch } = useQuery(fetchUserById, {
    variables: { id: localStorage.getItem('userID') },
    fetchPolicy: 'cache-and-network',
  });

  const onLocationSelected = value => {
    const locationInfo = {
      street: value.street,
      city: value.city,
      postcode: Number(value.postcode),
      state: value.state,
      country: value.country,
      latitude: value.latitude,
      longitude: value.longitude,
      location: value.location,
    };
    setLocation(locationInfo);
  };
  const onSend = async () => {
    const isEmailChanged = user.email !== email;
    setLoading(true);
    await updateUser({
      variables: {
        id: user._id,
        user: {
          firstname,
          lastname,
          email,
          location,
        },
      },
      refetchQueries: [
        {
          query: fetchUserById,
          variables: { id: localStorage.getItem('userID') },
        },
      ],
    });
    if (isEmailChanged) {
      await resendLink();
      setOpenVerifyEmailModal(true);
      setEmailNotification('Awaiting verification');
    }
    setLoading(false);
    setNotificationContent('User updated successfully');
  };
  const onClosePasswordModal = (success = false) => {
    if (success) {
      setNotificationContent('Password updated successfully');
    }
    setOpenChangePasswordModal(false);
  };
  const resendLink = async () => {
    const userId = localStorage.getItem('userID');
    if (userId) {
      setLoading(true);
      setFormError('');
      try {
        await resendUserConfirmationEmail({
          variables: {
            id: userId,
          },
        });
        setLoading(false);
        setSnackbarOpen(true);
        setSnackbarMessage('Email successfully resent');
      } catch (error) {
        setLoading(false);
        setFormError(error);
      }
    }
  };
  const gotoSignup = () => {
    history.push({ pathname: '/sign-up' });
    window.location.reload();
  };

  const gotoAccess = () => {
    history.push({ pathname: '/dashboard/settings', state: { menu: { id: 'access', title: 'Access & Privilege' } } });
    window.location.reload();
  };

  useEffect(() => {
    const userInfo = _.get(data, 'userByID');
    setIsCharityAdmin(_.size(_.get(userInfo, 'CharitiesOfAdmin', [])));
    if (userInfo) {
      setUser(userInfo);
      setFirstname(_.get(userInfo, 'firstname'));
      setLastname(_.get(userInfo, 'lastname'));
      setLocation({
        street: _.get(userInfo, 'location.street', ''),
        city: _.get(userInfo, 'location.city', ''),
        postcode: Number(_.get(userInfo, 'location.postcode')),
        state: _.get(userInfo, 'location.state'),
        country: _.get(userInfo, 'location.country'),
        latitude: _.get(userInfo, 'location.latitude'),
        longitude: _.get(userInfo, 'location.longitude'),
        location: _.get(userInfo, 'location.location'),
      });
      setLocationText(userUtils.getLocationtext(data.userByID.location));
      setEmail(data.userByID.email);
    }
  }, [data]);

  return (
    <div className="settingsPanel">
      {loading && <LoadingOverlay style={{ opacity: 0.8 }} />}
      <h2>My Account</h2>
      <div className="publicContainer col-xs-12">
        <h3>Public details</h3>
        <div className="col-xs-12">
          <p>Name</p>
          <div className="row">
            <div className="col-sm-6">
              <Input
                name="firstname"
                type="text"
                errorText=""
                value={firstname}
                onChange={value => {
                  setFirstname(value);
                }}
              />
            </div>
            <div className="col-sm-6">
              <Input
                name="lastname"
                type="text"
                errorText=""
                value={lastname}
                onChange={value => {
                  setLastname(value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="col-xs-12">
          <p>Suburb</p>
          <GoogleInput onNewPlace={onLocationSelected} value={locationText} onValueChange={value => setLocationText(value)} />
        </div>
      </div>
      <div className="securityContainer col-xs-12">
        <h3>Security</h3>
        <div className="col-xs-12">
          <p>Email</p>
          <Input
            name="email"
            type="email"
            value={email}
            onChange={value => setEmail(value)}
            errorText={emailValid}
            notificationText={emailNotification}
            onBlur={() => {
              if (userUtils.isValidEmail(email) === false) {
                setEmailValid('Please enter a valid email.');
              } else {
                setEmailValid('');
              }
            }}
          />
        </div>
        <div className="col-xs-12">
          <a onClick={() => setOpenChangePasswordModal(true)}>Change Password</a>
        </div>
      </div>
      <Button label="Save" onClick={onSend} disabled={!(firstname && lastname && locationText && email && userUtils.isValidEmail(email))} />
      <div className="deleteAccount col-xs-12">
        <a onClick={() => setOpenDeleteAccountModal(true)}>Delete Account</a>
      </div>
      {user && <ModalChangePassword open={openChangePasswordModal} onClose={success => onClosePasswordModal(success)} user_id={user._id} />}
      {user && <ModalVerifyEmail open={openVerifyEmailModal} onClose={() => setOpenVerifyEmailModal(false)} email={user.email} onResendLink={resendLink} />}
      {user && (
        <ModalDeleteAccount
          open={openDeleteAccountModal}
          onClose={() => setOpenDeleteAccountModal(false)}
          user_id={user._id}
          notify={value => setNotificationContent(value)}
          gotoSignup={gotoSignup}
          gotoAccess={gotoAccess}
          isCharityAdmin={isCharityAdmin}
        />
      )}
      <Notification content={notificationContent} />
      <Snackbar open={snackbarOpen} message={snackbarMessage} snackbarHandleRequestClose={() => setSnackbarOpen(false)} />
    </div>
  );
}

export default withRouter(AccountPanel);
