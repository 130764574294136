import React from 'react';
import { Link } from 'react-router-dom';
import NetworkEmptyIcon from 'components/charity/assets/network-empty.svg';
import { FlatButton } from '../FlatButton/view';
import { localStorage } from '../../../lib/storage';
import './styles.scss';

export const NetworkList = ({ users, onShowAll }) => {
  if (users.length !== 0) {
    return (
      <div className="networkList fontPoppins">
        <div className="flexCenter flexRow mbottom40">
          <div className="bold mRightAuto">Network</div>
          <FlatButton label={`View All (${users.length})`} onClick={onShowAll} />
        </div>
        {!!localStorage.get('userID') && (
          <div className="row">
            {users.slice(0, 6).map(({ user_id, user }) => {
              if (!user) return;
              return (
                <Link key={user_id} to={`/profile/${user_id}`} className="col-md-4 col-xs-6 flexColumn alignCenter networkUser">
                  <div style={{ backgroundImage: `url(${user.picture})` }} className="networkProfileImg mbottom8"></div>
                  <h4 className="fontPoppins mtop0 textCenter normal">
                    {user.firstname} {user.lastname}
                  </h4>
                </Link>
              );
            })}
          </div>
        )}
      </div>
    );
  }
  return (
    <div className="networkList fontPoppins">
      <div className="bold mRightAuto">Network</div>
      <div className="flexCenter mtop30">
        <img src={NetworkEmptyIcon} alt="Network Empty Icon" />
        <div className="subTitle mtop16 mbottom16">Connect with Volunteers</div>
        <div className="content">Grow your network and search for volunteers on Worthy</div>
      </div>
      {!!localStorage.get('userID') && (
        <div className="row">
          {users.slice(0, 6).map(({ user_id, user }) => {
            if (!user) return;
            return (
              <Link key={user_id} to={`/profile/${user_id}`} className="col-md-4 col-xs-6 flexColumn alignCenter networkUser">
                <img src={user.picture} alt="profile" className="networkProfileImg mbottom8" />
                <h4 className="fontPoppins mtop0 textCenter">
                  {user.firstname} {user.lastname}
                </h4>
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
};
