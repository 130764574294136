import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import ArrowDownIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-down';
import ArrowUpIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-up';

// Loop through the charities that the volunteer is following and display channels based on his macro skills
function ForumVolunteerSidebar({ userByIdData, macroSelected, setMacroSelected, charitySelected, setCharitySelected, unreadMessagesData }) {
  const [collapsedForCharityForum, setCollapsedForCharityForum] = useState({});

  return _.map(_.get(userByIdData, 'CharitiesFollowing'), (charity, index) => {
    const charityId = _.get(charity, '_id');
    return (
      <div className={`charityFollowingContainer child-${index}`} key={charityId}>
        <div className="charityRow">
          {_.get(collapsedForCharityForum, charityId, false) ? (
            <ArrowDownIcon onClick={() => setCollapsedForCharityForum({ ...collapsedForCharityForum, [charityId]: false })} />
          ) : (
            <ArrowUpIcon onClick={() => setCollapsedForCharityForum({ ...collapsedForCharityForum, [charityId]: true })} />
          )}
          <img src={_.get(charity, 'picture')} className="charityLogo" alt="Logo" />
          <h2>{_.get(charity, 'name')}</h2>
        </div>
        {_.get(collapsedForCharityForum, charityId, false) &&
          _.map([...userByIdData.UserMacros, ...charity.Forums], item => {
            const isSelected = item._id === macroSelected && charity._id === charitySelected;
            const unreadMessages = _.get(
              _.find(unreadMessagesData, messageData => messageData.charityId === charityId && messageData.macroId === _.get(item, '_id')),
              'unreadMessagesAmount',
              0,
            );

            // TODO unseen messages for volunteers
            return (
              <div className="sidebarSelectableRow" key={_.get(item, '_id')}>
                <div className="rowBody">
                  <Link
                    className={`${isSelected ? 'selected' : ''} forum`}
                    to={`/dashboard/inbox/charity/${charityId}/macro/${_.get(item, '_id')}`}
                    onClick={() => {
                      setMacroSelected(item._id);
                      setCharitySelected(charity._id);
                    }}
                  >
                    {_.get(item, 'name')}
                  </Link>
                  {unreadMessages > 0 && (
                    <div className="unseenMessageBadge">
                      <span>{unreadMessages > 9 ? '9+' : unreadMessages}</span>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    );
  });
}

export default ForumVolunteerSidebar;
