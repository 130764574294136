const globalVariables = {
  getEnvironmentVariables() {
    const envVariables = {};
    switch (window.location.hostname) {
      case 'localhost':
        envVariables.graphqlURI = 'http://localhost:4000/graphql';
        envVariables.uploadURI = 'http://localhost:4000/upload';
        envVariables.environment = 'DEV';
        break;
      case 'worthycause.com.au':
        envVariables.graphqlURI = 'https://graphql.worthycause.com.au/graphql';
        envVariables.uploadURI = 'https://graphql.worthycause.com.au/upload';
        envVariables.environment = 'PROD';
        break;
      case 'www.worthycause.com.au':
        envVariables.graphqlURI = 'https://graphql.worthycause.com.au/graphql';
        envVariables.uploadURI = 'https://graphql.worthycause.com.au/upload';
        envVariables.environment = 'PROD';
        break;
      case 'www.devenvworthy.com':
        envVariables.graphqlURI = 'https://graphql.devenvworthy.com/graphql';
        envVariables.uploadURI = 'https://graphql.devenvworthy.com/upload';
        envVariables.environment = 'DEV';
        break;
      case 'devenvworthy.com':
        envVariables.graphqlURI = 'https://graphql.devenvworthy.com/graphql';
        envVariables.uploadURI = 'https://graphql.devenvworthy.com/upload';
        envVariables.environment = 'DEV';
        break;
      case 'www.testversion-us.com':
          envVariables.graphqlURI = 'https://graphql.testversion-us.com/graphql';
          envVariables.uploadURI = 'https://graphql.testversion-us.com/upload';
          envVariables.environment = 'DEV';
          break;
      case 'testversion-us.com':
          envVariables.graphqlURI = 'https://graphql.testversion-us.com/graphql';
          envVariables.uploadURI = 'https://graphql.testversion-us.com/upload';
          envVariables.environment = 'DEV';
          break;
      default:
    }
    return envVariables;
  },
  fileServer: 'https://s3-ap-southeast-2.amazonaws.com/kudos-files/',
};

export { globalVariables };
