import React, { useState, useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { ModalBase } from 'components/common/ModalBase/view';
import { Button, LoadingOverlay } from 'components/common';
import continueTaskSubmission from 'mutations/task/continueTaskSubmission';
import continueTask from 'mutations/task/continueTask';
import createNotificationMutation from 'mutations/notification/createNotification';
import fetchTasksByUserId from 'queries/task/fetchTasksByUserId';
import fetchTasksByCharityId from 'queries/task/fetchTasksByCharityId';
import './styles.scss';

export const ModalContinueTask = ({ isCharityManager, taskSubmissionId, volunteerIds, taskId, taskCreator, taskFrequency, userId, onClose, charityId }) => {
  const [open, setOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [continueTaskSubmissionMutation] = useMutation(continueTaskSubmission);
  const [continueTaskMutation] = useMutation(continueTask);
  const [createNotification] = useMutation(createNotificationMutation);
  const handleContinueTask = useCallback(async () => {
    setIsLoading(true);
    if (isCharityManager) {
      await continueTaskMutation({
        variables: {
          taskId,
          submissionId: taskSubmissionId,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: fetchTasksByCharityId,
            variables: {
              _charity_id: charityId,
            },
            skip: false,
          },
        ],
      });
      const notification = {
        _user_id: volunteerIds,
        _action_user_id: userId,
        _task_id: taskId,
        isRead: false,
        isVolunteer: false,
        description: taskFrequency === 1 ? 'Task was continued' : 'Ongoing opportunity was continued',
      };
      await createNotification({
        variables: {
          notification,
        },
      });
    } else {
      await continueTaskSubmissionMutation({
        variables: {
          taskId,
          submissionId: taskSubmissionId,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: fetchTasksByUserId,
            variables: {
              _user_id: userId,
            },
          },
        ],
      });
      const notification = {
        _user_id: [taskCreator],
        _action_user_id: userId,
        _task_id: taskId,
        isRead: false,
        isVolunteer: true,
        description: 'continued task',
      };
      await createNotification({
        variables: {
          notification,
        },
      });
    }
    setIsLoading(false);
    onClose();
  }, [taskSubmissionId, taskId]);

  return (
    <ModalBase open={open} onClose={onClose} className="continueTaskContainer">
      {isLoading ? (
        <div className="flexCenter relative">
          <LoadingOverlay style={{ opacity: 0.8 }} />
        </div>
      ) : (
        <>
          <div className="subject">Are you sure you want to recommence this task?</div>
          <div className="btn_continue_task_cover">
            <Button label="Recommence Task" className="btn_continue_task" onClick={() => handleContinueTask()} />
          </div>
        </>
      )}
    </ModalBase>
  );
};
