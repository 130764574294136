import gql from 'graphql-tag';

export default gql`
  query FetchUserUnreadMessagesByID($id: ID) {
    userByID(id: $id) {
      unread_messages {
        groupId
        charityId
        macroId
        unreadMessagesAmount
      }
    }
  }
`;
