import React, { useState } from 'react';
import _ from 'lodash';
import { useMutation } from '@apollo/client';
import { Modal, LoadingOverlay, Input, Button, Notification } from '../common';
import { userUtils } from '../../lib/user';
import { localStorage } from '../../lib/storage';

import deleteUserMutation from '../../mutations/user/deleteUser';

import './styles/component.scss';

function ModalDeleteAccount(props) {
  const { user_id, isCharityAdmin } = props;
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0); // 0: confirm, 1: password
  const [errorPwdText, setErrorPwdText] = useState('');
  const [password, setPassword] = useState('');

  const [deleteUser] = useMutation(deleteUserMutation);

  const proceedAction = async () => {
    try {
      if (step === 0) {
        setStep(1);
        return;
      }
      if (isCharityAdmin) {
        props.gotoAccess();
        props.onClose(true);
        return;
      }
      setLoading(true);
      const result = await deleteUser({
        variables: {
          id: user_id,
          password,
        },
      });
      if (_.get(result, 'data.deleteUser').length) {
        localStorage.reset();
        props.gotoSignup();
        return;
      }
      setLoading(false);
      props.notify("Credential doesn't match!");
    } catch (error) {
      console.log(error);
      setLoading(false);
      props.notify('Error happened in deleting the account!');
      props.onClose(false);
      setStep(0);
      setPassword('');
    }
  };

  const generatePromptContent = () => {
    let content;
    if (step === 0) {
      content = (
        <>
          <p className="text">Are you sure you want to delete your account?</p>
          <p className="text">Your profile will be deleted and information removed within 7 days.</p>
        </>
      );
    } else if (isCharityAdmin) {
      content = (
        <>
          <p className="text">To delete your account you need to appoint a new Charity Administrator first.</p>
          <p className="text">This keeps the Charity profile even if you remove your account.</p>
        </>
      );
    } else {
      content = (
        <>
          <p className="text">Enter your password to delete your account. </p>
          <p className="inputLabel">Enter Password:</p>
          <Input
            name="password"
            placeholder="Input your password"
            type="password"
            errorText={errorPwdText}
            value={password}
            onChange={value => setPassword(value)}
            onBlur={() => {
              if (userUtils.isValidPassword(password) === false) {
                setErrorPwdText('Password must be 8 characters or more, and it must include at least one uppercase letter, one lowercase letter, and one number');
              } else {
                setErrorPwdText('');
              }
            }}
          />
        </>
      );
    }

    return content;
  };

  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.onClose(false);
        setStep(0);
      }}
      className="confirmModal"
    >
      {loading && <LoadingOverlay style={{ opacity: 0.8 }} />}
      <div className="settingModalFormContainer textCenter">
        <h1 className="settingModalFormHeader">{step === 1 && isCharityAdmin ? 'Appoint new Administrator' : 'Delete Account?'}</h1>
        <div>{generatePromptContent()}</div>
        <div className="buttonGroup">
          <Button label={step === 0 ? 'Yes' : 'Confirm'} onClick={proceedAction} />
          <Button
            label="Exit"
            onClick={() => {
              props.onClose(false);
              setStep(0);
            }}
          />
        </div>
      </div>
    </Modal>
  );
}

export default ModalDeleteAccount;
