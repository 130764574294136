import React from 'react';
import { withRouter } from 'react-router';
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { localStorage } from 'lib/storage';
import { LoadingOverlay } from 'components/common';
import { userUtils } from 'lib/user';
import ConnectWithCharities from './assets/connect-with-charities.svg';
import ManageTask from './assets/manage-task.svg';
import SearchVolunteer from './assets/search-volunteer.svg';
import Task from './assets/task.svg';
import UpdateCharityProfile from './assets/update-charity-profile.svg';
import UpdateProfile from './assets/update-profile.svg';

// Queries
import fetchUserById from '../../queries/user/fetchUserById';

// Styles
import './styles/welcomeStyles.scss';
import { GuideComponent } from './guideComponent';

function Welcome(props) {
  const { loading, data } = useQuery(fetchUserById, {
    variables: {
      id: localStorage.get('userID'),
    },
  });
  const userByIdData = _.get(data, 'userByID');
  const charitiesManaging = _.get(userByIdData, 'CharitiesAdmin.0');
  const isCharityManager = userUtils.isCharityManager(userByIdData);

  return (
    <div className="welcomeContainer">
      {loading ? (
        <LoadingOverlay style={{ opacity: 0.8 }} />
      ) : (
        <>
          <div className="title">Hello, {_.get(userByIdData, 'firstname')}!</div>
          <div className="subTitle mtop30">
            {isCharityManager
              ? `Here’s what’s going on with your team at ${_.get(userByIdData, 'CharitiesAdmin')[0].name}.`
              : 'Let’s get things done. Here’s an overview of what you can do.'}
          </div>
          <div className="header mtop40">Things to do</div>
          {isCharityManager ? (
            <div className="mtop24">
              <GuideComponent
                props={props}
                charityId={charitiesManaging._id}
                image={Task}
                title="Create a Task"
                content="Need something done? Create a task for a volunteer to complete."
                link="/task/create/overview"
              />
              <GuideComponent
                props={props}
                className="mtop22"
                image={SearchVolunteer}
                title="Search for Volunteers"
                content="Use the search bar to find skilled volunteers to chat with."
              />
              {_.get(userByIdData, 'TaskSubmissions').length === 0 ? (
                <GuideComponent
                  props={props}
                  className="mtop22"
                  image={UpdateCharityProfile}
                  title="Update your Charity Profile"
                  content="Keep your Charity Profile up to date so volunteers can easily find you."
                  link={`/charity/${charitiesManaging._id}`}
                />
              ) : (
                <GuideComponent
                  props={props}
                  className="mtop22"
                  image={ManageTask}
                  title="Manage your Tasks"
                  content="Go to your task manager to manage your volunteer’s tasks."
                  link="/dashboard/task-manager"
                />
              )}
            </div>
          ) : (
            <div className="mtop24">
              <GuideComponent
                props={props}
                image={ConnectWithCharities}
                title="Connect with charities"
                content="They’ll tap you on the shoulder when they need help."
                link="/charities"
              />
              <GuideComponent
                props={props}
                className="mtop22"
                image={Task}
                title="Apply for tasks"
                content="Find tasks that best match your skillset and apply."
                link="/task/search"
              />
              <GuideComponent
                props={props}
                className="mtop22"
                image={UpdateProfile}
                title="Update your profile"
                content="Keep your profile up to date so Charities can easily find you."
                link={`/profile/${_.get(userByIdData, '_id')}`}
              />
              <GuideComponent
                props={props}
                className="mtop22"
                image={ManageTask}
                title="Manage your Tasks"
                content="Go to the task manager to view your volunteering tasks."
                link="/dashboard/task-manager"
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default withRouter(Welcome);
