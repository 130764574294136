import gql from 'graphql-tag';

export default gql`
  query fetchUnreadNotificationsCountByUserId($user_id: ID!) {
    unreadNotificationsCountByUserId(user_id: $user_id) {
      unreadCount
      totalCount
    }
  }
`;
