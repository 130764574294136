import React, { useState } from 'react';
import _ from 'lodash';
import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { CharityManagerSearch, LoadingOverlay, ModalBase, Button } from 'components/common';
import inviteVolunteersForTask from 'mutations/task/inviteVolunteersForTask';
import fetchTaskById from 'queries/task/fetchTaskById';
import createNotificationMutation from 'mutations/notification/createNotification';
import './styles.scss';

export const ModalInviteVolunteer = ({ onClose, taskId }) => {
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const apolloClient = useApolloClient();
  const [createNotification] = useMutation(createNotificationMutation);
  const [inviteVolunteer] = useMutation(inviteVolunteersForTask);
  const { data: taskData } = useQuery(fetchTaskById, {
    variables: {
      _id: taskId,
    },
  });
  const onSelectUser = user => {
    let newSelectedUsers = [...selectedUsers];
    // Check whether user is already on the list
    if (_.find(selectedUsers, item => item._id === user._id)) {
      newSelectedUsers = _.filter(selectedUsers, item => item._id !== user._id);
    } else {
      newSelectedUsers.push(user);
    }
    setSelectedUsers(newSelectedUsers);
  };

  const onInviteUsers = async () => {
    setLoading(true);
    const usersOnGroup = _.map(selectedUsers, '_id');
    await inviteVolunteer({
      variables: {
        _task_id: taskId,
        _user_ids: usersOnGroup,
      },
      refetchQueries: [
        {
          query: fetchTaskById,
          variables: {
            _id: taskId,
          },
        },
      ],
    });
    const notification = {
      _user_id: usersOnGroup,
      _action_user_id: _.get(taskData, 'taskByID._user_id'),
      _task_id: taskId,
      isRead: false,
      isVolunteer: false,
      description: _.get(taskData, 'taskByID.frequency') === 1 ? 'You have been invited to a task' : 'You have been invited to an ongoing opportunity'
    };
    await createNotification({
      variables: {
        notification,
      },
    });
    setLoading(false);
    setOpen(false);
  };
  if (!taskData) return null;
  if (taskData) {
    return (
      <ModalBase className="inviteVolunteerModal" open={open} onClose={onClose}>
        <h2 className="fontPoppins textBlue bold textCenter mt0">{_.get(taskData, 'taskByID.name')}</h2>
        <h3 className="fontPoppins textBlack normal textCenter mbottom40">Invite volunteers to apply for this task. You can search by skill or name.</h3>
        <CharityManagerSearch apolloClient={apolloClient} displayAsPopover={false} onUserClicked={onSelectUser} selectedUsers={selectedUsers} shouldSelectUsers />
        <div className="flexEnd">
          <Button label="Invite" className="mtop30" onClick={onInviteUsers} disabled={_.size(selectedUsers) === 0} />
        </div>
      </ModalBase>
    );
  }
};
