import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useMutation, useApolloClient } from '@apollo/client';
import _ from 'lodash';
import DraggableList from 'react-draggable-list';
import ArrowDownIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-down';
import ArrowUpIcon from 'material-ui/svg-icons/hardware/keyboard-arrow-up';
import ChatIcon from '../../assets/svg/chat.svg';
import AddChatIcon from '../../assets/svg/squared-add.svg';
import { localStorage } from '../../lib/storage';
import NewMessageModal from './newMessageModal';
import GroupChatItem from './groupChatItem';

// Queries
import fetchUserById from '../../queries/user/fetchUserById';

// mutation
import updateGroupMessagesOrdersMutation from '../../mutations/user/updateGroupMessagesOrders';

// css
import './styles/sidebarChats.scss';

function GroupChatsSidebar({ groupsData, orderData, groupIdFromUrl, unreadMessagesData }) {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [groupChatList, setGroupChatList] = useState([]);
  const [openNewGroupChatModal, setOpenNewGroupChatModal] = useState(false);
  const userId = localStorage.get('userID');
  const apolloClient = useApolloClient();

  const containerRef = useRef();

  const [updateGroupMessagesOrders] = useMutation(updateGroupMessagesOrdersMutation);

  const saveOrderData = async groupChatListData => {
    const orderDataInput = _.map(groupChatListData, listItem => ({ group_id: listItem._id, pined: listItem.pined }));
    await updateGroupMessagesOrders({
      variables: {
        id: userId,
        groupMessagesOrders: orderDataInput,
      },
      refetchQueries: [
        {
          query: fetchUserById,
          variables: {
            id: userId,
          },
          fetchPolicy: 'network-only',
        },
      ],
    });
  };

  const handleListChanged = newList => {
    setGroupChatList([...pinedGroupChatList, ...newList]);
  };

  const pinGroupChatItem = item => {
    setGroupChatList([{ ...item, pined: true }, ...pinedGroupChatList, ..._.filter(unpinedGroupChatList, unpinedListItem => item._id !== unpinedListItem._id)]);
  };
  const unpinGroupChatItem = item => {
    setGroupChatList([..._.filter(pinedGroupChatList, pinedListItem => item._id !== pinedListItem._id), ...unpinedGroupChatList, { ...item, pined: false }]);
  };

  const pinedGroupChatList = useMemo(() => _.filter(groupChatList, item => item.pined), [groupChatList]);
  const unpinedGroupChatList = useMemo(() => _.filter(groupChatList, item => !item.pined), [groupChatList]);

  useEffect(() => {
    if (orderData.length > 0) {
      const sortedList = [];
      const groupsTempData = [...groupsData];
      orderData.forEach(orderDataItem => {
        const groupDataItemIndex = groupsTempData.findIndex(groupData => groupData._id === orderDataItem.group_id);
        if (groupDataItemIndex !== -1) {
          const groupDataItem = groupsTempData.splice(groupDataItemIndex, 1)[0];
          sortedList.push({
            ...groupDataItem,
            pined: _.get(orderDataItem, 'pined', false),
            unreadMessages: _.get(
              _.find(unreadMessagesData, item => item.groupId === groupDataItem._id),
              'unreadMessagesAmount',
              0,
            ),
          });
        }
      });

      if (groupsTempData.length > 0) {
        sortedList.push(
          ..._.map(groupsTempData, groupTempData => ({
            ...groupTempData,
            pined: false,
            unreadMessages: _.get(
              _.find(unreadMessagesData, item => item.groupId === groupTempData._id),
              'unreadMessagesAmount',
              0,
            ),
          })),
        );
      }
      setGroupChatList(sortedList);
    } else {
      setGroupChatList(_.map(groupsData, groupData => ({ ...groupData, pined: false })));
    }
  }, [groupsData, orderData, unreadMessagesData]);

  useEffect(() => {
    saveOrderData(groupChatList);
  }, [groupChatList]);

  return (
    <div className="sidebarSection groupChats">
      <div className="header">
        {isCollapsed ? <ArrowDownIcon onClick={() => setIsCollapsed(false)} /> : <ArrowUpIcon onClick={() => setIsCollapsed(true)} />}
        <h2>
          <img src={ChatIcon} alt="Chat Icon" /> Chats
        </h2>
      </div>
      {isCollapsed && (
        <div className="body">
          <div className="pinedSection">
            {pinedGroupChatList.map((pinedGroupChatItem, index) => (
              <GroupChatItem key={`groupchat-${index}`} item={pinedGroupChatItem} isPined commonProps={{ pinGroupChatItem, unpinGroupChatItem, groupIdFromUrl }} />
            ))}
          </div>
          <div className="unpinedSection" ref={containerRef}>
            <DraggableList
              itemKey="_id"
              template={GroupChatItem}
              list={unpinedGroupChatList}
              onMoveEnd={newList => handleListChanged(newList)}
              commonProps={{ pinGroupChatItem, unpinGroupChatItem, groupIdFromUrl }}
              container={() => containerRef.current}
            />
          </div>
        </div>
      )}
      <div className="newGroupChat" onClick={() => setOpenNewGroupChatModal(true)}>
        <img src={AddChatIcon} alt="Create New Chat Group" />
        <span>New Message</span>
      </div>
      {openNewGroupChatModal && <NewMessageModal onClose={() => setOpenNewGroupChatModal(false)} _current_user_id={userId} apolloClient={apolloClient} />}
    </div>
  );
}

export default GroupChatsSidebar;
